import { getType } from 'typesafe-actions';
import { getHappenings, resetState } from './../actions';
import { IAction, IHappeningsReducer } from './../types';

const initialState: IHappeningsReducer = null;

const happeningsReducer = (
  state: IHappeningsReducer = initialState,
  action: IAction,
): IHappeningsReducer => {
  switch (action.type) {
    case getType(getHappenings.success):
      return action.payload;

    case getType(resetState):
      return initialState;

    default:
      return state;
  }
};

export default happeningsReducer;
