import api from './api';
import app from './app';
import formio from './formio';
import muiTheme from './muiTheme';
import resizer from './resizer';
import spacings from './spacings';
import theme from './theme';

const config = {
  api,
  app,
  formio,
  muiTheme,
  resizer,
  spacings,
  theme,
};

export default config;
