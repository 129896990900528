import format from 'date-fns/format';

import getMetadataForLanguage from '@Misc/helpers/getMetadataForLanguage';
import {
  getConfigurationForSelectedSpace,
  getSelectedDayIncludeTimeSlot,
} from '@Model/booking/selector';
import calculatePrice from '@Model/booking/selector/calculatePrice';
import calculateUpSellProps from '@Model/booking/selector/calculateUpSellProps';
import getValues from '@Model/booking/selector/getValues';
import { get as getHappening } from '@Model/happening/selectors';
import { IHappeningSpaceMetadata } from '@Model/happening/types';
import { IHappeningsItemMetadata } from '@Model/happenings/types';
import { get as getCheckedPrice } from '@Model/price/selectors';
import calculateSummaryProps from '@Model/reservation/selectors/calculateSummaryProps';
import { createSelector } from 'reselect';
import { IZagrywkiBasket } from '../types';

const ONE_PERSON_TEXT = 'osoba';
const TWO_TO_FOUR_PEOPLE_TEXT = 'osoby';
const MORE_THAN_FOUR_PEOPLE_TEXT = 'osób';

const getBasketData = createSelector(
  [
    getHappening,
    getValues,
    getSelectedDayIncludeTimeSlot,
    getCheckedPrice,
    calculatePrice,
    calculateSummaryProps,
    getConfigurationForSelectedSpace,
    calculateUpSellProps,
  ],
  (
    happening,
    values,
    day,
    checkedPrice,
    calculatedPrice,
    summaryProps,
    configuration,
    calculatedUpSellProps,
  ) => {
    const selectedSpace =
      happening &&
      happening.spaces.find((_space) => _space.id === values.space);

    const dateTime =
      values && values.day
        ? `${format(day || values.day, 'yyyy-MM-dd')}T${values.slot}+00:00`
        : null;

    const getPrice = (): number => {
      if (checkedPrice && checkedPrice.value) {
        return checkedPrice.value;
      } else if (calculatedPrice) {
        return calculatedPrice;
      }

      return 0;
    };

    const getFee = (): number => {
      if (checkedPrice && checkedPrice.fee) {
        return checkedPrice.fee;
      }

      return 0;
    };

    const getNumberOfPeople = (): number => {
      if (happening && selectedSpace) {
        if (happening.calculatePricePerPerson && values.numberOfPlayers) {
          return values.numberOfPlayers;
        }

        return selectedSpace.maxNumberOfPeople;
      }

      return 0;
    };

    if (happening && selectedSpace) {
      const { title: spaceMetadata, slug: spaceSlug } = getMetadataForLanguage<
        IHappeningSpaceMetadata
      >(selectedSpace.metadata);

      const {
        title: happeningMetaData,
        slug: happeningSlug,
      } = getMetadataForLanguage<IHappeningsItemMetadata>(happening.metadata);

      const numberOfPeople = getNumberOfPeople();

      const getSpellNumberOfPeople = (count: number): string => {
        if (count === 1) {
          return ONE_PERSON_TEXT;
        } else if (count > 1 && count < 5) {
          return TWO_TO_FOUR_PEOPLE_TEXT;
        } else {
          return MORE_THAN_FOUR_PEOPLE_TEXT;
        }
      };

      const numberOfPeopleTest = numberOfPeople
        ? `(${numberOfPeople} ${getSpellNumberOfPeople(numberOfPeople)})`
        : '';

      const getHappeningProps = (): IZagrywkiBasket => {
        const getPricesType = (): string => {
          if (
            configuration &&
            configuration.prices &&
            configuration.prices.length
          ) {
            const defaultPriceKey = configuration.prices.findIndex(
              (_price) => _price.type === 'default',
            );

            if (defaultPriceKey !== -1) {
              return configuration.prices[defaultPriceKey].type;
            }

            return configuration.prices[0].type;
          }
          return '';
        };
        return {
          calculatePricePerPerson: happening.calculatePricePerPerson,
          configurationId:
            configuration && configuration.id ? configuration.id : null,
          dateTime,
          duration: selectedSpace.timeSlot,
          fee: getFee(),
          happeningId: happening.id,
          happeningSlug,
          id: dateTime
            ? selectedSpace.id + new Date(dateTime).getTime()
            : selectedSpace.id,
          numberOfPeople: getNumberOfPeople(),
          numberOfPeopleText: numberOfPeopleTest,
          price: getPrice(),
          priceType: getPricesType(),
          selectedTimeRange: summaryProps.selectedTimeRange,
          spaceId: selectedSpace.id,
          spaceSlug,
          spaceTitle: spaceMetadata,
          title: happeningMetaData,
          upSell:
            configuration && configuration.upsell
              ? calculatedUpSellProps
              : undefined,
        };
      };

      return getHappeningProps();
    }
    return null;
  },
);

export default getBasketData;
