import { get } from '@Model/happening/selectors';
import { createSelector } from 'reselect';
import getAvailabilities from './getAvailabilities';
import getSelectedPrice from './getSelectedPrice';
import getValues from './getValues';

const DEFAULT_PRICE = 0;

const calculatePrice = createSelector(
  [getAvailabilities, getValues, get, getSelectedPrice],
  (availabilities, values, happening, selectedPrice) => {
    const { slot, space, day, numberOfPlayers } = values;

    if (!(slot && space && day && availabilities && happening)) {
      return DEFAULT_PRICE;
    }

    const { currentDay, otherDays } = availabilities;

    let availability = currentDay[slot];

    if (otherDays && otherDays[slot]) {
      availability = otherDays[slot];
    }

    if (!availability) {
      return DEFAULT_PRICE;
    }

    const foundedSpace = availability.find(
      (_space) => _space.spaceId === space,
    );

    if (!foundedSpace) {
      return DEFAULT_PRICE;
    }

    const getPrice = (): number => {
      if (foundedSpace.prices && foundedSpace.prices.length) {
        if (selectedPrice) {
          const key = foundedSpace.prices.findIndex((_price) => {
            return _price.type === selectedPrice;
          });

          if (key !== -1) {
            return foundedSpace.prices[key].value;
          }
        } else if (foundedSpace.prices && foundedSpace.prices.length) {
          const defaultPriceKey = foundedSpace.prices.findIndex(
            (_price) => _price.type === 'default',
          );

          if (defaultPriceKey !== -1) {
            return  foundedSpace.prices[defaultPriceKey].value
          }

          return foundedSpace.prices[0].value;
        }
      }
      return foundedSpace.price;
    };

    const price = getPrice();

    let returnedPrice;

    if (!happening.calculatePricePerPerson) {
      returnedPrice = price;
    } else {
      returnedPrice = numberOfPlayers * price;
    }

    if (returnedPrice) {
      return returnedPrice;
    }

    return null;
  },
);

export default calculatePrice;
