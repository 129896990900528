// Private
export const _GET_REQUEST = 'Zagrywki/extendedPayment/_GET_REQUEST';
export const _GET_SUCCESS = 'Zagrywki/extendedPayment/_GET_SUCCESS';
export const _GET_FAILURE = 'Zagrywki/extendedPayment/_GET_FAILURE';
export const _RESET_STATE = 'Zagrywki/extendedPayment/_RESET_STATE';

export const _PAYMENT_REQUEST = 'Zagrywki/extendedPayment/_PAYMENT_REQUEST';
export const _PAYMENT_SUCCESS = 'Zagrywki/extendedPayment/_PAYMENT_SUCCESS';
export const _PAYMENT_FAILURE = 'Zagrywki/extendedPayment/_PAYMENT_FAILURE';

export const _GET_UPDATED_TRANSACTION_DETAILS_REQUEST =
  'Zagrywki/extendedPayment/_GET_UPDATED_TRANSACTION_DETAILS_REQUEST';
export const _GET_UPDATED_TRANSACTION_DETAILS_SUCCESS =
  'Zagrywki/extendedPayment/_GET_UPDATED_TRANSACTION_DETAILS_SUCCESS';
export const _GET_UPDATED_TRANSACTION_DETAILS_FAILURE =
  'Zagrywki/extendedPayment/_GET_UPDATED_TRANSACTION_DETAILS_FAILURE';

// Public
export const CATCH_GET_EXTENDED_PAYMENT =
  'Zagrywki/extendedPayment/CATCH_GET_EXTENDED_PAYMENT';
export const PAY = 'Zagrywki/extendedPayment/PAY';
