import React, { useEffect } from 'react';

import { Button, Grid, Typography } from '@material-ui/core';
import { useRecoilValue } from 'recoil';

import { HeaderContentMUI } from '@/srcShared/ui/molecules/HeaderContentMUI';
import { HeaderContentMUIVariant } from '@/srcShared/ui/molecules/HeaderContentMUI/models/HeaderContentMUI';
import config from '@Config';
import { iframeParams } from '@Recoil/formio/atoms';
import { translation } from '@Recoil/lang/selectors';
import Analytics from '@Services/$analytics-provider';
import useStyles from './HaveTicketCodeStep.styles';
import { IHaveTicketCodeStepProps } from './HaveTicketCodeStep.types';

const HaveTicketCodeStep = ({
  setStepByName,
  registrationWithoutCode,
  registrationWithTicketCode,
}: IHaveTicketCodeStepProps) => {
  const styles = useStyles();

  const { entryListL } = useRecoilValue(translation);
  const { ticketCode, transactionHash } = useRecoilValue(iframeParams);

  const spacing = config.spacings;

  useEffect(() => {
    Analytics.logEvent('page_view', {
      page_title: entryListL.haveTicketCodeStep.title,
    });
  }, []);

  useEffect(() => {
    if (ticketCode) {
      setStepByName('ticketCode');
      Analytics.logEvent('page_view', {
        page_title: 'Przekierowanie z maila',
      });
    }
    if (transactionHash) {
      Analytics.logEvent('page_view', {
        page_title: 'Przekierowanie z zakończonej transakcji',
      });
      setStepByName('addBuyer');
    }
  }, [ticketCode, transactionHash]);

  const haveTicketCode = () => {
    registrationWithTicketCode();
    Analytics.logEvent('select_item', {
      item_list_name: 'Mam kod biletu',
    });
    setStepByName('ticketCode');
  };
  const dontHaveTicketCode = () => {
    registrationWithoutCode();
    Analytics.logEvent('select_item', {
      item_list_name: 'Nie mam kodu biletu',
    });
    setStepByName('numberOfPeople');
  };

  return (
    <HeaderContentMUI
      variant={HeaderContentMUIVariant.GENERIC}
      withHeader={true}
      withHeadParagraph={false}
      title={entryListL.haveTicketCodeStep.title}
    >
      <Grid container={true} spacing={spacing.regular} direction="column">
        <Grid item={true}>
          <Typography variant="body1">
            {entryListL.haveTicketCodeStep.haveTicketCode}
          </Typography>
        </Grid>
        <Grid item={true}>
          <Grid container={true} spacing={spacing.regular}>
            <Grid item={true} xs={6}>
              <Button
                className={styles.button}
                size="large"
                variant="contained"
                color="primary"
                // tslint:disable-next-line: jsx-no-lambda
                onClick={haveTicketCode}
              >
                {entryListL.yes}
              </Button>
            </Grid>
            <Grid item={true} xs={6}>
              <Button
                className={styles.button}
                size="large"
                variant="outlined"
                color="primary"
                // tslint:disable-next-line: jsx-no-lambda
                onClick={dontHaveTicketCode}
              >
                {entryListL.no}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </HeaderContentMUI>
  );
};

export default HaveTicketCodeStep;
