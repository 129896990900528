import React, { Suspense } from 'react';

import EntryTerm from '@Compo/CustomByClient/Hangar/EntryTerm';
import Grid from '@Compo/layout/Grid';
import Loading from '@Compo/layout/Loading';
import PageWrapper from '@Compo/layout/PageWrapper';

const loading = <Loading />;

const HappeningPage = () => (
  <Grid>
    <PageWrapper>
      <Suspense fallback={loading}>
        <EntryTerm />
      </Suspense>
    </PageWrapper>
  </Grid>
);

export default HappeningPage;
