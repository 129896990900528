import { connect } from 'react-redux';
import { Dispatch, AnyAction } from 'redux';

import _Store from '@Store';

import AddNewPeopleToTicketStep from './AddNewPeopleToTicketStep.component';
import { IAddNewPeopleToTicketStepFromDispatch } from './AddNewPeopleToTicketStep.types';
import { registrationVisitorConfirmed } from '@Model/analytics/actions';

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>,
): IAddNewPeopleToTicketStepFromDispatch => ({
  registrationVisitorConfirmed: (email) =>
    dispatch(registrationVisitorConfirmed(email)),
});

export default connect<
  {},
  IAddNewPeopleToTicketStepFromDispatch,
  {},
  _Store.IState
>(
  null,
  mapDispatchToProps,
)(AddNewPeopleToTicketStep);
