import { useEffect } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import error from '@Recoil/errors/atoms/error';
import { formioData, iframeParams, submissions } from '@Recoil/formio/atoms';
import { isLoading } from '@Recoil/loading/atoms';
import FormIoApi from '@Services/$formio-api';

import { ISubmissions } from './../types';

export const useSubmissionsApi = () => {
  const [data, setData] = useRecoilState(submissions);
  const setLoading = useSetRecoilState(isLoading);
  const setError = useSetRecoilState(error);
  const { reservationCode } = useRecoilValue(formioData);
  const { transactionHash, formIoId } = useRecoilValue(iframeParams);

  const getSubmissions = async () => {
    setLoading(true);
    try {
      if (reservationCode || transactionHash) {
        const submission = await FormIoApi.getFormIoSubmissions(
          reservationCode,
          transactionHash,
          formIoId,
        );

        const {
          firstName,
          lastName,
          email,
          formSubmissionId,
        } = submission[0].owner;

        const submissionData: ISubmissions = {
          ...submission[0],
          owner: {
            email,
            firstName,
            lastName,
            submissionId: formSubmissionId,
          },
          submissions: submission[0].submission
            ? submission[0].submission.map((user) => ({
                email: user.submissions[0].data.email,
                firstName: user.submissions[0].data.firstname,
                lastName: user.submissions[0].data.lastname || '',
                submissionId: user.submissions[0].submissionId,
              }))
            : [],
        };

        setData(submissionData);
      }
    } catch (error) {
      setError({ message: error.message });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getSubmissions();
  }, [transactionHash, reservationCode]);

  return { data };
};
