import { selectorFamily } from 'recoil';

import FormIoApi from '@Services/$formio-api';

const getUserSubmissions = selectorFamily({
  get: (data: { userName?: string; userEmail?: string }) => async () => {
    try {
      if (data.userEmail && data.userName) {
        const users = await FormIoApi.getFormIoUserSubmissions(
          data.userEmail,
          data.userName,
        );

        return users.map((user) => ({ ...user, selected: false }));
      }

      return [];
    } catch {}
  },

  key: 'getUserSubmissions',
});

export default getUserSubmissions;
