const theme = {
  /*
   * Default theme
   */
  isHangar: process.env.THEME === 'hangar',
  isSaltos: process.env.THEME === 'saltos',
  isZagrywki: process.env.THEME === 'zagrywki',
  selectedTheme: process.env.THEME,
  showUnfinishedFeatures: process.env.REACT_APP_UNFINISHED_FEATURE
    ? String(process.env.REACT_APP_UNFINISHED_FEATURE) === 'true'
    : false,
};
export default theme;
