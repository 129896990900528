import { SMALL_MOBILE } from '@Model/app/constants/constants';
import { createSelector } from 'reselect';
import getDevice from './getDevice';

const isSmallMobile = createSelector(
  [getDevice],
  (device) => device === SMALL_MOBILE,
);

export default isSmallMobile;
