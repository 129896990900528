import React, { useEffect, useState } from 'react';

import { Grid, Typography } from '@material-ui/core';

import { Colors } from '@Constants/Colors';
import { TypographyVariant } from '@Constants/Variants/Typography';
import translateDefaultPriceType from '@Misc/helpers/strings/translateDefaultPrice';
import MaterialSlider from './../MaterialSlider';
import { ITicketTypeSingleProps } from './TicketTypeSingle.types';

const CURRENCY_TEXT = 'zł';

const TicketTypeSingle = ({
  availablePriceTypes,
  values,
  setPeopleCount,
  selectPriceType,
  showAdditionalInfo,
}: ITicketTypeSingleProps) => {
  const filteredPriceTypes = availablePriceTypes.filter(
    (price) =>
      values && !values.find((value) => value.priceType === price.type),
  );
  const [selectedPriceType, setSelectedPriceType] = useState(
    selectPriceType.type ||
      (filteredPriceTypes[0] ? filteredPriceTypes[0].type : 'default'),
  );

  const getDefaultSelectedPriceTypeCounter = (): number => {
    const selectedCounter = availablePriceTypes.find(
      (priceType) => priceType.type === 'default',
    );
    if (selectedCounter) {
      return selectedCounter.counter || 1;
    }
    return 1;
  };

  const getSelectedPriceTypeCounterBySlug = (): number => {
    const selectedCounter = availablePriceTypes.find(
      (priceType) => priceType.type === selectedPriceType,
    );
    if (selectedCounter) {
      return selectedCounter.counter || 1;
    }
    return 1;
  };

  useEffect(() => {
    const isDefaultPrice = availablePriceTypes.find(
      (priceType) => priceType.type === 'default',
    );

    if (!!isDefaultPrice) {
      setPeopleCount(getDefaultSelectedPriceTypeCounter(), 'default');
    }
  }, []);

  const selectedPrice =
    values && values.find((value) => value.priceType === selectedPriceType);

  const selectedPriceNumberOfPeople =
    (selectedPrice && selectedPrice.numberOfPeople) || 0;

  return (
    <Grid container={true}>
      <Grid item={true} xs={12} md={6}>
        <Grid container={true} spacing={2}>
          {showAdditionalInfo && (
            <>
              <Grid container={true} item={true} xs={12}>
                <Grid item={true} xs={true}>
                  <Typography variant={TypographyVariant.h5}>
                    {translateDefaultPriceType(selectPriceType.title)}
                  </Typography>
                </Grid>
                <Grid item={true}>
                  <Typography variant={TypographyVariant.body1}>
                    {selectPriceType.value} {CURRENCY_TEXT}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item={true} xs={12}>
                <Typography
                  variant={TypographyVariant.body1}
                  color={Colors.textSecondary}
                >
                  {selectPriceType.description}
                </Typography>
              </Grid>
            </>
          )}
          <Grid item={true} xs={12}>
            <MaterialSlider
              priceType={selectedPriceType}
              numberOfPeople={selectedPriceNumberOfPeople}
              step={getSelectedPriceTypeCounterBySlug()}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TicketTypeSingle;
