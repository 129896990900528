import { connect } from 'react-redux';
import { Dispatch, AnyAction } from 'redux';

import _Store from '@Store';
import EntryTerm from './EntryTerm.component';
import { IEntryTermFromDispatch } from './EntryTerm.types';
import { addVisitorData } from '@Model/analytics/actions';

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>,
): IEntryTermFromDispatch => ({
  addVisitorData: (email) => dispatch(addVisitorData(email)),
});

export default connect<{}, IEntryTermFromDispatch, {}, _Store.IState>(
  null,
  mapDispatchToProps,
)(EntryTerm);
