const DEFAULT_PRICE_TEXT = 'Bilet normalny';

const translateDefaultPriceType = (type: string | undefined) => {
  if (type) {
    if (type === 'default') {
      return DEFAULT_PRICE_TEXT;
    }
    return type;
  }
  return '';
};

export default translateDefaultPriceType;
