import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { carnetsMounted, selectCarnet } from '@Model/products/actions';
import { getProducts } from '@Model/products/selectors';
import _Store from '@Store';
import Carnets from './Carnets.component';
import { ITicketsFromDispatch, ITicketsFromState } from './Carnets.types';

const mapStateToProps = (state: _Store.IState): ITicketsFromState => {
  const { carnets } = getProducts(state);
  return {
    carnets,
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>,
): ITicketsFromDispatch => ({
  mounted: () => dispatch(carnetsMounted()),
  selectCarnet: (carnet) => dispatch(selectCarnet(carnet)),
});

export default connect<
  ITicketsFromState,
  ITicketsFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps,
)(Carnets);
