import React, { useEffect } from 'react';

import { Button, Grid, Typography } from '@material-ui/core';
import { useRecoilValue, useSetRecoilState, useRecoilState } from 'recoil';

import { HeaderContentMUI } from '@/srcShared/ui/molecules/HeaderContentMUI';
import { HeaderContentMUIVariant } from '@/srcShared/ui/molecules/HeaderContentMUI/models/HeaderContentMUI';
import { TypographyGoingJP } from '@/srcShared/ui/particles/TypographyGoingJP';
import { TypographyGoingJPVariant } from '@/srcShared/ui/particles/TypographyGoingJP/models/TypographyGoingJP';
import Loading from '@Compo/layout/Loading';
import config from '@Config';
import { iframeParams, ownerState, peopleState } from '@Recoil/formio/atoms';
import { useGroupApi } from '@Recoil/formio/hooks/useGroupApi';
import { translation } from '@Recoil/lang/selectors';
import { isLoading } from '@Recoil/loading/atoms';
import Analytics from '@Services/$analytics-provider';
import { IThanksStepProps } from './ThanksStep.types';

import useStyles from './ThanksStep.styles';

const ThanksStep = ({
  setStepByName,
  saved,
  registrationSuccess,
}: IThanksStepProps) => {
  const styles = useStyles();
  const { code } = useGroupApi();

  const { entryListL } = useRecoilValue(translation);
  const setIframeParams = useSetRecoilState(iframeParams);
  const [people, setPeople] = useRecoilState(peopleState);
  const owner = useRecoilValue(ownerState);
  const loading = useRecoilValue(isLoading);

  const spacing = config.spacings;

  const handleBack = () => {
    if (owner.email) {
      registrationSuccess(owner.email);
    } else {
      registrationSuccess(people[0]?.email || '');
    }
    setIframeParams((oldState) => ({
      ...oldState,
      ticketCode: null,
      transactionHash: null,
    }));
    setTimeout(() => setStepByName('haveTicketCode'), 0);
  };

  useEffect(() => {
    Analytics.logEvent('page_view', {
      page_title: entryListL.thanksStep.thanks,
    });
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <HeaderContentMUI
      variant={HeaderContentMUIVariant.GENERIC}
      withHeader={true}
      withHeadParagraph={false}
      title={saved ? entryListL.thanksStep.saved : entryListL.thanksStep.thanks}
    >
      <Grid container={true} spacing={spacing.regular} direction="column">
        <Grid item={true} xs={12}>
          <Typography variant="body2">
            {saved
              ? entryListL.thanksStep.savedDescription
              : entryListL.thanksStep.code}
          </Typography>
        </Grid>
        {!saved && config.app.showLastStepEntryListCode && (
          <Grid item={true} xs={12}>
            <TypographyGoingJP
              typographyVariant="h5"
              variant={TypographyGoingJPVariant.yellowUnderline}
            >
              {code || ''}
            </TypographyGoingJP>
          </Grid>
        )}
        <Grid item={true}>
          <Grid container={true}>
            <Grid item={true} xs={12}>
              <Button
                className={styles.button}
                size="large"
                variant="contained"
                color="primary"
                onClick={handleBack}
              >
                {entryListL.thanksStep.back}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </HeaderContentMUI>
  );
};

export default ThanksStep;
