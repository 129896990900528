import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { catchGetExtendedPayment, pay } from '@Model/extendedPayment/actions';
import {
  getExtendedTransaction,
  getUpdatedExtendedTransaction,
} from '@Model/extendedPayment/selectors';
import { MODULE_EXTENDED_PAYMENT } from '@Model/state/constants/constants';
import { getLoading } from '@Model/state/selectors';
import _Store from '@Store';
import ExtendedPayment from './ExtendedPayment.component';
import {
  IExtendedFromDispatch,
  IExtendedPaymentFromState,
} from './ExtendedPayment.types';

const mapStateToProps = (state: _Store.IState): IExtendedPaymentFromState => {
  return {
    extendedPayment: getExtendedTransaction(state),
    isLoading: getLoading(MODULE_EXTENDED_PAYMENT)(state),
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>,
): IExtendedFromDispatch => ({
  mounted: () => dispatch(catchGetExtendedPayment()),
  pay: () => dispatch(pay()),
});

export default connect<
  IExtendedPaymentFromState,
  IExtendedFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps,
)(ExtendedPayment);
