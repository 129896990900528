import { createSelector } from 'reselect';
import { _LOADING } from './../constants/constants';
import get from './get';

const VALUE_POSITION = 1;

const isAnyLoading = createSelector(
  [get],
  (state) => {
    return Object.entries(state).reduce(
      (previousValue, currentValue) =>
        previousValue || currentValue[VALUE_POSITION][_LOADING],
      false,
    );
  },
);

export default isAnyLoading;
