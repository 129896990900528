import config from '@Config';
import { IPriceCheckBody, ISelectedProduct } from '@Services/$price-api/types';

export const getPriceReduction = (
  discountCode: string,
  upSellSelected: boolean,
  price: number,
  dateTime: string,
  numberOfPeople: number,
  products: ISelectedProduct[],
  spaceId: number,
  configurationId: number | null,
  priceType: string,
): IPriceCheckBody | undefined => {
  if ((discountCode && discountCode.length) || upSellSelected) {
    const priceReduction: IPriceCheckBody = {
      agent: 'zagrywki-web',
      dateTime,
      numberOfPeople,
      price,
      products,
      salesChannelId: 12,
      spaceId,
    };

    if (discountCode && discountCode.length) {
      priceReduction.discount = {
        code: discountCode,
      };
    }

    if (upSellSelected) {
      priceReduction.upsell = {
        configurationId: configurationId || 0,
      };
    }

    priceReduction.rulePriceId = configurationId || 0;
    priceReduction.priceType = priceType;

    if (upSellSelected) {
      priceReduction.upsell = true as any; // TODO: nomalize
    }

    return priceReduction;
  }

  return undefined;
};
