import React, { FC } from 'react';

import cn from 'classnames';

import { IPageWrapperProps } from './PageWrapper.types';

import styles from './PageWrapper.module.scss';
import useStyles from './PageWrapper.styles';

const PageWrapper: FC<IPageWrapperProps> = ({ children, template }) => {
  const classes = useStyles();
  return (
    <div id={template} className={cn(styles.wrapper, classes.container)}>
      {children}
    </div>
  );
};

export default PageWrapper;
