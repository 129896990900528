import axios, { CancelTokenSource } from 'axios';

import config from '@Config';
import catchHttpError from '@Misc/helpers/api/catchHttpError';
import getData from '@Misc/helpers/api/getData';
import { IGetTermsSuccessPayload } from '@Model/reservation/types';
import {
  IBookingPayUUrlTransactionResponse,
  IBookingTransactionBody,
  ICompanyInfoResponse,
  IPayUUrlBody,
  ITransactionEmpikResponse,
} from './types';

const SOMETHING_WENT_WRONG_TEXT =
  'Coś poszło nie tak, proszę spróbuj jeszcze raz.';

class BookingAPi {
  private static getTransactionUrl(): string {
    return `${config.api.baseEmpikTicketUrl}transaction`;
  }

  private static getTransactionPayUUrl(): string {
    return `${config.api.baseUrl}payment`;
  }

  private static getTransactionEmpikDetailsUrl(id: string): string {
    return `${
      config.api.baseEmpikTicketUrl
    }transaction-details/${id.toLowerCase()}`;
  }

  private static getTermsUrl(eventSlug: string, runDateSlug: string): string {
    return `${config.api.baseEmpikTicketUrl}terms/${eventSlug}/${runDateSlug}`;
  }

  private static getCompanyDataUrl(nip: string): string {
    return `${config.api.baseInvoiceUrl}company-info/${nip}`;
  }

  private cancelTokenTransaction?: CancelTokenSource;

  public postTransaction(
    body: IBookingTransactionBody,
  ): Promise<IBookingPayUUrlTransactionResponse> {
    return new Promise<IBookingPayUUrlTransactionResponse>(
      (resolve, reject) => {
        this.cancelTokenTransaction = axios.CancelToken.source();

        axios
          .post(BookingAPi.getTransactionUrl(), body, {
            cancelToken: this.cancelTokenTransaction.token,
          })
          .then(getData)
          .then((response: IBookingPayUUrlTransactionResponse) => {
            resolve(response);
          })
          .catch((error) => reject(error.response.data));
      },
    );
  }

  public postPayUUrl(
    body: IPayUUrlBody,
  ): Promise<IBookingPayUUrlTransactionResponse> {
    return new Promise<IBookingPayUUrlTransactionResponse>(
      (resolve, reject) => {
        this.cancelTokenTransaction = axios.CancelToken.source();

        return axios
          .post(BookingAPi.getTransactionPayUUrl(), body, {
            cancelToken: this.cancelTokenTransaction.token,
          })
          .then(getData)
          .then((response: IBookingPayUUrlTransactionResponse) => {
            if (!response.error) {
              resolve(response);
            } else {
              reject(SOMETHING_WENT_WRONG_TEXT);
            }
          });
      },
    );
  }

  public getTerms(
    eventSlug: string,
    runDateSlug: string,
  ): Promise<IGetTermsSuccessPayload> {
    return new Promise<IGetTermsSuccessPayload>((resolve, reject) => {
      this.cancelTokenTransaction = axios.CancelToken.source();

      return axios
        .get(BookingAPi.getTermsUrl(eventSlug, runDateSlug))
        .then(getData)
        .then((response: IGetTermsSuccessPayload) => {
          resolve(response);
        })
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public getCompanyData(nip: string): Promise<ICompanyInfoResponse> {
    return new Promise<ICompanyInfoResponse>((resolve, reject) => {
      return axios
        .get(BookingAPi.getCompanyDataUrl(nip))
        .then(getData)
        .then((response: ICompanyInfoResponse) => {
          resolve(response);
        })
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public getTransactionEmpikDetails(
    id: string,
  ): Promise<ITransactionEmpikResponse> {
    return new Promise<ITransactionEmpikResponse>((resolve, reject) => {
      this.cancelTokenTransaction = axios.CancelToken.source();

      return axios
        .get(BookingAPi.getTransactionEmpikDetailsUrl(id))
        .then(getData)
        .then((response: ITransactionEmpikResponse) => {
          resolve(response);
        })
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public cancelPostTransaction() {
    if (this.cancelTokenTransaction) {
      this.cancelTokenTransaction.cancel();
      this.cancelTokenTransaction = undefined;
    }
  }
}

export default new BookingAPi();
