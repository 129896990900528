import { Translation } from './pl';

const en: Translation = {
  entryListL: {
    addBuyerStep: {
      title: 'Will you be one of the participants?',
    },
    addNewPeopleToTicketStep: {
      addNewPerson: 'Add another person',
      checkData: 'Check that the data is correct',
      confirm: 'I confirm',
      description:
        'Add missing people or share the form with participants. This will shorten the formalities on the spot.',
      descriptionWithoutTicketCode:
        'Add missing people. This will shorten the formalities on the spot.',
      shareDescription:
        'The invitation to complete the data and accept the regulations has been sent.',
      shareTitle: 'Sent!',
      thanks: 'Thank you!',
      thanksDescWhenPersonIsInDatabase:
        'Person correctly assigned to the ticket. As this person has been with us before, there is no need to re-register.',
      thanksDescription:
        'The person has been correctly added and the registration form has been sent.',
      title: 'You need more to complete the process',
    },
    addPeopleToTicketStep: {
      description: 'Select the people you want to add to the ticket',
      noData: 'No related entries',
      title: 'Is any of the people you visited us with before going with you?',
    },
    back: 'Back',
    entryTerm: {
      confirm: 'Confirm',
      emailModal: {
        alertDescription:
          'Provide the e-mail addresses of people who will participate in the game and provide them with the form and regulations for approval. All participants must do this before entering, and by doing it online you do not waste time on the formalities on the spot.',
        alertTitle: 'Share the form and regulations with participants.',
      },
      error: 'Something went wrong! Try again.',
      retry: 'Try again',
      saveAndCompleteLater: 'Save and complete later',
      sendEmail: 'Share',
    },
    haveTicketCodeStep: {
      haveTicketCode: 'Do you have a ticket code?',
      title: 'Fill in the additional data',
    },
    next: 'Next',
    no: 'No',
    numberOfPeopleStep: {
      error: 'The number of people must be greater than 0.',
      inputHelperText:
        'Enter how many people are going to the event as part of the purchased ticket',
      inputText: 'Number of people on the ticket',
      numberError: 'The value provided must be a number.',
    },
    registerNewPerson: {
      title: 'Registration',
    },
    thanksStep: {
      back: 'Back to start',
      code: 'Your code to enter at the entrance is:',
      saved: 'Saved!',
      savedDescription:
        'We have saved the form at this stage. You can go back to completing it at any time by clicking on the link sent to your e-mail.',
      thanks: 'Thank you!',
    },
    ticketStep: {
      enterTicketCode: 'Enter the ticket code to add people to it',
      ticketCode: 'Ticket code',
      ticketCodeHelper: 'Enter the ticket code received by e-mail',
    },
    title: 'Entry list',
    yes: 'Yes',
  },
};

export default en;
