import React, { useState } from 'react';

import cn from 'classnames';

import { IProduct } from '@Model/products/types';

import styles from './DropDown.module.scss';

const SELECT_TEXT = 'wybierz';

export const DropDown = ({
  products,
  selectProduct,
}: {
  products: IProduct[];
  selectProduct: (product: IProduct) => void;
}) => {
  const [open, setOpen] = useState<boolean>(false);

  const handleSelectProduct = (product: IProduct) => {
    setOpen(false);
    selectProduct(product);
  };

  const sortedProducts = products.filter((product) => !product.selected);

  if (!sortedProducts.length) {
    return null;
  }

  return (
    <div
      className={cn(styles.dropDown, open && styles.open)}
      // tslint:disable-next-line: jsx-no-lambda
      onClick={() => setOpen(!open)}
    >
      <div className={styles.title}>{SELECT_TEXT}</div>
      {open &&
        sortedProducts.map((product) => (
          <div
            key={product.name}
            className={styles.product}
            // tslint:disable-next-line: jsx-no-lambda
            onClick={() => handleSelectProduct(product)}
          >
            {product.name}
          </div>
        ))}
    </div>
  );
};
