import { IZagrywkiHappeningPrice } from '@Services/$extendedPayment-api/types';
import { IHappeningResponseConfiguration } from '@Services/$happenings-api/types';
import { createSelector } from 'reselect';
import getSpaces from './getSpaces';

const getAvailablePriceTypes = createSelector(
  [getSpaces],
  (spaces) => {
    const allPriceRules: IHappeningResponseConfiguration[] = [];
    const allPrices: IZagrywkiHappeningPrice[] = [];

    spaces.forEach((space) => {
      if (space.rulePrice) {
        space.rulePrice.forEach((rule) => allPriceRules.push(rule));
      }
    });

    allPriceRules.forEach((priceRule) =>
      priceRule.prices.forEach((price) => allPrices.push(price)),
    );

    return allPrices.filter(
      (price, index, arr) =>
        arr.findIndex((value) => value.type === price.type) === index,
    );
  },
);

export default getAvailablePriceTypes;
