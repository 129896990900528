import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { isMobile, isSmallMobile, isTablet } from '@Model/app/selectors';
import { setSlot } from '@Model/booking/actions';
import getGroupedTimeSlots from '@Model/booking/selector/getGroupedTimeSlots';
import { getSelectedSpaceIsScopeView } from '@Model/happening/selectors';
import _Store from '@Store';
import OverbookingTimeSlots from './OverbookingTimeSlots.component';
import {
  IOverbookingTimeSlotsFromDispatch,
  IOverbookingTimeSlotsFromState,
} from './OverbookingTimeSlots.types';

const mapStateToProps = (
  state: _Store.IState,
): IOverbookingTimeSlotsFromState => {
  return {
    isScopeView: getSelectedSpaceIsScopeView(state),
    isSmallScreen: isSmallMobile(state) || isMobile(state) || isTablet(state),
    timeSlots: getGroupedTimeSlots(state),
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>,
): IOverbookingTimeSlotsFromDispatch => {
  return {
    setSlot: (slot: string) => dispatch(setSlot(slot)),
  };
};

export default connect<
  IOverbookingTimeSlotsFromState,
  IOverbookingTimeSlotsFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps,
)(OverbookingTimeSlots);
