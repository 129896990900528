import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { isBigDesktop, isDesktop } from '@Model/app/selectors';
import { getSelectedNumberOfPlayers } from '@Model/booking/selector';
import calculateUpSellProps from '@Model/booking/selector/calculateUpSellProps';
import {
  selectProduct,
  setProductCount,
  unSelectProduct,
} from '@Model/products/actions';
import _Store from '@Store';
import SingularProduct from './SingularProduct';
import {
  ISingularProductsFromDispatch,
  ISingularProductsFromState,
} from './SingularProduct.types';

const mapStateToProps = (state: _Store.IState): ISingularProductsFromState => {
  const values = calculateUpSellProps(state);

  return {
    ...values,
    isBigDesktop: isBigDesktop(state),
    isDesktop: isDesktop(state),
    peopleCount: getSelectedNumberOfPlayers(state),
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch,
): ISingularProductsFromDispatch => ({
  selectProduct: (product) => dispatch(selectProduct(product)),
  setProductCount: (product) => dispatch(setProductCount(product)),
  unSelectProduct: (product) => dispatch(unSelectProduct(product)),
});

export default connect<
  ISingularProductsFromState,
  ISingularProductsFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps,
)(SingularProduct);
