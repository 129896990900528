export const formTranslations = {
  i18n: {
    pl: {
      'Add Another': 'Dodaj kolejne',
      'Drop files to attach,': 'Przeciągnij,',
      'File Name': 'Nazwa Pliku',
      'No results found': 'Brak wyników',
      Size: 'Rozmiar',
      Submit: 'Wyślij',
      'Type to search': 'Wyszukaj',
      april: 'Kwiecień',
      array: '{{field}} powinno być tablicą.',
      array_nonempty: '{{field}} nie powinno być pustą tablicą.',
      august: 'Sierpień',
      browse: 'przeglądaj',
      cancel: 'Anuluj',
      complete: 'Zgłoszenie wysłane.',
      day: 'Dzień',
      december: 'Grudzień',
      error: 'Proszę popraw błędy przed wysłaniem formularza.',
      february: 'Luty',
      invalid_date: 'Pole {{field}} nie zawiera poprawnej daty.',
      invalid_day: '{{field}} nie jest poprawnym dniem tygodnia.',
      invalid_email: 'Pole {{field}} nie zawiera poprawnego adresu e-mail.',
      invalid_regex: '{{field}} nie jest zgodne z wyrażeniem {{regex}}.',
      invalid_url: '{{field}} powinno być adresem url.',
      january: 'Styczeń',
      july: 'Lipiec',
      june: 'Czerwiec',
      march: 'Marzec',
      mask: '{{field}} nie jest właściwe.',
      max: '{{field}} nie może być dłuższe niż {{max}}.',
      maxDate: '{{field}} nie powinno być po {{- maxDate}}.',
      maxLength: '{{field}} powinno być krótsze niż {{length}} znaki/ów.',
      maxWords: '{{field}} powinno zawierać mniej niż {{length}} słów.',
      maxYear: '{{field}} nie powinno być później niż {{maxYear}}',
      may: 'Maj',
      min: '{{field}} nie powinno być krótsze niż {{min}}.',
      minDate: '{{field}} nie powinno być przed {{- minDate}}.',
      minLength: '{{field}} powinno być dłuższe niż {{length}} znaki/ów.',
      minWords: '{{field}} powinno zawierać przynajmniej {{length}} słów.',
      minYear: '{{field}} nie powinno być przed {{minYear}}.',
      month: 'Miesiąc',
      next: 'Dalej',
      nonarray: '{{field}} nie powinno być tablicą.',
      november: 'Listopad',
      october: 'Październik',
      or: 'lub',
      pattern: '{{field}} nie pasuje do {{pattern}}.',
      previous: 'Wstecz',
      required: 'Pole {{field}} jest wymagane.',
      select: 'W {{field}} została wybrana niewłaściwa pozycja.',
      september: 'Wrzesień',
      stripe: '{{stripe}}',
      submit: 'Wyślij formularz',
      submitError:
        'Przed wysłaniem sprawdź formularz i popraw wszystkie błędy.',
      unique: '{{field}} powinno być unikalne.',
      year: 'Rok',
    },
  },
  language: 'pl',
};
