import { getType } from 'typesafe-actions';
import { setDevice } from './../../actions';
import { DESKTOP } from './../../constants/constants';
import { IAction, IReducerDevice } from './../../types';

const initialState: IReducerDevice = DESKTOP;

const reducer = (
  state: IReducerDevice = initialState,
  action: IAction,
): IReducerDevice => {
  switch (action.type) {
    case getType(setDevice):
      return action.payload;

    default:
      return state;
  }
};

export default reducer;
