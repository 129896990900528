import config from '@Config';
import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginBottom: theme.spacing(config.spacings.humongous),
  },
}));

export default useStyles;
