import { connect } from 'react-redux';

import {
  getExtendedTransaction,
  getUpdatedExtendedTransaction,
} from '@Model/extendedPayment/selectors';
import _Store from '@Store';
import PersonalData from './PersonalData.component';
import { IPersonalDataFromState } from './PersonalData.types';

const mapStateToProps = (state: _Store.IState): IPersonalDataFromState => {
  return {
    extendedPayment: getExtendedTransaction(state),
    updatedTransaction: getUpdatedExtendedTransaction(state),
  };
};

export default connect<IPersonalDataFromState, null, {}, _Store.IState>(
  mapStateToProps,
)(PersonalData);
