import { getHappening } from '@Model/happening/actions';
import _Store from '@Store';
import { filter as filter$, map as map$ } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { resetLoading, setLoading } from './../actions';
import { MODULE_HAPPENING } from './../constants/constants';

export const whenHappeningRequestedSetLoading: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf(getHappening.request)),
    map$(() => setLoading(MODULE_HAPPENING)),
  );
};

export const whenHappeningFinishedResetLoading: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$((action: _Store.IAction) =>
      isActionOf([getHappening.success, getHappening.failure], action),
    ),
    map$(() => resetLoading(MODULE_HAPPENING)),
  );
};
