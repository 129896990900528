import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    borderColor: theme.palette.secondary.dark,
    borderStyle: 'solid',
    borderWidth: 3,
  },
}));

export default useStyles;
