import { createAsyncAction, createStandardAction } from 'typesafe-actions';

import * as CONSTANTS from '../constants/actions';
import { IGetProductsPayload, IProduct } from '../types';

export const getProducts = createAsyncAction(
  CONSTANTS._GET_REQUEST,
  CONSTANTS._GET_SUCCESS,
  CONSTANTS._GET_FAILURE,
)<IGetProductsPayload, IProduct[], Error>();

export const getCarnets = createAsyncAction(
  CONSTANTS._GET_CARNETS_REQUEST,
  CONSTANTS._GET_CARNETS_SUCCESS,
  CONSTANTS._GET_CARNETS_FAILURE,
)<IGetProductsPayload, IProduct[], Error>();

export const setProductCount = createStandardAction(
  CONSTANTS.SET_PRODUCT_COUNT,
)<{
  product: IProduct;
  count: number;
}>();
export const selectProduct = createStandardAction(CONSTANTS.SELECT_PRODUCT)<
  IProduct
>();
export const unSelectProduct = createStandardAction(
  CONSTANTS.UN_SELECT_PRODUCT,
)<IProduct>();

export const updateList = createStandardAction(CONSTANTS.UPDATE_LIST)<
  IProduct[]
>();

export const carnetsMounted = createStandardAction(CONSTANTS.CARNETS_MOUNTED)();
export const selectCarnet = createStandardAction(CONSTANTS.SELECT_CARNET)<
  IProduct
>();
