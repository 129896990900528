import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { getConfigurationForSelectedSpace } from '@Model/booking/selector';
import calculateUpSellProps from '@Model/booking/selector/calculateUpSellProps';
import { selectProduct } from '@Model/products/actions';
import { getProducts } from '@Model/products/selectors/index';
import _Store from '@Store';
import Products from './Products.component';
import { IProductsFromDispatch, IProductsFromState } from './Products.types';

const mapStateToProps = (state: _Store.IState): IProductsFromState => {
  const values = calculateUpSellProps(state);
  const configuration = getConfigurationForSelectedSpace(state);
  const { items } = getProducts(state);

  return {
    ...values,
    haveUpSell: !!(configuration && configuration.upsell),
    products: items,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): IProductsFromDispatch => ({
  selectProduct: (product) => dispatch(selectProduct(product)),
});

export default connect<
  IProductsFromState,
  IProductsFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps,
)(Products);
