import { LOCATION_CHANGE } from 'connected-react-router';
import { from as from$, of as of$ } from 'rxjs';
import {
  catchError as catchError$,
  filter as filter$,
  map as map$,
  mergeMap as mergeMap$,
  takeUntil as takeUntil$,
  tap as tap$,
  withLatestFrom as withLatestFrom$,
} from 'rxjs/operators';
import { isActionOf, isOfType } from 'typesafe-actions';

import get from '@Model/iframe/selectors/get';
import { IHappeningsResponse } from '@Services/$happenings-api/types';
import _Store from '@Store';
import { getHappenings, happeningsMounted, resetState } from './../actions';

export const whenHappeningsMounted: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf(happeningsMounted)),
    mergeMap$(() => {
      return of$(resetState(), getHappenings.request());
    }),
  );
};

export const whenHappeningsRequested: _Store.IEpic = (
  action$,
  state$,
  { happeningsApi },
) => {
  return action$.pipe(
    filter$(isActionOf(getHappenings.request)),
    withLatestFrom$(state$),
    mergeMap$(([_, state]) => {
      const { partnerId } = get(state);

      return from$(happeningsApi.getHappenings(partnerId)).pipe(
        map$((data: IHappeningsResponse) => {
          const normalizedData = happeningsApi.normalizeHappenings(data);

          return getHappenings.success(normalizedData);
        }),
        takeUntil$(
          action$.pipe(
            filter$(isOfType(LOCATION_CHANGE)),
            tap$(() => happeningsApi.cancelHappenings()),
          ),
        ),
        catchError$((error: Error) => of$(getHappenings.failure(error))),
      );
    }),
  );
};
