import React, { useEffect, useState } from 'react';

import cn from 'classnames';
import moment from 'moment';
import { Form } from 'react-formio';

import Loading from '@Compo/layout/Loading';
import DatePickerModal from './../Components/ConfigurationModal/DatePickerModal.component';
import {
  checkUserExist,
  DEFAULT_PHONE_NUMBER,
  sendSms,
} from './EntryTerm.helpers';
import {
  IEntryTermProps,
  IFormIoChange,
  IFormIoCustomEvent,
  IFormIoNextPage,
  IFormIoPrevPage,
  ISubmission,
} from './EntryTerm.types';
import { formTranslations } from './Formio.translations';

import './EntryTerm.module.scss';

const EntryTerm = ({
  formSlug,
  passCode,
  checkUserApiUrl,
  refreshPage,
  scrollIframeToTop,
  partnerId,
  catchSaveUser,
}: IEntryTermProps) => {
  const [submission, setSubmission] = useState<ISubmission>({
    data: {
      page1Kodrezerwacji: passCode,
    },
  } as ISubmission);
  const [page, setPage] = useState<number>(0);
  const [formUrl, setFormUrl] = useState<string>(formSlug);
  const [loading, setLoading] = useState<boolean>(false);
  const [redirected, setRedirected] = useState<boolean>(false);
  const [checkedUser, setCheckedUser] = useState<boolean>(false);
  const [smsSended, setSmsSended] = useState<boolean>(false);
  const [filteredInputs, setFilteredInputs] = useState<HTMLInputElement[]>([]);
  const [showDatePicker, handleShowDatePicker] = useState(false);
  const [iframeQueue, setIframeQueue] = useState<
    Array<{ key: string; value: string }>
  >([]);

  useEffect(() => {
    if (passCode) {
      setSubmission({
        data: {
          page1Kodrezerwacji: passCode,
        },
      });
    } else {
      setSubmission({} as ISubmission);
    }
  }, []);

  const onChange = (data: IFormIoChange) => {
    const { autoRedirectFormPath } = data.data;

    if (autoRedirectFormPath && autoRedirectFormPath.length && !redirected) {
      setRedirected(true);

      setTimeout(() => {
        resetForm();
      }, 5000);
    }
  };

  const tryBuildIframe = (domElementName: string, iframeValue: string) => {
    const pdfUrl = `https://docs.google.com/gview?url=${iframeValue}&embedded=true`;
    if (domElementName.startsWith('iframe')) {
      addToIframeQueue(domElementName, iframeValue);

      const elements = document.getElementsByName(`data[${domElementName}]`);

      if (elements && elements.length) {
        const element = elements[0];

        const iframe = document.createElement('div');

        const odlIframe = document.getElementById(domElementName);

        if (odlIframe) {
          odlIframe.remove();
        }

        iframe.style.cssText = 'display: flex; justify-content: center;';

        const ifrm = document.createElement('iframe');
        ifrm.setAttribute('src', pdfUrl);
        ifrm.setAttribute('width', '100%');
        ifrm.setAttribute('id', domElementName);
        ifrm.setAttribute('height', '500px');
        ifrm.setAttribute('scrolling', 'no');
        ifrm.setAttribute('frameborder', '0');

        ifrm.onload = () => {
          removeFromIframeQueue(domElementName);
        };

        iframe.innerHTML = '';
        iframe.appendChild(ifrm);

        const parent = element.parentNode;

        if (parent) {
          parent.insertBefore(iframe, element);
        }
      }
    }
  };

  const checkIframeElement = (data: ISubmission) => {
    const keys = Object.keys(data.data) as Array<keyof typeof data.data>;
    keys.forEach((key) => {
      const value = data.data[key];

      if (typeof value === 'string') {
        tryBuildIframe(key, value);
      } else if (
        key === 'page2Enterform' &&
        value &&
        typeof value !== 'number' &&
        value.data
      ) {
        const keysSecond = Object.keys(value.data) as Array<
          keyof typeof value.data
        >;

        keysSecond.forEach((keySecond) => {
          const valueSecond = value.data[keySecond];

          if (valueSecond) {
            tryBuildIframe(keySecond, valueSecond);
          }
        });
      }
    });
  };

  const resetForm = () => {
    refreshPage();
  };

  useEffect(() => {
    return () => {
      filteredInputs.forEach((input) =>
        input.removeEventListener('keypress', function(e) {
          keyPress(this, e);
        }),
      );
    };
  }, []);

  const checkUserOnFirsPage = (data: IFormIoNextPage) => {
    const {
      page1RedirectAfterSubmit,
      page1Email,
      page1Imi,
      page1Kodrezerwacji,
    } = data.submission.data;

    const redirectSuccess = () => {
      if (page1RedirectAfterSubmit) {
        setLoading(false);
        setFormUrl(page1RedirectAfterSubmit);
      }
    };
    const stopLoading = () => {
      setLoading(false);
    };

    if (
      page1Email &&
      page1Email.length &&
      page1Imi &&
      page1Imi.length &&
      page1Kodrezerwacji &&
      page1Kodrezerwacji.length &&
      !checkedUser
    ) {
      setCheckedUser(true);
      setLoading(true);
      checkUserExist(
        checkUserApiUrl,
        page1Imi,
        page1Email,
        page1Kodrezerwacji,
        redirectSuccess,
        stopLoading,
        partnerId,
      );
    }
  };

  const tryAddEventListenerForDatePicker = (data: any) => {
    const elementList = document.getElementsByClassName(
      'formio-component-dataurodzenia2 ',
    );

    if (elementList && elementList[0]) {
      setSubmission({ data: data.component._data });

      elementList[0].addEventListener('click', () => {
        handleShowDatePicker(!showDatePicker);
        scrollIframeToTop();
      });
    }
  };

  const onNextPage = (data: IFormIoNextPage) => {
    setPage(data.page);

    const {
      page: currentPage,
      submission: {
        data: {
          page4Numbergen,
          page1SmsGateWay,
          page1SmsTekst,
          page3phone,
          page3parentfirstname,
          page3parentlastname,
        },
      },
    } = data;

    scrollIframeToTop();

    if (currentPage === 1) {
      checkUserOnFirsPage(data);
    } else if (
      data &&
      currentPage === 3 &&
      page4Numbergen &&
      page1SmsGateWay &&
      page1SmsTekst &&
      page3phone &&
      !smsSended
    ) {
      setSmsSended(true);
      setTimeout(() => {
        sendSms(page1SmsGateWay, page3phone, page4Numbergen, page1SmsTekst);
      }, 300);
    } else if (
      data.page === 4 &&
      (page3parentfirstname === '' ||
        (page3parentfirstname && !page3parentfirstname.length) ||
        page3parentfirstname === '-')
    ) {
      setSubmission({
        data: {
          ...data.submission.data,
          page3parentfirstname: page3parentfirstname || '-',
          page3parentlastname: page3parentlastname || '-',
          page3phone: page3phone || DEFAULT_PHONE_NUMBER,
        },
      });
    }
  };

  const onPrevPage = (data: IFormIoPrevPage) => {
    setPage(data.page);

    if (data.page === 3) {
      setSubmission({
        data: {
          ...data.submission.data,
        },
      });
    }
  };

  const onSubmitDone = (data: ISubmission) => {
    if (data.data.page2Enterform && data.data.userFormUrl) {
      catchSaveUser({
        data: data.data.page2Enterform,
        url: data.data.userFormUrl,
      });
    }
    if (data.data.page1RedirectAfterSubmit) {
      setLoading(false);
      setFormUrl(data.data.page1RedirectAfterSubmit);
    }
  };

  const onCustomEvent = (data: IFormIoCustomEvent) => {
    if (data.type === 'smsResend') {
      if (
        data &&
        data.data.page4Numbergen &&
        data.data.page1SmsGateWay &&
        data.data.page1SmsTekst &&
        data.data.page3phone
      ) {
        sendSms(
          data.data.page1SmsGateWay,
          data.data.page3phone,
          data.data.page4Numbergen,
          data.data.page1SmsTekst,
        );
      }
    } else if (data.type === 'redirect') {
      refreshPage();
    }
  };

  const keyPress = (that: HTMLInputElement, e: KeyboardEvent) => {
    if (e.which === 13) {
      e.preventDefault();
      const nextInput = document.querySelectorAll(
        `[tabIndex="${that.tabIndex + 1}"]`,
      );
      if (nextInput.length === 0) {
        const button = document.getElementsByClassName(
          'btn-wizard-nav-next',
        )[0];
        if (button instanceof HTMLButtonElement) {
          button.click();
        }
      }
      if (nextInput[0] && nextInput[0] instanceof HTMLInputElement) {
        const input = nextInput[0] as HTMLInputElement;
        input.focus();
      }
    }
  };

  const formLoad = () => {
    setLoading(false);
  };

  const onRender = (data: any) => {
    tryAddEventListenerForDatePicker(data);
    if (data && data.component && data.component._data) {
      checkIframeElement({ data: data.component._data });
    }

    document
      .getElementsByClassName('btn-wizard-nav-cancel')[0]
      .addEventListener('click', () => refreshPage());
    setLoading(false);
    setCheckedUser(false);
    const inputs = document.getElementsByClassName(
      'form-control',
    ) as HTMLCollectionOf<HTMLInputElement>;
    const elements = Array.from(inputs);
    const filteredInput = elements.filter((el) => el.hasAttribute('tabindex'));
    setFilteredInputs(filteredInput);
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < filteredInput.length; i++) {
      filteredInput[i].addEventListener('keypress', function(e) {
        keyPress(this, e);
      });
    }
  };

  const addToIframeQueue = (key: string, value: string) => {
    setIframeQueue((oldState) => {
      if (oldState.find((iframe) => iframe.key === key)) {
        return oldState;
      }
      return [...oldState, { key, value }];
    });
  };

  const removeFromIframeQueue = (key: string) => {
    setIframeQueue((oldState) => {
      const newQueue = oldState.filter((iframe) => {
        return iframe.key !== key;
      });

      return newQueue;
    });
  };

  const runIframeQueue = () => {
    setIframeQueue((oldState) => {
      if (oldState.length) {
        oldState.forEach(({ key, value }) => {
          tryBuildIframe(key, value);
        });
      }

      return oldState;
    });
  };

  const handleData = (date: Date) => {
    handleShowDatePicker(false);

    setSubmission((oldState) => {
      const data = {
        ...oldState.data,
        page2Enterform: {
          data: {
            ...(oldState && oldState.data && oldState.data.page2Enterform
              ? oldState.data.page2Enterform.data
              : {}),
            dataurodzenia: moment(date).format('YYYY-MM-DD'),
            dataurodzenia2: moment(date).format('YYYY-MM-DD'),
          },
        },
      };
      return {
        ...oldState,
        data,
      };
    });
  };

  useEffect(() => {
    if (iframeQueue && iframeQueue.length) {
      const interval = setInterval(() => {
        runIframeQueue();
      }, 5000);
      return () => clearInterval(interval);
    }
  }, [iframeQueue]);
  return (
    <>
      {showDatePicker && <DatePickerModal onChange={handleData} />}
      {(loading || (!!iframeQueue.length && page === 1)) && <Loading />}
      <div id="formio" className={cn(loading && 'hidden')}>
        <Form
          id="formio"
          src={formUrl}
          options={formTranslations}
          submission={submission}
          onNextPage={onNextPage}
          onSubmitDone={onSubmitDone}
          onFormLoad={formLoad}
          onRender={onRender}
          onChange={onChange}
          onCustomEvent={onCustomEvent}
          scrollableYearDropdown={true}
        />
      </div>
    </>
  );
};

export default EntryTerm;
