import { connect } from 'react-redux';
import { Dispatch, AnyAction } from 'redux';

import _Store from '@Store';

import { IAddBuyerStepFromDispatch } from './AddBuyerStep.types';
import AddBuyerStep from './AddBuyerStep.component';
import { newUserRegistration } from '@Model/analytics/actions';

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>,
): IAddBuyerStepFromDispatch => ({
  newUserRegistration: (email) => dispatch(newUserRegistration(email)),
});

export default connect<{}, IAddBuyerStepFromDispatch, {}, _Store.IState>(
  null,
  mapDispatchToProps,
)(AddBuyerStep);
