import React from 'react';

import { Route, Switch } from 'react-router';
import routes from './routes';

// Route components
import Error404 from '@Compo/errors/Error404';
import Carnets from '@Compo/pages/Carnets';
import NewTermsHangar from '@Compo/pages/CustomByClient/NewTermsHangar';
import TermsHangar from '@Compo/pages/CustomByClient/TermsHangar';
import ExtendedPayment from '@Compo/pages/ExtendedPayment';
import ExternalForm from '@Compo/pages/ExternalForm';
import Happening from '@Compo/pages/Happening';
import Main from '@Compo/pages/Main';
import Reservation from '@Compo/pages/Reservation';
import Start from '@Compo/pages/Start';
import Summary from '@Compo/pages/Summary';
import SummaryFail from '@Compo/pages/SummaryFail';

const Routes = () => (
  <>
    <Switch>
      <Route exact={true} path={routes.summary} component={Summary} />
      <Route exact={true} path={routes.paymentFail} component={SummaryFail} />
      <Route exact={true} path={routes.index} component={Main} />
      <Route exact={true} path={routes.happening} component={Happening} />
      <Route exact={true} path={routes.reservation} component={Reservation} />
      <Route exact={true} path={routes.start} component={Start} />
      {/* <Route exact={true} path={routes.tickets} component={Tickets} /> */}
      <Route exact={true} path={routes.termsHangar} component={TermsHangar} />
      <Route
        exact={true}
        path={routes.newTermsHangar}
        component={NewTermsHangar}
      />
      <Route exact={true} path={routes.externalForm} component={ExternalForm} />
      <Route
        exact={true}
        path={routes.extendedPayment}
        component={ExtendedPayment}
      />
      <Route exact={true} path={routes.carnets} component={Carnets} />
      <Route component={Error404} />
    </Switch>
  </>
);

export default Routes;
