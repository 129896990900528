import React from 'react';

import SingularProduct from '../SingularProduct';
import { DropDown } from './Components/DropDown/DropDown';
import { IProductsProps } from './Products.types';

import styles from './Products.module.scss';

const TITLE_TEXT = 'A może inne gadżety?';

const Products = ({ haveUpSell, products, selectProduct }: IProductsProps) => {
  return (
    <>
      {!!(haveUpSell || (products && products.length)) &&
        products
          .filter((product) => product.autocomplete)
          .map((product) => (
            <SingularProduct product={product} key={product.id} />
          ))}
      <div className={styles.additionalProducts}>
        <div className={styles.title}>{TITLE_TEXT}</div>
        <div className={styles.dropDown}>
          <DropDown products={products} selectProduct={selectProduct} />
        </div>
      </div>

      {!!(haveUpSell || (products && products.length)) &&
        products
          .filter((product) => !product.autocomplete && product.selected)
          .map((product) => (
            <SingularProduct product={product} key={product.id} />
          ))}
    </>
  );
};

export default Products;
