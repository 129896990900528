import HttpError from '@Misc/classes/HttpError';

interface IError {
  code?: number;
  message: string;
  response?: {
    data?: {
      message: string;
    };
    status?: number;
    code?: number;
  };
}

const SOMETHING_WENT_WRONG_TEXT =
  'Coś poszło nie tak, proszę spróbuj jeszcze raz.';

const catchHttpError = (error: any) => {
  if ('request' in error && error.request && 'status' in error.request) {
    return new HttpError('Http Error', error.request.status);
  }

  return error;
};

export const catchNewErrorMessage = (
  error: IError,
  errorCustomMessage?: string,
) => {
  if (errorCustomMessage) {
    return {
      message: errorCustomMessage,
    };
  } else if (
    error.response &&
    error.response.data &&
    error.response.data.message
  ) {
    return {
      message: error.response.data.message,
      statusCode: error.response.status,
    };
  } else if (error.response) {
    return {
      message: SOMETHING_WENT_WRONG_TEXT,
      statusCode: error.response.status,
    };
  } else if (error.message && error.code) {
    return {
      message: error.message,
      statusCode: error.code,
    };
  }
  return error;
};

export default catchHttpError;
