import { setUserData } from '@Model/reservation/actions';
import { getType } from 'typesafe-actions';
import { IAction, IReservationUser } from './../types';

const initialState: IReservationUser | null = null;

const userReducer = (
  state: IReservationUser | null = initialState,
  action: IAction,
): IReservationUser | null => {
  switch (action.type) {
    case getType(setUserData):
      return action.payload.data;

    default:
      return state;
  }
};

export default userReducer;
