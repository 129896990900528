import moment from 'moment';
import { createSelector } from 'reselect';

import config from '@Config';
import { addTimeWithMoment } from '@Misc/helpers/dateTime/addTime';
import getMetadataForLanguage from '@Misc/helpers/getMetadataForLanguage';
import {
  getActivityDuration,
  getConfigurationForSelectedSpace,
} from '@Model/booking/selector/index';
import { get as getHappening } from '@Model/happening/selectors';
import { IHappeningSpaceMetadata } from '@Model/happening/types/index';
import { IHappeningsItemMetadata } from '@Model/happenings/types/index';
import getValues from './getValues';

const MORNING = 'rana'; // polish verb form  "until morning" ;

const calculateUpSellProps = createSelector(
  [
    getValues,
    getConfigurationForSelectedSpace,
    getActivityDuration,
    getHappening,
  ],
  (values, configuration, duration, happening) => {
    if (!(values.slot && configuration && duration)) {
      return {
        additionalDuration: 0,
        finishTime: '',
        isUpSellSelected: false,
        name: '',
        upSellPrice: 0,
      };
    }

    const getName = (): string => {
      if (happening) {
        const selectedSpace =
          happening &&
          happening.spaces.find((_space) => _space.id === values.space);

        if (selectedSpace) {
          const happeningMetaData = getMetadataForLanguage<
            IHappeningsItemMetadata
          >(happening.metadata).title;

          const spaceMetaData = getMetadataForLanguage<IHappeningSpaceMetadata>(
            selectedSpace.metadata,
          ).title;

          return `${happeningMetaData} ${spaceMetaData}`;
        }
      }
      return '';
    };

    const getRegularPrice = (): number => {
      if (
        configuration &&
        configuration.prices &&
        configuration.prices.length
      ) {
        const defaultPriceKey = configuration.prices.findIndex(
          (_price) => _price.type === 'default',
        );

        if (defaultPriceKey !== -1) {
          return configuration.prices[defaultPriceKey].value;
        }

        return configuration.prices[0].value;
      }

      return 0;
    };

    const regularPrice = getRegularPrice();

    const time = values.slot ? values.slot : '';
    const eventStartTime = moment(time, 'HH:mm');
    const doubleDurationTime = duration ? 2 * duration : 0;
    let price = 0;

    const getPrice = (): number => {
      if (
        configuration.upsell &&
        configuration.upsell.prices &&
        configuration.upsell.prices.length
      ) {
        const defaultPriceKey = configuration.upsell.prices.findIndex(
          (_price) => _price.type === 'default',
        );

        if (defaultPriceKey !== -1) {
          return configuration.upsell.prices[defaultPriceKey].value;
        }

        return configuration.upsell.prices[0].value;
      }
      return 0;
    };

    price = getPrice();

    return {
      additionalDuration: duration,
      /*
       * TODO: this is hack. Now we know that upsell is only for simple event.
       */
      finishTime: values.slot
        ? addTimeWithMoment(eventStartTime, doubleDurationTime)
        : MORNING,
      name: getName(),
      upSellPrice: price - regularPrice,
    };
  },
);

export default calculateUpSellProps;
