import React from 'react';

import { Typography } from '@material-ui/core';
import cn from 'classnames';

import config from '@Config';
import getShortTime from '@Misc/helpers/dateTime/getShortTime';
import _L from '@Misc/helpers/local';
import LOCALS from '@Vars/constants';
import styles from './OverbookingTimeSlots.module.scss';
import { IOverbookingTimeSlotsProps } from './OverbookingTimeSlots.types';

const COME_ON_TIME_TEXT = _L(LOCALS.REMEMBERS_TEXT);
const EMPTY_SLOTS_TEXT = _L(LOCALS.EMPTY_SLOTS_TEXT);

const { isZagrywki, isHangar, isSaltos } = config.theme;

const getIcon = (): string => {
  // TODO: refactor to import
  if (isZagrywki) {
    return '/static/exclamation.svg';
  } else if (isHangar) {
    return '/static/exclamation_black.svg';
  }
  return '';
};

const OverbookingTimeSlots = ({
  isScopeView,
  isSmallScreen,
  setSlot,
  timeSlots,
}: IOverbookingTimeSlotsProps) => {
  if (!timeSlots.length) {
    return (
      <Typography align="center" variant="h5">
        {EMPTY_SLOTS_TEXT}
      </Typography>
    );
  }

  return (
    <div className={cn(styles.wrapper)}>
      {!isSmallScreen && timeSlots && timeSlots.length && !isSaltos ? (
        <div className={styles.info}>
          <img className={styles.infoIcon} src={getIcon()} />
          {COME_ON_TIME_TEXT}
        </div>
      ) : null}
      <ul className={cn(styles.timeSlots, 'S')}>
        <li className={styles.row}>
          {timeSlots.map((row) =>
            row.slots.map((timeSlot) =>
              !timeSlot.isDisabled ? (
                <button
                  className={cn(
                    styles.button,
                    timeSlot.isSelected && styles.selected,
                  )}
                  disabled={timeSlot.isDisabled}
                  key={timeSlot.start}
                  onClick={() => setSlot(timeSlot.start)}
                >
                  {isScopeView
                    ? `${getShortTime(timeSlot.start)} - ${timeSlot.duration}`
                    : getShortTime(timeSlot.start)}
                </button>
              ) : null,
            ),
          )}
        </li>
      </ul>
      {isSmallScreen && timeSlots && timeSlots.length ? (
        <div className={styles.info}>
          <img className={styles.infoIcon} src={getIcon()} />
          {COME_ON_TIME_TEXT}
        </div>
      ) : null}
    </div>
  );
};

export default OverbookingTimeSlots;
