import { resetLoading, setLoading } from '@Model/state/actions';
import { Reducer } from 'redux';
import { getType } from 'typesafe-actions';
import { _LOADING } from './../constants/constants';
import { IAction, ISubState } from './../types';

export const initialSubState: ISubState = {
  /*
   * When API asked and waits to response.
   */
  [_LOADING]: false,
};

const createSubStateReducer = (key: string): Reducer<ISubState, any> => (
  state = initialSubState,
  action: IAction,
): ISubState => {
  if (action.payload && action.payload.module === key) {
    switch (action.type) {
      case getType(resetLoading):
        return { ...state, [_LOADING]: false };

      case getType(setLoading):
        return { ...state, [_LOADING]: true };

      default:
        return state;
    }
  }

  return state;
};

export default createSubStateReducer;
