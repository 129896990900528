import { asyncRedirect } from '@Model/app/actions';
import { Dispatch } from 'react';
import { connect } from 'react-redux';
import { AnyAction } from 'redux';

import config from '@Config';
import { catchSaveUser } from '@Model/formio/actions';
import { refreshPage, scrollIframeToTop } from '@Model/iframe/actions';
import { get } from '@Model/iframe/selectors';
import _Store from '@Store';
import EntryTerm from './EntryTerm.component';
import {
  IEntryTermFromDispatch,
  IEntryTermFromState,
  IPage2Submission,
} from './EntryTerm.types';

const mapStateToProps = (state: _Store.IState): IEntryTermFromState => {
  const checkUserApiUrl = `${config.api.baseUrl}check-submission`;

  const { enterFromUrl: formSlug, passCode, partnerId } = get(state);
  return {
    checkUserApiUrl,
    formSlug,
    partnerId,
    passCode,
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>,
): IEntryTermFromDispatch => ({
  asyncRedirect: (path: string) => dispatch(asyncRedirect(path)),
  catchSaveUser: (userData: { data: IPage2Submission; url: string }) => {
    dispatch(catchSaveUser(userData));
  },
  refreshPage: () => dispatch(refreshPage()),
  scrollIframeToTop: () => dispatch(scrollIframeToTop()),
});

export default connect<
  IEntryTermFromState,
  IEntryTermFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps,
)(EntryTerm);
