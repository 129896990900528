import { getType } from 'typesafe-actions';

import { IAction, IProductsReducer } from '../types';
import {
  getCarnets,
  getProducts,
  selectCarnet,
  updateList,
} from './../actions/index';

const initialState: IProductsReducer = {
  carnets: [],
  items: [],
  selectedCarnet: null,
};

const productsReducer = (
  state: IProductsReducer = initialState,
  action: IAction,
): IProductsReducer => {
  switch (action.type) {
    case getType(getProducts.success):
      return {
        ...state,
        items: action.payload,
      };
    case getType(updateList):
      return {
        ...state,
        items: action.payload,
      };

    case getType(getCarnets.success):
      return {
        ...state,
        carnets: action.payload,
      };

    case getType(selectCarnet):
      return {
        ...state,
        selectedCarnet: action.payload,
      };

    default:
      return state;
  }
};

export default productsReducer;
