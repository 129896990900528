import { createStandardAction } from 'typesafe-actions';
import {
  ADD_TO_GLOBAL_BASKET,
  CAPTURE_EVENT_FROM_IFRAME,
  MOUNTED,
  REDIRECT_PARENT_TO,
  REDIRECT_TO_TRY_AGAIN,
  REFRESH_PAGE,
  RESIZE,
  RUN_FBQ_ACTION,
  SCROLL_TO,
  SCROLL_TO_TOP,
  SET_ADD_BASKET_REDIRECT,
  SET_BASKET,
  SET_CURRENT_URL_IFRAME_PROVIDER,
  SET_EMBED_BASKET,
  SET_ENTER_FROM_URL,
  SET_EXTENDED_PAYMENT,
  SET_EXTERNAL_FORM_URL,
  SET_PARTNER_ID,
  SET_PASS_CODE,
  SET_ROOM,
  SET_ROOM_URL,
  SET_TEMPLATE,
  SET_THEME,
  START_MOUNTED,
} from '../constants/actions';
import { IEvent, IFbqAction } from '../types';
import { IZagrywkiBasket } from './../../basket/types/index';

export const mounted = createStandardAction(MOUNTED)();

export const setRoom = createStandardAction(SET_ROOM)<string>();

export const setTheme = createStandardAction(SET_THEME)<string | null>();

export const captureIframeEvent = createStandardAction(
  CAPTURE_EVENT_FROM_IFRAME,
)<IEvent>();

export const startMounted = createStandardAction(START_MOUNTED)();

export const setParentUrl = createStandardAction(
  SET_CURRENT_URL_IFRAME_PROVIDER,
)<string>();

export const setPartnerId = createStandardAction(SET_PARTNER_ID)<
  string | null
>();

export const setTemplate = createStandardAction(SET_TEMPLATE)<string | null>();

export const setExtendedPayment = createStandardAction(SET_EXTENDED_PAYMENT)<
  string | null
>();

export const setExternalFormUrl = createStandardAction(SET_EXTERNAL_FORM_URL)<
  string | null
>();

export const setRoomUrl = createStandardAction(SET_ROOM_URL)<string>();

export const setBasket = createStandardAction(SET_BASKET)<boolean>();

export const setEmbedBasket = createStandardAction(SET_EMBED_BASKET)<boolean>();

export const setEnterFromUrl = createStandardAction(SET_ENTER_FROM_URL)<
  string | null
>();
export const setPassCode = createStandardAction(SET_PASS_CODE)<string | null>();

export const redirectToTryAgain = createStandardAction(REDIRECT_TO_TRY_AGAIN)();

export const setIframeSizes = createStandardAction(RESIZE)<string>();

export const scrollIframeTo = createStandardAction(SCROLL_TO)<number>();

export const scrollIframeToTop = createStandardAction(SCROLL_TO_TOP)();

export const refreshPage = createStandardAction(REFRESH_PAGE)();

export const runFbqAction = createStandardAction(RUN_FBQ_ACTION)<IFbqAction>();

export const addToGlobalBasket = createStandardAction(ADD_TO_GLOBAL_BASKET)<
  IZagrywkiBasket[]
>();

export const redirectParentTo = createStandardAction(REDIRECT_PARENT_TO)<
  string
>();

export const setAddBasketRedirect = createStandardAction(
  SET_ADD_BASKET_REDIRECT,
)<string | null>();
