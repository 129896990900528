import React, { useEffect, useRef } from 'react';

import { IframeProvider } from '@Services/$iframe-provider';
import { IFrameTypes } from './Iframe.types';

import styles from './Iframe.module.scss';

const Iframe = ({ url, height, actions }: IFrameTypes) => {
  const iframeEl = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    window.addEventListener(
      'message',
      (messageEvent: MessageEvent) =>
        IframeProvider.addEventListenerMethod(messageEvent, () => {
          actions.map(({ payload, type }) => {
            if (
              iframeEl &&
              iframeEl.current &&
              iframeEl.current.contentWindow
            ) {
              iframeEl.current.contentWindow.postMessage(
                JSON.stringify({
                  action: {
                    payload,
                    type,
                  },
                  type: 'GOING',
                }),
                '*',
              );
            }
          });
        }),
      false,
    );
  }, []);

  return (
    <iframe
      src={url}
      ref={iframeEl}
      className={styles.iframe}
      scrolling="no"
      style={{ height }}
    />
  );
};

export default Iframe;
