import { atom } from 'recoil';

import pl from '@/lang/pl';
import plHangar from '@/lang/plHangar';
import plSaltos from '@/lang/plSaltos';
import config from '@Config';
import { ITranslation } from './../types';

const mapPL = atom<ITranslation>({
  default: config.theme.isSaltos
    ? plSaltos
    : config.theme.isHangar
    ? plHangar
    : pl,
  key: 'mapPL',
});

export default mapPL;
