import React from 'react';

import { IApiAction } from '@Services/$iframe-provider/types';
import { IExternalFormProps } from './ExternalForm.types';
import Iframe from './Iframe';

const ExternalForm = ({
  iframeHeight,
  parentUrl,
  externalFormUrl,
}: IExternalFormProps) => {
  const actions: IApiAction[] = [
    {
      payload: parentUrl,
      type: 'SET_CURRENT_URL',
    },
    {
      payload: 'hangar',
      type: 'SET_CLIENT',
    },
    {
      type: 'PONG',
    },
  ];

  if (!externalFormUrl) {
    return null;
  }

  return (
    <>
      <Iframe url={externalFormUrl} height={iframeHeight} actions={actions} />
    </>
  );
};

export default ExternalForm;
