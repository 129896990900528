import moment from 'moment';
import { createSelector } from 'reselect';

import { addTimeWithMoment } from '@Misc/helpers/dateTime/addTime';
import getShortTime from '@Misc/helpers/dateTime/getShortTime';
import getActivityDuration from './getActivityDuration';
import getValues from './getValues';

const DEFAULT_RANGE = '';

const calculateTimeRange = createSelector(
  [getActivityDuration, getValues],
  (duration, values) => {
    const time = values.slot ? values.slot : '';
    const eventStartTime = moment(time, 'HH:mm');

    if (duration && values && values.slot) {
      return `${getShortTime(values.slot)} - ${addTimeWithMoment(
        eventStartTime,
        duration,
      )}`;
    }

    return DEFAULT_RANGE;
  },
);

export default calculateTimeRange;
