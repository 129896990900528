import React, { FC } from 'react';

import styles from './Checkbox.module.scss';
import { ICheckboxProps } from './Checkbox.types';

const Checkbox: FC<ICheckboxProps> = ({
  checked,
  children,
  onChange,
  hideCheck,
}) => {
  return (
    <label className={styles.label}>
      <input checked={checked} onChange={onChange} type="checkbox" />
      {!hideCheck && <span className={styles.selector} />}
      {children}
    </label>
  );
};

export default Checkbox;
