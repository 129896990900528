import { Theme, withStyles } from '@material-ui/core/styles';

import colorsHangar from '@/config/theme/colors/hangar';
import colorsSaltos from '@/config/theme/colors/saltos';
import config from '@Config';

const FormioOverride = withStyles((theme: Theme) => ({
  '@global': {
    '#formio': {
      overflow: 'auto',
      padding: theme.spacing(5),
    },
    '#formio .btn-wizard-nav-cancel': {
      display: 'none',
    },
    '#formio .btn-wizard-nav-next': {
      display: 'none',
    },
    '#formio .btn-wizard-nav-previous': {
      display: 'none',
    },
    '#formio .btn-wizard-nav-submit': {
      display: 'none',
    },
    '#formio .form-check-label': {
      color: theme.palette.text.primary,
      fontSize: 18,
      fontWeight: 500,
    },
    '#formio .form-control': {
      borderColor: theme.palette.other.stroke,
      borderRadius: theme.shape.borderRadius,
      borderWidth: 3,
      color: theme.palette.text.primary,
      fontSize: 16,
      height: 56,
      paddingBottom: 19,
      paddingLeft: 12,
      paddingRight: 12,
      paddingTop: 18,
    },
    '#formio .form-control:focus': {
      borderImageSlice: 1,
      borderImageSource: theme.palette.other.focused,
      boxShadow: 'none',
      outline: 'none',
    },
    '#formio .form-control::placeholder': {
      color: theme.palette.text.secondary,
    },
    '#formio .pagination': {
      display: 'none',
    },
    '#formio .summaryTitle': {
      color: theme.palette.text.primary,
      fontSize: 34,
      fontWeight: 500,
      marginBottom: theme.spacing(3),
    },
    '#formio .text-muted': {
      color: `${theme.palette.text.secondary} !important`,
      fontSize: 12,
      padding: 0,
    },
    '#formio .userData': {
      padding: theme.spacing(4),
    },
    '#formio .userData .customRow': {
      marginBottom: theme.spacing(3),
    },
    '#formio .userData .title': {
      color: theme.palette.text.secondary,
      fontSize: 18,
      fontWeight: 300,
    },
    '#formio .userData .value': {
      color: theme.palette.text.primary,
      fontSize: 24,
      fontWeight: 500,
    },
    '#formio a': {
      color: theme.palette.text.primary,
      textDecoration: 'underline',

      '&:hover': {
        color: theme.palette.text.primary,
      },
    },
    '#formio .btn-primary': {
      background: theme.formio?.button
        ? theme.formio.button
        : config.theme.isSaltos
        ? colorsSaltos.primary.main
        : colorsHangar.primary.main,
      borderColor: theme.palette.other.stroke,
      borderRadius: theme.shape.borderRadius,
      borderStyle: 'solid',
      borderWidth: 3,
      color: theme.palette.text.primary,
      fontSize: 15,
      fontWeight: 700,
      paddingBottom: 8,
      paddingLeft: 32,
      paddingRight: 32,
      paddingTop: 8,
      width: '100%',
    },
    '#formio .alert': {
      display: 'none',
    },
    '#formio .list-group-item': {
      border: 'none',
      padding: 0,
    },
    '#formio .formio-component-editgrid .btn-primary': {
      background: 'transparent',
      color: theme.palette.text.primary,
    },
    '#formio .form-check.checkbox': {
      paddingLeft: 30,
    },
    '#formio .form-check.checkbox span': {
      fontSize: 16,
    },
    '#formio .form-check.checkbox .form-check-input': {
      marginLeft: -30,
      marginTop: 5,
    },
  },
}))(() => null);

export default FormioOverride;
