import { createSelector } from 'reselect';

import getSelectedPrice from './getSelectedPrice';
import getSpaces from './getSpaces';
import getValues from './getValues';

const getSelectedSpaceCounter = createSelector(
  [getValues, getSpaces, getSelectedPrice],
  (selected, spaces, selectedPrice) => {
    const selectedSpace =
      spaces.find((space) => selected.space === space.id) || spaces[0];
    if (selectedSpace && selectedSpace.rulePrice) {
      const selectedCounter = selectedSpace.rulePrice[0].prices.find(
        (price) => price.type === (selectedPrice || 'default'),
      );
      if (selectedCounter) {
        return selectedCounter.counter || 1;
      }
      return 1;
    }
    return 1;
  },
);

export default getSelectedSpaceCounter;
