export enum spacing {
  noSpacing,
  tiny,
  small,
  regular,
  medium,
  large,
  huge,
  humongous,
  enormous,
  colossal,
}

export default spacing;
