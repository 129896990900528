import { setInnerSizes, setSizes } from '@Model/app/actions';
import { getSizes } from '@Model/app/selectors';
import _Store from '@Store';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';
import Resizer from './Resizer.component';
import { IResizerFromDispatch, IResizerFromState } from './Resizer.types';

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>,
): IResizerFromDispatch => {
  return {
    handleSetSizes: (width: number, height: number) =>
      dispatch(
        setSizes({
          height,
          width,
        }),
      ),

    handleSetInnerSize: (width: number, height: number) =>
      dispatch(
        setInnerSizes({
          height,
          width,
        }),
      ),
  };
};

function mapStateToProps(state: _Store.IState): IResizerFromState {
  return {
    sizes: getSizes(state),
  };
}

export default connect<
  IResizerFromState,
  IResizerFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps,
)(Resizer);
