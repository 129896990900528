import { ITransactionReducer } from '@Model/transaction/types';
import _Store from '@Store';
import { createSelector } from 'reselect';
import getTransactionDetails from './getTransactionDetails';

const getTransactionBookingIdentifier = createSelector<
  _Store.IState,
  ITransactionReducer | null,
  string
>(
  [getTransactionDetails],
  (transactionDetails: ITransactionReducer | null) =>
    (transactionDetails && transactionDetails.bookingIdentifier) || '',
);

export default getTransactionBookingIdentifier;
