import { setSelectedPrices } from '@Model/booking/actions';
import {
  getActivityDuration,
  getSelectedNumberOfPlayers,
  getSelectedPrices,
} from '@Model/booking/selector';
import {
  get as getHappening,
  getAvailablePriceTypes,
} from '@Model/happening/selectors';
import _Store from '@Store';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';
import TicketTypePicker from './TicketTypePicker.component';
import {
  ITicketTypePickerFromDispatch,
  ITicketTypePickerFromState,
} from './TicketTypePicker.types';

const mapStateToProps = (state: _Store.IState): ITicketTypePickerFromState => {
  const happening = getHappening(state);

  return {
    activityDuration: getActivityDuration(state),
    availablePriceTypes: getAvailablePriceTypes(state),
    maxPeopleCount: (happening && happening.maxNumberOfPeople) || 1,
    peopleCount: getSelectedNumberOfPlayers(state),
    selectedPrices: getSelectedPrices(state),
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>,
): ITicketTypePickerFromDispatch => ({
  setPeopleCount: (value, priceType) =>
    dispatch(setSelectedPrices({ numberOfPeople: value, priceType })),
});

export default connect<
  ITicketTypePickerFromState,
  ITicketTypePickerFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps,
)(TicketTypePicker);
