import React from 'react';

import BookForm from '@Compo/BookForm';
import Grid from '@Compo/layout/Grid';
import PageWrapper from '@Compo/layout/PageWrapper';

const ReservationPage = () => {
  return (
    <Grid>
      <PageWrapper>
        <BookForm />
      </PageWrapper>
    </Grid>
  );
};

export default ReservationPage;
