import React, { useEffect } from 'react';

import { ISummaryFailProps } from './SummaryFail.types';

import styles from './SummaryFail.module.scss';

const BOOKING_FAILED_TEXT = 'Podczas transakcji wystąpił błąd.';
const DO_IT_AGAIN_TEXT = 'Spróbuj ponownie.';

const SummaryFail = ({
  mounted,
  roomUrl,
  redirectToTryAgain,
}: ISummaryFailProps) => {
  useEffect(() => {
    mounted();
  }, []);
  return (
    <>
      <section className={styles.header}>
        <h1 className={styles.title}>{BOOKING_FAILED_TEXT}</h1>
      </section>
      <section className={styles.info}>
        <div onClick={redirectToTryAgain}>
          <div className={styles.button}>{DO_IT_AGAIN_TEXT}</div>
        </div>
      </section>
    </>
  );
};

export default SummaryFail;
