import { selectorFamily } from 'recoil';

import { formioData, iframeParams } from '@Recoil/formio/atoms';
import FormIoApi from '@Services/$formio-api';

const sendFormEmail = selectorFamily({
  get: (userData: {
    firstName?: string;
    lastName?: string;
    email?: string;
  }) => async ({ get }) => {
    try {
      const { reservationCode } = get(formioData);
      const { transactionHash, formIoId } = get(iframeParams);
      if (
        (reservationCode || transactionHash) &&
        userData.firstName &&
        userData.lastName &&
        userData.email
      ) {
        return await FormIoApi.sendFormEmailPost(
          reservationCode,
          userData.firstName,
          userData.lastName,
          userData.email,
          transactionHash,
          formIoId,
        );
      }
    } catch (e) {
      return e;
    }
  },
  key: 'sendFormEmail',
});

export default sendFormEmail;
