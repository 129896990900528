import moment from 'moment';
import { createSelector } from 'reselect';

import { addTimeWithMoment } from '@Misc/helpers/dateTime/addTime';
import getShortTime from '@Misc/helpers/dateTime/getShortTime';
import { getActivityDuration, getValues } from '@Model/booking/selector';
import calculatePrice from '@Model/booking/selector/calculatePrice';
import calculateTimeRange from '@Model/booking/selector/calculateTimeRange';
import get from '@Model/price/selectors/get';
import { isUpSellEnabled } from '@Model/reservation/selectors/index';

const calculateSummaryProps = createSelector(
  [
    get,
    calculatePrice,
    calculateTimeRange,
    isUpSellEnabled,
    getValues,
    getActivityDuration,
  ],
  (price, calculatedPrice, timeRange, upsell, values, duration) => {
    let usePrice = 0;
    let useTimeRange = timeRange;
    const doubleDurationTime = duration ? 2 * duration : 0;

    if (!price) {
      usePrice = calculatedPrice || 0;
    } else {
      usePrice = price.value || 0;
    }
    const time = values.slot ? values.slot : '';
    const eventStartTime = moment(time, 'HH:mm');

    if (upsell && values && values.slot && duration) {
      useTimeRange = `${getShortTime(values.slot)} - ${addTimeWithMoment(
        eventStartTime,
        doubleDurationTime,
      )}`;
    }

    return {
      price: usePrice,
      selectedTimeRange: useTimeRange,
    };
  },
);

export default calculateSummaryProps;
