import pl, { Translation } from './pl';

const plHangar: Translation = {
  ...pl,
  entryListL: {
    ...pl.entryListL,
    addNewPeopleToTicketStep: {
      ...pl.entryListL.addNewPeopleToTicketStep,
      title: 'Brakuje jeszcze',
    },
    entryTerm: {
      ...pl.entryListL.entryTerm,
      emailModal: {
        ...pl.entryListL.entryTerm.emailModal,
        alertDescription:
          'Podaj adresy e-mail osób z którymi wybierasz się do hangaru i udostępnij im formularz oraz regulamin do akceptacji. Wszyscy uczestnicy muszą być dodani do Twojej rezerwacji, a dodając się wcześniej nie będziecie tracić czasu na formalności na miejscu.',
      },
    },
    numberOfPeopleStep: {
      ...pl.entryListL.numberOfPeopleStep,
      inputText: 'Wpisz liczbę osób',
    },
  },
};

export default plHangar;
