import { IZagrywkiBasket } from '@Model/basket/types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { AnyAction, Dispatch } from 'redux';

import { isBigDesktop, isDesktop } from '@Model/app/selectors';
import getBasketData from '@Model/basket/selector/getBasketData';
import { getConfigurationForSelectedSpace } from '@Model/booking/selector';
import getHappening from '@Model/happening/selectors/get';
import {
  addToGlobalBasket,
  redirectParentTo,
} from '@Model/iframe/actions/index';
import { get as getIframe } from '@Model/iframe/selectors';
import { getProducts } from '@Model/products/selectors';
import { catchCompanyData, setFormData } from '@Model/reservation/actions';
import {
  getAdditionalTerms,
  isUpSellEnabled,
} from '@Model/reservation/selectors';
import { ITerm } from '@Model/reservation/types';
import { MODULE_INVOICE } from '@Model/state/constants/constants';
import { getLoading } from '@Model/state/selectors';
import _Store from '@Store';
import BookForm from './FormLayout.component';
import {
  IFormLayoutFromDispatch,
  IFormLayoutFromState,
} from './FormLayout.types';

const mapStateToProps = (state: _Store.IState): IFormLayoutFromState => {
  const configuration = getConfigurationForSelectedSpace(state);
  const { items, selectedCarnet } = getProducts(state);
  const additionalTerms = getAdditionalTerms(state);
  const areTermsAccepted = additionalTerms.every((term: ITerm) => {
    if (term.required) {
      return !!term.selected;
    }
    return true;
  });
  const happening = getHappening(state);

  return {
    addBasketRedirect: getIframe(state).addBasketRedirect,
    areTermsAccepted,
    currentBasketData: getBasketData(state),
    embedBasket: getIframe(state).embedBasket,
    haveUpSell: Boolean(configuration && configuration.upsell) && !!happening,
    isBigDesktop: isBigDesktop(state),
    isCarnet: !!selectedCarnet,
    isCurrentReservation: !!happening,
    isDesktop: isDesktop(state),
    isInvoiceLoading: getLoading(MODULE_INVOICE)(state),
    isUpSellSelected: isUpSellEnabled(state),
    products: items,
    showBasket: getIframe(state).showBasket,
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>,
): IFormLayoutFromDispatch => ({
  addToGlobalBasket: (items: IZagrywkiBasket[]) =>
    dispatch(addToGlobalBasket(items)),
  getCompanyData: (nip: string) => dispatch(catchCompanyData(nip)),
  redirectParentTo: (url) => dispatch(redirectParentTo(url)),
  setFormData: (data) => dispatch(setFormData(data)),
});

export default withRouter(
  connect<IFormLayoutFromState, IFormLayoutFromDispatch, {}, _Store.IState>(
    mapStateToProps,
    mapDispatchToProps,
  )(BookForm),
);
