import React, { FC } from 'react';

import { Typography } from '@material-ui/core';
import { IHeaderJPProps } from './models/HeaderJP';
import { useStyles } from './styles/HeaderJP.styles';

export const HeaderJP: FC<IHeaderJPProps> = ({ children, subheader }) => {
  const styles = useStyles();

  return (
    <Typography
      className={subheader ? styles.subheader : styles.container}
      variant={subheader ? 'h4' : 'h2'}
    >
      {children}
    </Typography>
  );
};
