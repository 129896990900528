import React, { useEffect, useState } from 'react';

import {
  Button,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  InputAdornment,
  Snackbar,
  TextField,
  Typography,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ClearIcon from '@material-ui/icons/Clear';
import { Alert, AlertTitle } from '@material-ui/lab';
import { useRecoilCallback, useRecoilValue, useSetRecoilState } from 'recoil';
import uuid from 'uuid';
import validator from 'validator';

import { personEmail, successMessageState } from '@Recoil/formio/atoms';
import { sendFormEmail } from '@Recoil/formio/selectors';
import { translation } from '@Recoil/lang/selectors';

import { IEmailUserData } from '@Recoil/formio/types';
import Analytics from '@Services/$analytics-provider';
import { IFormioSendEmailResponse } from '@Services/$formio-api/types';
import useStyles from './EmailModal.styles';
import { IEmailModalProps, IInputEmail } from './EmailModal.types';

const EMAIL_TEXT = 'Adres e-mail';
const EMAIL_HELPER_TEXT =
  'Wpisz adres e-mail osoby biorącej udział w wydarzeniu';
const ADD_TEXT = 'Dodaj kolejny e-mail';
const CANCEL_TEXT = 'Anuluj';
const SEND_TEXT = 'Wyślij';
const ERROR_TEXT = 'Wystąpił błąd spróbuj ponownie.';

const EmailModal = ({ close }: IEmailModalProps) => {
  const inputEmail = useRecoilValue(personEmail);
  const setSuccessMessage = useSetRecoilState(successMessageState);
  const { entryListL } = useRecoilValue(translation);
  const [emailInputs, setEmailInputs] = useState<IInputEmail[]>([
    { id: uuid.v4(), value: '', error: false },
  ]);
  const styles = useStyles();

  useEffect(() => {
    if (inputEmail) {
      setEmailInputs(() => [inputEmail]);
    }
  }, [inputEmail]);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const sendFormEmailUser = useRecoilCallback(
    ({ snapshot }) => (userData: IEmailUserData) => {
      return snapshot.getPromise(sendFormEmail(userData));
    },
  );
  const removeInput = (id: string) => {
    const filtered = emailInputs.filter((input) => id !== input.id);
    setEmailInputs(filtered);
  };
  const addInput = () => {
    setEmailInputs((oldState) => [
      ...oldState,
      { id: uuid.v4(), value: '', error: false },
    ]);
  };
  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    id: string,
  ) => {
    setEmailInputs(
      emailInputs.map((input) =>
        input.id === id ? { ...input, value: event.target.value } : input,
      ),
    );
  };
  const handleBlur = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    id: string,
  ) => {
    setEmailInputs(
      emailInputs.map((input) =>
        input.id === id
          ? { ...input, error: !validator.isEmail(getEmailValue(id)) }
          : input,
      ),
    );
  };
  const getEmailValue = (id: string) => {
    const email = emailInputs.find((input) => input.id === id);
    return email ? email.value : '';
  };
  const anyHasError = () => {
    return emailInputs.some((input) => input.error);
  };
  const anyPromiseHasError = (array: any[]) => {
    return array.some((value) => value.message && value.statusCode !== 200);
  };

  const submit = () => {
    const errorInputs = emailInputs.map((input) => ({
      ...input,
      error: !validator.isEmail(getEmailValue(input.id)),
    }));
    setEmailInputs(errorInputs);
    if (!errorInputs.some((input) => input.error)) {
      const emailPromises: Array<Promise<
        IFormioSendEmailResponse | Error
      >> = [];
      errorInputs.forEach((input) => {
        emailPromises.push(
          sendFormEmailUser({
            email: input.value,
            firstName: '-',
            lastName: '-',
            timestamp: Date.now(),
          }),
        );
      });
      Promise.all(emailPromises).then((response) => {
        if (anyPromiseHasError(response)) {
          setErrorMessage(response[0] ? response[0].message : ERROR_TEXT);
        } else {
          close();
          setSuccessMessage({
            message: entryListL.addNewPeopleToTicketStep.shareDescription,
            title: entryListL.addNewPeopleToTicketStep.shareTitle,
          });
          Analytics.logEvent('select_item', {
            item_list_name:
              entryListL.addNewPeopleToTicketStep.shareDescription,
          });
        }
      });
    }
  };

  const closeErrorMessage = () => {
    setErrorMessage(null);
  };
  return (
    <>
      <DialogContent>
        <Snackbar
          open={!!errorMessage}
          autoHideDuration={6000}
          onClose={closeErrorMessage}
        >
          <Alert onClose={closeErrorMessage} severity="error">
            {errorMessage}
          </Alert>
        </Snackbar>
        <Grid container={true} spacing={3}>
          <Grid item={true} xs={12}>
            <Alert severity="info" variant="filled">
              <AlertTitle>
                {entryListL.entryTerm.emailModal.alertTitle}
              </AlertTitle>
              <Typography variant="body2" component="p">
                {entryListL.entryTerm.emailModal.alertDescription}
              </Typography>
            </Alert>
          </Grid>
          {emailInputs.map((input) => (
            <Grid item={true} key={input.id} xs={12}>
              <TextField
                variant="outlined"
                value={input.value}
                onChange={(e) => handleChange(e, input.id)}
                onBlur={(e) => handleBlur(e, input.id)}
                error={input.error}
                helperText={EMAIL_HELPER_TEXT}
                placeholder={EMAIL_TEXT}
                fullWidth={true}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => removeInput(input.id)}>
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          ))}
          <Grid item={true} xs={12}>
            <Button
              variant="outlined"
              size="medium"
              startIcon={<AddIcon />}
              onClick={addInput}
              fullWidth={true}
              color="primary"
            >
              {ADD_TEXT}
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={styles.actions}>
        <Grid container={true} spacing={6}>
          <Grid container={true} spacing={5} item={true}>
            <Grid item={true} xs={6}>
              <Button
                variant="outlined"
                fullWidth={true}
                size="large"
                color="primary"
                onClick={close}
              >
                {CANCEL_TEXT}
              </Button>
            </Grid>
            <Grid item={true} xs={6}>
              <Button
                variant="contained"
                fullWidth={true}
                size="large"
                color="primary"
                disabled={anyHasError()}
                onClick={submit}
              >
                {SEND_TEXT}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </DialogActions>
    </>
  );
};

export default EmailModal;
