import { combineReducers } from 'redux';

import companyDataReduce from './companyData';
import discountCodeReducer from './discountCode';
import formDataReducer from './formData';
import termsReducer from './terms';
import transactionReducer from './transaction';
import upSellReducer from './upSell';
import userReducer from './user';

const reducer = combineReducers({
  companyData: companyDataReduce,
  discountCode: discountCodeReducer,
  formData: formDataReducer,
  terms: termsReducer,
  transaction: transactionReducer,
  upSell: upSellReducer,
  user: userReducer,
});

export default reducer;
