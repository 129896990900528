import { setDiscount } from '@Model/reservation/actions';
import _Store from '@Store';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';
import Discount from './Discount.component';
import { IDiscountFromDispatch, IDiscountFromState } from './Discount.types';

const mapStateToProps = (state: _Store.IState): IDiscountFromState => ({
  errorMessage: '',
  isDiscountInvalid: false,
});

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>,
): IDiscountFromDispatch => ({
  checkDiscount: (discountCode: string) => dispatch(setDiscount(discountCode)),
});

export default connect<
  IDiscountFromState,
  IDiscountFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps,
)(Discount);
