// Private
export const _GET_REQUEST = 'Zagrywki/happening/_GET_REQUEST';
export const _GET_SUCCESS = 'Zagrywki/happening/_GET_SUCCESS';
export const _GET_FAILURE = 'Zagrywki/happening/_GET_FAILURE';
export const _RESET_STATE = 'Zagrywki/happening/_RESET_STATE';

// Public
export const HAPPENING_MOUNTED = 'Zagrywki/happening/HAPPENING_MOUNTED';
export const REDIRECT_TO_HAPPENING = 'Zagrywki/happening/REDIRECT_TO_HAPPENING';
export const HANDLE_HAPPENING = 'Zagrywki/happening/HANDLE_HAPPENING';
export const SCROLL_TO_SECOND_STEP = 'Zagrywki/happening/SCROLL_TO_SECOND_STEP';
export const SCROLL_TO = 'Zagrywki/happening/SCROLL_TO';
