import { getType } from 'typesafe-actions';
import {
  resetAll,
  resetSpaceAndSlot,
  setCapacityLeft,
  setDay,
  setDurationTimeAfterMidnight,
  setNumberOfPlayers,
  setPrice,
  setSelectedPrices,
  setSlot,
  setSpace,
} from './../actions';
import { IAction, IBookingValues } from './../types';

const DEFAULT_NUMBER_OF_PLAYERS = 0;

const initialState: IBookingValues = {
  capacityLeft: 0,
  day: null,
  durationTimeAfterMidnight: false,
  numberOfPlayers: DEFAULT_NUMBER_OF_PLAYERS,
  selectedPrice: null,
  selectedPrices: [],
  slot: null,
  space: -1,
};

const valuesReducer = (
  state: IBookingValues = initialState,
  action: IAction,
): IBookingValues => {
  switch (action.type) {
    case getType(setDay):
      return {
        ...state,
        day: action.payload,
        slot: null,
        space: -1,
      };

    case getType(setNumberOfPlayers):
      return {
        ...state,
        numberOfPlayers: action.payload,
        slot: null,
        space: -1,
      };

    case getType(setSlot):
      return {
        ...state,
        slot: action.payload,
      };

    case getType(setSpace):
      return {
        ...state,
        space: action.payload,
      };

    case getType(resetSpaceAndSlot):
      return {
        ...state,
        slot: null,
        space: -1,
      };

    case getType(resetAll):
      return {
        ...state,
        numberOfPlayers: DEFAULT_NUMBER_OF_PLAYERS,
        slot: null,
        space: -1,
      };

    case getType(setDurationTimeAfterMidnight):
      return {
        ...state,
        durationTimeAfterMidnight: true,
      };

    case getType(setPrice):
      return {
        ...state,
        selectedPrice: action.payload,
      };

    case getType(setSelectedPrices):
      return !!action.payload.numberOfPeople
        ? {
            ...state,
            selectedPrices: state.selectedPrices.some(
              (v) => v.priceType === action.payload.priceType,
            )
              ? state.selectedPrices.map((price) =>
                  price.priceType === action.payload.priceType
                    ? action.payload
                    : price,
                )
              : [...state.selectedPrices, action.payload],
            slot: null,
            space: -1,
          }
        : {
            ...state,
            selectedPrices: state.selectedPrices.filter(
              (price) => price.priceType !== action.payload.priceType,
            ),
            slot: null,
            space: -1,
          };

    case getType(setCapacityLeft):
      return {
        ...state,
        capacityLeft: action.payload,
      };

    default:
      return state;
  }
};

export default valuesReducer;
