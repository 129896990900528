import { getAvailabilities } from '@Model/booking/actions';
import _Store from '@Store';
import { filter as filter$, map as map$ } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { resetLoading, setLoading } from './../actions';
import { MODULE_AVAILABILITIES } from './../constants/constants';

export const whenAvailabilitiesRequestedSetLoading: _Store.IEpic = (
  action$,
) => {
  return action$.pipe(
    filter$(isActionOf(getAvailabilities.request)),
    map$(() => setLoading(MODULE_AVAILABILITIES)),
  );
};

export const whenAvailabilitiesFinishedResetLoading: _Store.IEpic = (
  action$,
) => {
  return action$.pipe(
    filter$((action: _Store.IAction) =>
      isActionOf(
        [getAvailabilities.success, getAvailabilities.failure],
        action,
      ),
    ),
    map$(() => resetLoading(MODULE_AVAILABILITIES)),
  );
};
