import React from 'react';

import styles from './Header.module.scss';

const TITLE_TEXT = 'Miesiąc skakania w najlepszej cenie!';
const DESCRIPTION_TEXT =
  'Kup jeden z naszych karnetów i skacz przez następne 30 dni w naszych parkach!';

const Header = () => {
  return (
    <div className={styles.headerWrapper}>
      <div className={styles.title}>{TITLE_TEXT}</div>
      <div className={styles.description}>{DESCRIPTION_TEXT}</div>
    </div>
  );
};

export default Header;
