import React, { Suspense } from 'react';

import HappeningRegister from '@Compo/HappeningRegister';
import Grid from '@Compo/layout/Grid';
import Loading from '@Compo/layout/Loading';
import PageWrapper from '@Compo/layout/PageWrapper';

const loading = <Loading />;

const NewTermsHangar = () => {
  return (
    <Grid>
      <PageWrapper>
        <Suspense fallback={loading}>
          <HappeningRegister />
        </Suspense>
      </PageWrapper>
    </Grid>
  );
};

export default NewTermsHangar;
