export enum IAvailableFunctions {
  LOADER_POSITION_TOP = 'LOADER_POSITION_TOP',
  PING = 'PING',
  REDIRECT = 'REDIRECT',
  RESIZE = 'RESIZE',
  SCROLL_TO_TOP = 'SCROLL_TO_TOP',
  PONG = 'PONG',
  SET_PARENT_URL = 'SET_PARENT_URL',
  SET_CURRENT_URL = 'SET_CURRENT_URL',
  SCROLL_IFRAME_TO = 'SCROLL_IFRAME_TO',
  SET_CLIENT = 'SET_CLIENT',
  REFRESH_PAGE = 'REFRESH_PAGE',
  FBQ = 'FBQ',
  SET_BASKET_DATA = 'SET_BASKET_DATA',
}

export interface IApiAction {
  type: keyof typeof IAvailableFunctions;
  payload?: string;
}

export interface IFrameAction {
  action: IApiAction;
  type: 'GOING';
}
