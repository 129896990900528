const app = {
  entryListUrl: process.env.REACT_APP_FORMIO_ENTRY_LIST_URL,
  formIoId: process.env.REACT_APP_FORMIO_ID,
  oldEntryListUrl: process.env.REACT_APP_FORMIO_OLD_ENTRY_LIST_URL,
  redirectAfterSaleUrl: process.env.REACT_APP_REDIRECT_AFTER_SALE_URL,
  showFirstStepEntryList: true,
  showLastStepEntryListCode: true,
  showPrePaidCard: false,
};

export default app;
