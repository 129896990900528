import { selectorFamily } from 'recoil';

import FormIoApi from '@Services/$formio-api';
import { formioData, iframeParams } from '../atoms';

const deleteSubmission = selectorFamily({
  get: (data: { submissionId: string }) => async ({ get }) => {
    try {
      const { transactionHash } = get(iframeParams);
      const { reservationCode } = get(formioData);

      return await FormIoApi.deleteSubmission(
        data.submissionId,
        reservationCode,
        transactionHash,
      );
    } catch {}
  },
  key: 'getSaveUserData',
});

export default deleteSubmission;
