import { combineReducers } from 'redux';
import availabilitiesReducer from './availabilities';
import valuesReducer from './values';

const reducer = combineReducers({
  availabilities: availabilitiesReducer,
  values: valuesReducer,
});

export default reducer;
