import { isMobile, isSmallMobile } from '@Model/app/selectors';
import { setSpace } from '@Model/booking/actions';
import {
  calculateAvailableSpaces,
  getSelectedPrices,
  getSelectedSpace,
} from '@Model/booking/selector';
import _Store from '@Store';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';
import Spaces from './Spaces.component';
import { ISpacesFromDispatch, ISpacesFromState } from './Spaces.types';

const mapStateToProps = (state: _Store.IState): ISpacesFromState => {
  return {
    isMobile: isMobile(state) || isSmallMobile(state),
    selectedPrices: getSelectedPrices(state),
    selectedSpace: getSelectedSpace(state),
    spaces: calculateAvailableSpaces(state),
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>,
): ISpacesFromDispatch => {
  return {
    selectSpace: (space: number) => dispatch(setSpace(space)),
  };
};

export default connect<
  ISpacesFromState,
  ISpacesFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps,
)(Spaces);
