export const staticCodesSaltos = [
  {
    name: 'Karta Dużej Rodziny',
    value: 'KDR',
  },
  {
    name: 'Karta Łodzianina',
    value: 'KŁ',
  },
  {
    name: 'MULTISPORT Tydzień',
    value: 'MULTISPORTtydzien',
  },
  {
    name: 'MULTISPORT dni wolne od szkoły',
    value: 'MULTISPORT_dni_wolne_od_szkoly',
  },
  {
    name: 'MEDICOVER Tydzień',
    value: 'MEDICOVERtydzien',
  },
  {
    name: 'MEDICOVER dni wolne od szkoły',
    value: 'Medicover_dni_wolne_od_szkoly',
  },
  {
    name: 'FitProfit Tydzień',
    value: 'FitProfitTydzien',
  },
  {
    name: 'FitProfit dni wolne od szkoły',
    value: 'FitProfit_dni_wolne_od_szkoly',
  },
];
