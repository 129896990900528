import React, { FC } from 'react';

import cn from 'classnames';

import config from '@Config';
import { IAppClassNameFromState } from './AppClassName.types';

const App: FC<IAppClassNameFromState> = ({
  children,
  isBigDesktop,
  isDesktop,
  isMobile,
  isSmallMobile,
  isTablet,
}) => {
  const { isZagrywki, isHangar, isSaltos } = config.theme;
  return (
    <div
      className={cn({
        bigDesktop: isBigDesktop,
        desktop: isDesktop,
        hangar: isHangar,
        mobile: isMobile,
        saltos: isSaltos,
        smallMobile: isSmallMobile,
        tablet: isTablet,
        zagrywki: isZagrywki,
      })}
    >
      {children}
    </div>
  );
};

export default App;
