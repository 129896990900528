import React, { useEffect } from 'react';

import SliderComponent from '@Compo/forms/Slider';
import Config from '@Config';
import { ISliderProps } from './Slider.types';

import styles from './Slider.module.scss';

const { isHangar, isSaltos } = Config.theme;

const GAME_TIME_TEXT = isHangar || isSaltos ? 'Czas wejścia:' : '';
const MIN_TEXT = 'min';

const Slider = ({
  activityDuration,
  maxPeopleCount,
  peopleCount,
  setPeopleCount,
  setPeopleCountPrice,
  step,
}: ISliderProps) => {
  useEffect(() => {
    setPeopleCount(step);
  }, []);

  const handleChange = (currentValue: number) => {
    setPeopleCount(currentValue);
    setPeopleCountPrice(currentValue, 'default');
  };

  const isSlider = isSaltos;

  return (
    <div className={styles.slider}>
      {isSlider && (
        <section className={styles.info}>
          <div className={styles.infoRow}>
            <span>{GAME_TIME_TEXT}</span>
            {!!(activityDuration && GAME_TIME_TEXT.length) && (
              <span className={styles.value}>
                {activityDuration} {MIN_TEXT}
              </span>
            )}
          </div>
        </section>
      )}

      <SliderComponent
        maxValue={maxPeopleCount}
        onChange={handleChange}
        step={step}
        value={peopleCount}
      />

      {!isSlider && (
        <section className={styles.info}>
          <div className={styles.infoRow}>
            <span>{GAME_TIME_TEXT}</span>{' '}
            {!!(activityDuration && GAME_TIME_TEXT.length) && (
              <span className={styles.value}>
                {' '}
                {activityDuration} {MIN_TEXT}
              </span>
            )}
          </div>
        </section>
      )}
    </div>
  );
};

export default Slider;
