// Private
export const _SEND_TRANSACTION_REQUEST =
  'Zagrywki/transaction/_SEND_TRANSACTION_REQUEST';
export const _SEND_TRANSACTION_SUCCESS =
  'Zagrywki/transaction/_SEND_TRANSACTION_SUCCESS';
export const _SEND_TRANSACTION_FAILURE =
  'Zagrywki/transaction/_SEND_TRANSACTION_FAILURE';

export const _CAPTURE_TRANSACTION_EMPIK_DETAILS_REQUEST =
  'Zagrywki/reservation/_CAPTURE_TRANSACTION_EMPIK_DETAILS_REQUEST';
export const _CAPTURE_TRANSACTION_EMPIK_DETAILS_SUCCESS =
  'Zagrywki/reservation/_CAPTURE_TRANSACTION_EMPIK_DETAILS_SUCCESS';
export const _CAPTURE_TRANSACTION_EMPIK_DETAILS_FAILURE =
  'Zagrywki/reservation/_CAPTURE_TRANSACTION_EMPIK_DETAILS_FAILURE';

// Public
export const SET_BOOKING_USER_DATA =
  'Zagrywki/transaction/SET_BOOKING_USER_DATA';
export const SET_BOOKING_TERMS = 'Zagrywki/transaction/SET_BOOKING_TERMS';
export const REDIRECT_AFTER_SALE = 'Zagrywki/transaction/REDIRECT_AFTER_SALE';
export const RESET_LOADER = 'Zagrywki/transaction/RESET_LOADER';
