import { getType } from 'typesafe-actions';

import { captureTransactionsEmpikDetailsRequest } from '@Model/transaction/actions';
import _Store from '@Store';
import { IReservationTransaction } from './../types';

const initialState: IReservationTransaction = {
  transactionItems: [],
  transactionProducts: [],
};

const transactionReducer = (
  state: IReservationTransaction = initialState,
  action: _Store.IAction,
): IReservationTransaction => {
  switch (action.type) {
    case getType(captureTransactionsEmpikDetailsRequest.success):
      return action.payload;

    default:
      return state;
  }
};

export default transactionReducer;
