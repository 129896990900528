import config from '@Config';
import catchHttpError from '@Misc/helpers/api/catchHttpError';
import getData from '@Misc/helpers/api/getData';
import axios, { CancelTokenSource } from 'axios';
import { IAvailabilitiesResponse, IAvailabilitiesResponseDay } from './types';

class AvailabilitiesApi {
  private static getUrl(happeningSlug: string, date: string): string {
    return `${config.api.baseUrlV2}happenings/${happeningSlug}/availability?date=${date}`;
  }

  private cancelTokenAvailabilities?: CancelTokenSource;

  public getAvailabilities<T extends IAvailabilitiesResponse>(
    happeningSlug: string,
    date: string,
  ): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      this.cancelTokenAvailabilities = axios.CancelToken.source();

      return axios
        .get(AvailabilitiesApi.getUrl(happeningSlug, date), {
          cancelToken: this.cancelTokenAvailabilities.token,
        })
        .then(getData)
        .then((data) => {
          resolve(data);
        })
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public normalizeAvailabilities(
    response: IAvailabilitiesResponse,
    date: string,
  ): IAvailabilitiesResponseDay {
    const { items } = response;

    return items[date];
  }

  public cancelAvailabilities() {
    if (this.cancelTokenAvailabilities) {
      this.cancelTokenAvailabilities.cancel();
      this.cancelTokenAvailabilities = undefined;
    }
  }
}

export default new AvailabilitiesApi();
