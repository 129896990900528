import React, { useEffect } from 'react';

import Loading from '@Compo/layout/Loading';
import PersonalData from './components/PersonalData';
import Summary from './components/Summary/Summary';
import { IExtendedPaymentProps } from './ExtendedPayment.types';

import styles from './ExtendedPayment.module.scss';

const RESERVATION_TEXT = 'Twoja rezerwacja';
const SUB_TITLE_TEXT =
  'Zanim klikniesz “Zapłać”, sprawdź czy wszystko się zgadza.';
const I_PAY_TEXT = 'Zapłać';

const ExtendedPayment = ({
  extendedPayment,
  isLoading,
  mounted,
  pay,
}: IExtendedPaymentProps) => {
  useEffect(() => {
    mounted();
  }, []);

  if (isLoading) {
    return <Loading />;
  }
  if (!extendedPayment) {
    return null;
  }

  return (
    <>
      <section className={styles.header}>
        <h1 className={styles.title}>{RESERVATION_TEXT}</h1>
        <h2 className={styles.subTitle}>{SUB_TITLE_TEXT}</h2>
      </section>

      <PersonalData />

      <Summary extendedPayment={extendedPayment} />

      <div className={styles.payButtonWrapper}>
        <button className={styles.payButton} onClick={pay}>
          {I_PAY_TEXT}
        </button>
      </div>
    </>
  );
};

export default ExtendedPayment;
