import React from 'react';

import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useRecoilState } from 'recoil';

import { error as errorState } from '@Recoil/errors/atoms';

const Toast = () => {
  const [error, setError] = useRecoilState(errorState);
  const handleClose = () => {
    setError({ message: null });
  };
  return (
    <Snackbar
      open={!!error.message}
      autoHideDuration={6000}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <Alert onClose={handleClose} severity="error" variant="filled">
        {error.message}
      </Alert>
    </Snackbar>
  );
};

export default Toast;
