import _Store from '@Store';
import { createSelector } from 'reselect';
import { _LOADING } from './../constants/constants';
import { ISetStateModule, IStateReducer, ISubStateKeys } from './../types';
import get from './get';

const getStateByKey = (property: ISubStateKeys) => (module: ISetStateModule) =>
  createSelector<_Store.IState, IStateReducer, boolean>(
    [get],
    (state) => {
      switch (property) {
        /*
         * This selector should be used only for boolean properties.
         */
        case _LOADING:
          return state[module][property];

        default:
          return false;
      }
    },
  );

export default getStateByKey;
