import { createAsyncAction, createStandardAction } from 'typesafe-actions';
import {
  _GET_FAILURE,
  _GET_REQUEST,
  _GET_SUCCESS,
  _RESET_STATE,
  HANDLE_HAPPENING,
  HAPPENING_MOUNTED,
  REDIRECT_TO_HAPPENING,
  SCROLL_TO,
  SCROLL_TO_SECOND_STEP,
} from './../constants/actions';
import { IHappening } from './../types';

export const getHappening = createAsyncAction(
  _GET_REQUEST,
  _GET_SUCCESS,
  _GET_FAILURE,
)<string, IHappening, Error>();

export const resetState = createStandardAction(_RESET_STATE)();
export const redirectToHappening = createStandardAction(REDIRECT_TO_HAPPENING)<
  string
>();
export const handleHappening = createStandardAction(HANDLE_HAPPENING)<string>();
export const scrollToSecondStep = createStandardAction(SCROLL_TO_SECOND_STEP)();
export const scrollTo = createStandardAction(SCROLL_TO)<string>();

export const happeningMounted = createStandardAction(HAPPENING_MOUNTED)();
