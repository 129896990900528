import React, { useEffect } from 'react';

import { ITicketsProps } from './Carnets.types';
import Carnet from './components/Carnet';
import Header from './components/Header';

import styles from './Carnets.module.scss';

const Carnets = ({ mounted, carnets, selectCarnet }: ITicketsProps) => {
  useEffect(() => {
    mounted();
  }, []);

  return (
    <>
      <Header />
      <div className={styles.carnetsList}>
        {carnets.map((carnet) => (
          <Carnet carnet={carnet} selectCarnet={selectCarnet} key={carnet.id} />
        ))}
      </div>
    </>
  );
};

export default Carnets;
