import React from 'react';

import { Typography } from '@material-ui/core';
import cn from 'classnames';

import config from '@Config';
import getShortTime from '@Misc/helpers/dateTime/getShortTime';
import _L from '@Misc/helpers/local';
import LOCALS from '@Vars/constants';
import { ITimeSlotsProps } from './TimeSlots.types';

import styles from './TimeSlots.module.scss';

const exclamationUrl = config.theme.isHangar
  ? '/static/exclamation_black.svg'
  : '/static/exclamation.svg';

const COME_ON_TIME_TEXT = _L(LOCALS.REMEMBERS_TEXT);
const EMPTY_SLOTS_TEXT = _L(LOCALS.EMPTY_SLOTS_TEXT);

const TimeSlots = ({ setSlot, timeSlots, isScopeView }: ITimeSlotsProps) => {
  if (!timeSlots.length) {
    return (
      <Typography align="center" variant="h5">
        {EMPTY_SLOTS_TEXT}
      </Typography>
    );
  }

  return (
    <div className={styles.wrapper}>
      <ul className={styles.timeSlots}>
        {timeSlots.map((slot) => {
          if (!slot.isDisabled) {
            return (
              <li key={slot.start}>
                <button
                  className={cn(
                    styles.button,
                    slot.isSelected && styles.selected,
                  )}
                  disabled={slot.isDisabled}
                  onClick={() => setSlot(slot.start)}
                >
                  {isScopeView
                    ? `${getShortTime(slot.start)} - ${slot.duration}`
                    : getShortTime(slot.start)}
                </button>
              </li>
            );
          } else {
            return null;
          }
        })}
      </ul>
      {timeSlots && timeSlots.length ? (
        <div className={styles.info}>
          <img className={styles.infoIcon} src={exclamationUrl} />
          {COME_ON_TIME_TEXT}
        </div>
      ) : null}
    </div>
  );
};

export default TimeSlots;
