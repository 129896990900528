import { createSelector } from 'reselect';

import { get } from '@Model/extendedPayment/selectors';

const getUpdatedExtendedTransaction = createSelector(
  [get],
  (extendedPayment) => {
    return extendedPayment.updatedTransactionDetails;
  },
);

export default getUpdatedExtendedTransaction;
