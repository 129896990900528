import { createStandardAction } from 'typesafe-actions';
import {
  ASYNC_REDIRECT,
  SET_DEVICE,
  SET_INNER_SIZES,
  SET_SIZES,
} from './../constants/actions';
import { IActionDevice, IActionSizes } from './../types';

export const setDevice = createStandardAction(SET_DEVICE)<IActionDevice>();

export const setSizes = createStandardAction(SET_SIZES)<IActionSizes>();

export const setInnerSizes = createStandardAction(SET_INNER_SIZES)<
  IActionSizes
>();

export const asyncRedirect = createStandardAction(ASYNC_REDIRECT)<string>();
