import {
  filter as filter$,
  map as map$,
  mergeMap as mergeMap$,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { getCompanyData } from '@Model/reservation/actions';
import _Store from '@Store';
import { resetError, resetLoading, setLoading } from '../actions';
import { MODULE_INVOICE } from '../constants/constants';

export const setUpStateWhenCompanyDataRequested: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf([getCompanyData.request])),
    mergeMap$(() => [resetError(MODULE_INVOICE), setLoading(MODULE_INVOICE)]),
  );
};

export const setLoaderWhenCompanyDataFinished: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$((action: _Store.IAction) =>
      isActionOf([getCompanyData.success, getCompanyData.failure], action),
    ),
    map$(() => resetLoading(MODULE_INVOICE)),
  );
};
