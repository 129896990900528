import React, { useEffect } from 'react';

import Loading from '@Compo/layout/Loading';
import { IframeProvider } from '@Services/$iframe-provider';
import styles from './Start.module.scss';
import { IStartProps } from './Start.types';

const Start = ({ mounted, captureIframeEvent }: IStartProps) => {
  useEffect(() => {
    window.addEventListener(
      'message',
      (messageEvent: MessageEvent) =>
        IframeProvider.addEventListenerMethod(messageEvent, captureIframeEvent),
      false,
    );

    mounted();
  }, []);

  return (
    <div className={styles.wrapper}>
      <Loading fixed={true} />
    </div>
  );
};

export default Start;
