import moment from 'moment';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { setDay } from '@Model/booking/actions';
import { getSelectedDay, getValues } from '@Model/booking/selector';
import { get as getHappening } from '@Model/happening/selectors';
import _Store from '@Store';
import Calendar from './Calendar.component';
import { ICalendarFromDispatch, ICalendarFromState } from './Calendar.types';

const mapStateToProps = (state: _Store.IState): ICalendarFromState => {
  const happening = getHappening(state);
  const { durationTimeAfterMidnight } = getValues(state);

  const endDate = happening && happening.endDate ? happening.endDate : null;

  const getStartDate = (): Date => {
    if (happening && happening.startDate) {
      const startDate = moment(happening.startDate);

      if (moment().diff(startDate) < 0) {
        return startDate.toDate();
      }
    }
    if (durationTimeAfterMidnight) {
      return moment()
        .subtract(1, 'days')
        .toDate();
    }

    return moment().toDate();
  };

  const happeningEndDate = endDate ? moment(endDate).toDate() : null;

  return {
    happeningEndDate,
    happeningStartDate: getStartDate(),
    isDateSelectionAvailable: true,
    selectedDate: getSelectedDay(state),
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>,
): ICalendarFromDispatch => {
  return {
    setDay: (day: Date) => dispatch(setDay(day)),
  };
};

export default connect<
  ICalendarFromState,
  ICalendarFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps,
)(Calendar);
