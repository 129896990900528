import { selectorFamily } from 'recoil';

import { formioData, iframeParams } from '@Recoil/formio/atoms';
import FormIoApi from '@Services/$formio-api';

const getSubmissionsAttach = selectorFamily({
  get: (request: { submissionId: string; id: string }) => async ({ get }) => {
    try {
      const { reservationCode } = get(formioData);
      const { transactionHash, formIoId } = get(iframeParams);

      if (reservationCode || transactionHash) {
        return await FormIoApi.saveSubmissionsAttach(
          request.submissionId,
          reservationCode,
          transactionHash,
          formIoId,
        );
      }
    } catch {}
  },
  key: 'getSubmissionsAttach',
});

export default getSubmissionsAttach;
