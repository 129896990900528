import { getType } from 'typesafe-actions';
import { clearDiscount, setDiscount } from './../actions';
import { IAction, IReservationDiscountCode } from './../types';

const initialState: IReservationDiscountCode = null;

const discountCodeReducer = (
  state: IReservationDiscountCode = initialState,
  action: IAction,
): IReservationDiscountCode => {
  switch (action.type) {
    case getType(setDiscount):
      return action.payload ? action.payload : null;
    case getType(clearDiscount):
      return '';

    default:
      return state;
  }
};

export default discountCodeReducer;
