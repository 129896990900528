const defaultTheme = () => ({
  formio: {
    button: '#eff200',
  },
  overrides: {
    MuiButton: {
      containedSizeLarge: {
        fontSize: 20,
        fontWeight: 700,
        lineHeight: 1,
        paddingBottom: 16,
        paddingLeft: 24,
        paddingRight: 24,
        paddingTop: 16,
      },
      outlinedSizeLarge: {
        color: '#111111',
        fontSize: 20,
        fontWeight: 700,
        lineHeight: 1,
        paddingBottom: 16,
        paddingLeft: 24,
        paddingRight: 24,
        paddingTop: 16,
      },
      root: {
        borderRadius: '0.5rem',
      },
      textPrimary: {
        color: '#111111',
        fontSize: 14,
      },
    },
    MuiCssBaseline: {
      '@global': {
        body: {
          backgroundColor: '#f4f7f8',
        },
        html: {
          fontSize: 16,
        },
      },
    },
    MuiFormHelperText: {
      root: {
        fontSize: 12,
        fontWeight: 400,
        letterSpacing: '0.4px',
        lineHeight: '20px',
      },
    },
    MuiInputBase: {
      root: {
        fontSize: 16,
        fontWeight: 400,
        letterSpacing: '0.15px',
        lineHeight: '19px',
      },
    },
    MuiSvgIcon: {
      fontSizeLarge: {
        fontSize: '3.5rem',
      },
      root: {
        fontSize: '2.4rem',
      },
    },
  },
  palette: {
    background: {
      default: '#f4f7f8',
    },
    info: {
      contrastText: '#ffffff',
      main: '#60748B',
    },
    other: {
      stroke: 'rgba(0, 0, 0, 0.23)',
    },
    primary: {
      contrastText: '#111111',
      darkBg: '#ede40c',
      light: '#ffff57',
      main: '#eff200',
    },
    secondary: {
      contrastText: '#ffffff',
      darkBg: '#34495e',
      light: '#8ea3bb',
      main: '#60748b',
    },
  },
  spacing: [0, 4, 8, 16, 24, 32, 48, 96, 120, 160],
  typography: {
    fontFamily: [
      '"Noir Pro"',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),

    body1: {
      fontWeight: 500,
    },
    body2: {
      fontSize: '1.125rem',
    },

    fontWeightBold: 500,

    h2: {
      fontWeight: 500,
    },
    h3: {
      fontSize: '1.75rem',
      fontWeight: 500,
    },
    h4: {
      fontWeight: 500,
    },
    h5: {
      fontWeight: 500,
    },
    h6: {
      fontWeight: 700,
    },
  },
});

export default defaultTheme;
