import React from 'react';

import cn from 'classnames';
import { ErrorMessage, Field } from 'formik';
import styles from './FormField.module.scss';
import { IFormFieldProps } from './FormField.types';

const FormField = ({ customError, id, isError, ...props }: IFormFieldProps) => {
  return (
    <>
      <Field
        className={cn(styles.input, isError && styles.errorInput)}
        {...props}
      />
      {customError && isError && (
        <span className={styles.error}>{customError}</span>
      )}
      {!customError && (
        <ErrorMessage
          className={styles.error}
          name={props.name}
          component="span"
        />
      )}
    </>
  );
};

export default FormField;
