import { atom } from 'recoil';

import { IIframeParams } from './../types';

const iframeParams = atom<IIframeParams>({
  default: {
    entryListUrl: null,
    formIoId: null,
    ticketCode: null,
    transactionHash: null,
  },
  key: 'iframeParams',
});

export default iframeParams;
