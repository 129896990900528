import { createTheme, Theme } from '@material-ui/core/styles';

import theme from './../theme';
import defaultTheme from './default';
import hangar from './hangar';
import saltos from './saltos';
import zagrywki from './zagrywki';

const themeSelector = (selectedTheme: string | null) => {
  if (selectedTheme) {
    switch (selectedTheme) {
      case 'hangar':
        return hangar();
      case 'zagrywki':
        return zagrywki();
      case 'saltos':
        return saltos();
      default:
        return defaultTheme();
    }
  } else {
    switch (theme.selectedTheme) {
      case 'hangar':
        return hangar();
      case 'zagrywki':
        return zagrywki();
      case 'saltos':
        return saltos();
      default:
        return defaultTheme();
    }
  }
};

const muiTheme = (selectedTheme: string | null): Theme =>
  createTheme({
    ...themeSelector(selectedTheme),
  });

const mui = (selectedTheme: string | null) => ({
  theme: {
    ...muiTheme(selectedTheme),
  },
});

export default mui;
