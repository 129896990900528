import { getType } from 'typesafe-actions';

import { getExtendedPayment, getUpdatedExtendedPayment } from '../actions';
import { IAction, IExtendedPaymentReducer } from './../types';

const initialState: IExtendedPaymentReducer = {
  transactionDetails: null,
  updatedTransactionDetails: null,
};

const happeningReducer = (
  state: IExtendedPaymentReducer = initialState,
  action: IAction,
): IExtendedPaymentReducer => {
  switch (action.type) {
    case getType(getExtendedPayment.success):
      return {
        ...state,
        transactionDetails: action.payload,
      };

    case getType(getUpdatedExtendedPayment.success):
      return {
        ...state,
        updatedTransactionDetails: action.payload,
      };
    default:
      return state;
  }
};

export default happeningReducer;
