import React from 'react';

import styles from './Carnet.module.scss';
import { ICarnetProps } from './Carnet.types';

const PRICE_TEXT = 'Cena: ';
const BUY_TEXT = 'Kup karnet';

const Carnet = ({
  carnet,
  carnet: { subDescriptions, name, description },
  selectCarnet,
}: ICarnetProps) => {
  const formattedPrice = `${carnet.price} PLN`;

  const catchSelectCarnet = () => {
    selectCarnet(carnet);
  };

  return (
    <div className={styles.carnet}>
      <div className={styles.title}>{name}</div>
      <div className={styles.description}>{description}</div>

      {subDescriptions && (
        <div className={styles.subDescriptionsWrapper}>
          {subDescriptions.map((subDescription) => (
            <div key={subDescription} className={styles.subDescriptions}>
              {subDescription}
            </div>
          ))}
        </div>
      )}

      <div className={styles.priceWrapper}>
        <div className={styles.priceTitle}>{PRICE_TEXT}</div>
        <div className={styles.priceValue}>{formattedPrice}</div>
      </div>
      <div className={styles.selectButton} onClick={catchSelectCarnet}>
        {BUY_TEXT}
      </div>
    </div>
  );
};

export default Carnet;
