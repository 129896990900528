import React, { useEffect } from 'react';

import Loading from '@Compo/layout/Loading';
import ItemsView from './components/ItemsView';
import ProductsView from './components/ProductsView';
import { ISummaryProps } from './Summary.types';

import styles from './Summary.module.scss';

const BOOKING_SUCCESSFUL_TEXT =
  'Dziękujemy za rezerwację. Oczekujemy na potwierdzenie przyjęcia pieniędzy. Niedługo dostaniesz od nas maila z potwierdzeniem rezerwacji.';
const BOOKING_CARNET_SUCCESSFUL_TEXT =
  'Dziękujemy za zakup karnetu. Oczekujemy na potwierdzenie przyjęcia pieniędzy. Niedługo dostaniesz od nas maila z potwierdzeniem karnetu.';
const HAPPENING_DOESNT_EXIST_TEXT = 'Błędne wydarzenie';
const CONTINUE_TEXT = 'Kontynuuj zakupy';
const TRY_AGAIN_TEXT = 'Spróbuj ponownie';

const Summary = ({
  bookingIdentifier,
  isLoading,
  mounted,
  redirectToTryAgain,
  transactionProducts,
  transactionItems,
}: ISummaryProps) => {
  useEffect(() => {
    mounted();
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  if (!bookingIdentifier && !transactionProducts) {
    return (
      <section className={styles.header}>
        <h1 className={styles.title}>{HAPPENING_DOESNT_EXIST_TEXT} </h1>

        <div onClick={redirectToTryAgain}>
          <div className={styles.button}>{TRY_AGAIN_TEXT}</div>
        </div>
      </section>
    );
  }

  const isCarnet =
    !bookingIdentifier &&
    transactionProducts &&
    transactionProducts.length &&
    !transactionItems.length;
  return (
    <>
      <section className={styles.header}>
        <h1 className={styles.title}>
          {isCarnet ? BOOKING_CARNET_SUCCESSFUL_TEXT : BOOKING_SUCCESSFUL_TEXT}{' '}
        </h1>
      </section>

      <ProductsView />
      <ItemsView />
      <section className={styles.info}>
        <div onClick={redirectToTryAgain}>
          <div className={styles.button}>{CONTINUE_TEXT}</div>
        </div>
      </section>
    </>
  );
};

export default Summary;
