const pl = {
  entryListL: {
    addBuyerStep: {
      title: 'Czy będziesz jednym z uczestników?',
    },
    addNewPeopleToTicketStep: {
      addNewPerson: 'Dodaj kolejną osobę',
      checkData: 'Sprawdź, czy dane się zgadzają',
      confirm: 'Potwierdzam',
      description:
        'Dodaj brakujące osoby lub udostępnij formularz uczestnikom. Skróci to formalności na miejscu.',
      descriptionWithoutTicketCode:
        'Dodaj brakujące osoby. Skróci to formalności na miejscu.',
      shareDescription:
        'Zaproszenie do uzupełniania danych i akceptacji regulaminu zostało wysłane.',
      shareTitle: 'Poszło!',
      thanks: 'Dziękujemy!',
      thanksDescWhenPersonIsInDatabase:
        'Osoba poprawnie dopisana do biletu. Ponieważ była już u nas wcześniej nie ma potrzeby ponownej rejestracji.',
      thanksDescription:
        'Osoba została poprawnie dodana, a formularz rejestracyjny wysłany.',

      title: 'Aby dokończyć proces potrzeba jeszcze',
    },
    addPeopleToTicketStep: {
      description: 'Zaznacz osoby, które mają zostać dopisane do biletu',
      noData: 'Brak powiązanych wpisów',
      title: 'Czy idzie z Tobą któraś z osób, z którymi byłeś u nas wcześniej?',
    },
    back: 'Wstecz',
    entryTerm: {
      confirm: 'Potwierdź',
      emailModal: {
        alertDescription:
          'Podaj adresy e-mail osób, które będą uczestniczyć w zabawie i udostępnij im formularz oraz regulamin do akceptacji. Wszyscy uczestnicy muszą to zrobić przed wejściem, a robiąc to online nie tracicie czasu na formalności na miejscu.',
        alertTitle: 'Udostępnij formularz i regulamin uczestnikom.',
      },
      error: 'Coś poszło nie tak! Spróbuj ponownie.',
      retry: 'Spróbuj ponownie',
      saveAndCompleteLater: 'Zapisz i dokończ później',
      sendEmail: 'Udostępnij',
    },
    haveTicketCodeStep: {
      haveTicketCode: 'Czy posiadasz kod biletu?',
      title: 'Uzupełnij dodatkowe dane',
    },
    next: 'Dalej',
    no: 'Nie',
    numberOfPeopleStep: {
      error: 'Liczba osób musi być większa od 0.',
      inputHelperText:
        'Wpisz ile osób wybiera się na wydarzenie w ramach zakupionego biletu',
      inputText: 'Liczba osób na bilecie',
      numberError: 'Podana wartość musi być liczbą.',
    },
    registerNewPerson: {
      title: 'Rejestracja',
    },
    thanksStep: {
      back: 'Zakończ',
      code:
        'Dziękujemy za wypełnienie formularza! Zapamiętaj poniższy kod i podaj kasjerowi po dotarciu na miejsce.',
      saved: 'Zapisano!',
      savedDescription: 'Dane z formularza zostały zapisane.',
      thanks: 'Gotowe!',
    },
    ticketStep: {
      enterTicketCode: 'Wpisz kod biletu i dodaj do niego osoby',
      ticketCode: 'Kod biletu',
      ticketCodeHelper: 'Wpisz kod biletu otrzymany mailowo',
    },
    title: 'Lista wejściowa',
    yes: 'Tak',
  },
};

export type Translation = typeof pl;

export default pl;
