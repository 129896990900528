import { createAction } from 'typesafe-actions';
import {
  RESET_ERROR,
  RESET_LOADING,
  SET_ERROR,
  SET_LOADING,
} from './../constants/actions';
import { IPayloadMetaAction, ISetStateModule } from './../types';

function createHandler<T extends string>(
  actionCallback: <P = undefined, M = undefined>(
    payload?: P,
    meta?: M,
  ) => IPayloadMetaAction<T, P, M>,
) {
  return (module: ISetStateModule) =>
    actionCallback({
      module,
    });
}

export const resetLoading = createAction(RESET_LOADING, createHandler);

export const setLoading = createAction(SET_LOADING, createHandler);

export const setError = createAction(SET_ERROR, (actionCallback) => {
  return (module: ISetStateModule, message: string) =>
    actionCallback({
      message,
      module,
    });
});

export const resetError = createAction(RESET_ERROR, createHandler);
