import React from 'react';

import ExternalForm from '@Compo/ExternalForm';
import Grid from '@Compo/layout/Grid';
import PageWrapper from '@Compo/layout/PageWrapper';

const ExternalFormPage = () => (
  <Grid>
    <PageWrapper>
      <ExternalForm />
    </PageWrapper>
  </Grid>
);

export default ExternalFormPage;
