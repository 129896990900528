import React, { useContext, useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import cn from 'classnames';

import routes from '@/routes/routes';
import Calendar from '@Compo/Happening/components/Calendar';
import OverbookingTimeSlots from '@Compo/Happening/components/OverbookingTimeSlots';
import Slider from '@Compo/Happening/components/Slider';
import Spaces from '@Compo/Happening/components/Spaces';
import SpaceCheckBox from '@Compo/Happening/components/SpacesCheckBox';
import TicketTypePicker from '@Compo/Happening/components/TicketsTypePicker';
import TimeSlots from '@Compo/Happening/components/TimeSlots';
import { IHappeningProps } from '@Compo/Happening/Happening.types';
import Loading from '@Compo/layout/Loading';
import Config from '@Config';
import { priceFormatter } from '@Misc/helpers';
import getMetadataForLanguage from '@Misc/helpers/getMetadataForLanguage';
import _L from '@Misc/helpers/local';
import { IHappeningsItemMetadata } from '@Model/happenings/types';
import LOCALS from '@Vars/constants';
import { clearContext } from '../Basket/ZagrywkiBasketProvider/ZagrywkiBasketProvider';
import HappeningCheckBox from '../Happenings/components/HappeningCheckBox';
import HappeningCheckBoxV2 from '../Happenings/components/HappeningCheckBoxV2';
import SummaryBox from '../SummaryBox/SummaryBox.container';

import styles from './Happening.module.scss';

const BOOK_TEXT = 'Rezerwuj';
const PRICE_TEXT = 'Cena:';
const SELECT_DATE_AND_TIME_TEXT = 'Wybierz datę i godzinę';

const SELECT_PLAYERS_COUNT_TEXT = _L(LOCALS.SELECT_PLAYERS_TEXT);
const SELECT_PLACE_TEXT = _L(LOCALS.SELECT_PLACE_TEXT);
const CONTINUE_BUYING_TEXT = 'Dodaj do koszyka';
const COME_ON_TIME_TEXT = _L(LOCALS.REMEMBERS_TEXT);
const SAME_ITEM_IN_BASKET_ERROR_TEXT =
  'Aktywność znajduje się już w twoim koszyku!';

const useStyles = makeStyles(() => ({
  alert: {
    alignItems: 'center',
    fontSize: 14,
  },
}));

const Happening = ({
  addToGlobalBasket,
  happening,
  isBookingAvailable,
  isLoading,
  isLoadingAvailabilities,
  mounted,
  price,
  showBasket,
  resetSpaceAndSlot,
  selectedSpace,
  getHappenings,
  currentBasketData,
  isSmallScreen,
  addBasketRedirect,
  redirectParentTo,
  history,
  selectedPrices,
}: IHappeningProps) => {
  const BagState = useContext(clearContext);
  const [open, setOpen] = useState(false);
  const [isRedirectLoading, setRedirectLoading] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    mounted();
    getHappenings();
  }, []);

  useEffect(() => {
    if (price === null) {
      resetSpaceAndSlot();
    }
  }, [price]);

  if (isLoading || isRedirectLoading) {
    return <Loading />;
  }

  if (!happening) {
    return null;
  }

  const metadata = getMetadataForLanguage<IHappeningsItemMetadata>(
    happening.metadata,
  );

  const showPlayersCount = happening.calculatePricePerPerson;
  const { isHangar, isSaltos, isZagrywki } = Config.theme;
  const disablePay =
    !isBookingAvailable ||
    !selectedSpace ||
    selectedSpace === -1 ||
    (isHangar && selectedPrices.length === 0 && showPlayersCount);

  const INTRO_RESERVATION_TEXT = isHangar || isSaltos ? '' : metadata.title;

  const handleContinueBuying = () => {
    if (BagState && currentBasketData) {
      if (
        BagState.basketItems.some((item) => item.id === currentBasketData.id)
      ) {
        setOpen(true);
        setRedirectLoading(false);
      } else {
        BagState.addToBag(currentBasketData);

        if (BagState.basketItems) {
          addToGlobalBasket([...BagState.basketItems, currentBasketData]);
        }
        if (!addBasketRedirect) {
          history.push(routes.index);
        }
      }
    }
  };

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const continueWithRedirection = () => {
    setRedirectLoading(true);
    handleContinueBuying();
    if (addBasketRedirect) {
      redirectParentTo(addBasketRedirect);
    }
  };

  const handleReservation = () => {
    if (BagState && currentBasketData) {
      if (
        BagState.basketItems.some((item) => item.id === currentBasketData.id)
      ) {
        setOpen(true);
      } else {
        history.push(`/rezerwacja/${metadata.slug}`);
      }
    }
  };

  return (
    <>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          className={classes.alert}
          onClose={handleClose}
          severity="error"
          variant="filled"
        >
          {SAME_ITEM_IN_BASKET_ERROR_TEXT}
        </Alert>
      </Snackbar>
      <section className={styles.header}>
        <h1 className={styles.title}>{INTRO_RESERVATION_TEXT}</h1>

        {/* TODO: keep it */}
        {/* {!showPlayersCount && (
          <span className={styles.recommendation}>
            <img className={styles.userIcon} src="/static/user.svg" alt="" />
            {RECOMMENDED_PLAYERS_COUNT} {recommendedNumberOfPlayers}
          </span>
        )} */}
      </section>

      {isHangar && <HappeningCheckBox />}
      {isSaltos && <HappeningCheckBoxV2 />}

      <section className={styles.content}>
        {showPlayersCount && (
          <div className={styles.box}>
            <h4 id="playerCount" className={styles.boxTitle}>
              {SELECT_PLAYERS_COUNT_TEXT}
            </h4>
            {isZagrywki && <Slider />}
            {(isHangar || isSaltos) && <TicketTypePicker />}
          </div>
        )}

        {isSaltos && <div className={styles.line} />}
        <div className={styles.box}>
          <h4 className={styles.boxTitle}>{SELECT_DATE_AND_TIME_TEXT}</h4>

          {isSaltos && !isSmallScreen && (
            <div className={styles.info}>
              {/* TODO: refactor to import */}
              <img
                className={styles.infoIcon}
                src={'/static/exclamation_clock.svg'}
              />
              {COME_ON_TIME_TEXT}
            </div>
          )}

          <div className={styles.flexContainer}>
            <Calendar />

            {isLoadingAvailabilities && <Loading />}
            {!showPlayersCount && !isLoadingAvailabilities && <TimeSlots />}
            {showPlayersCount && !isLoadingAvailabilities && (
              <OverbookingTimeSlots />
            )}
          </div>
        </div>

        <div className={styles.box}>
          {(isZagrywki || isHangar) && (
            <h4 id="place" className={styles.boxTitle}>
              {SELECT_PLACE_TEXT}
            </h4>
          )}

          {isZagrywki && <Spaces />}
          {isHangar && <SpaceCheckBox />}
          {isSaltos && <SpaceCheckBox hide={true} />}
        </div>

        <SummaryBox cannotRemoveCurrentItem={true} />

        <div className={cn(styles.box, styles.summary)}>
          <a
            className={cn(styles.reserveButton, disablePay && styles.disabled)}
            id="place"
            onClick={handleReservation}
          >
            {BOOK_TEXT}
          </a>

          {!!(selectedSpace && selectedSpace !== -1) && showBasket && (
            <>
              {!addBasketRedirect ? (
                <a
                  className={styles.basketButton}
                  onClick={handleContinueBuying}
                >
                  {CONTINUE_BUYING_TEXT}
                </a>
              ) : (
                <a
                  className={styles.basketButton}
                  onClick={continueWithRedirection}
                >
                  {CONTINUE_BUYING_TEXT}
                </a>
              )}
            </>
          )}
        </div>
      </section>
    </>
  );
};

export default Happening;
