import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { getConfigurationForSelectedSpace } from '@Model/booking/selector';
import calculateUpSellProps from '@Model/booking/selector/calculateUpSellProps';
import { getProducts } from '@Model/products/selectors/index';
import _Store from '@Store';
import Products from './Products.component';
import { IProductsFromState } from './Products.types';

const mapStateToProps = (state: _Store.IState): IProductsFromState => {
  const values = calculateUpSellProps(state);
  const configuration = getConfigurationForSelectedSpace(state);
  const { items } = getProducts(state);

  return {
    ...values,
    haveUpSell: !!(configuration && configuration.upsell),
    products: items,
  };
};

export default connect<IProductsFromState, {}, {}, _Store.IState>(
  mapStateToProps,
)(Products);
