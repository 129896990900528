import { atom } from 'recoil';

import { IFormIoUser } from '../types';

const peopleState = atom<IFormIoUser[]>({
  default: [],
  key: 'peopleState',
});

export default peopleState;
