import { Formik } from 'formik';
import React, { useContext, useEffect } from 'react';

import { makeValidationSchema } from '@Compo/BookForm/BookForm.selectors';
import { IBookFormProps } from '@Compo/BookForm/BookForm.types';
import Loading from '@Compo/layout/Loading';
import { IReservationUser } from '@Model/reservation/types';
import { clearContext } from '../Basket/ZagrywkiBasketProvider/ZagrywkiBasketProvider';
import FormLayout from './components/FormLayout';

const BookForm = ({
  initialValues,
  mounted,
  saveData,
  isLoading,
  embedBasket,
  getTermsByHappeningAndSpace,
}: IBookFormProps) => {
  const state = useContext(clearContext);
  const basketJsonString = JSON.stringify(state ? state.basketItems : []);

  useEffect(() => {
    if (state) {
      if (state && state.basketItems && state.basketItems.length) {
        state.basketItems.forEach((basketItem) => {
          getTermsByHappeningAndSpace({
            happeningSlug: basketItem.happeningSlug,
            spaceSlug: basketItem.spaceSlug,
          });
        });
      }
    }
  }, [basketJsonString]);

  useEffect(() => {
    if (embedBasket && state) {
      state.readLocalStorage();
    } else {
      mounted();
    }
  }, []);

  const catchSaveData = (data: IReservationUser) => {
    saveData({
      basketItems: state && state.basketItems ? state.basketItems : [],
      data,
      idempotencyKey: state && state.idempotencyKey ? state.idempotencyKey : '',
      onDone: state && state.clearBasket ? state.clearBasket : () => null,
    });
  };

  const validationSchema = makeValidationSchema();

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Formik
      enableReinitialize={true}
      onSubmit={catchSaveData}
      initialValues={initialValues}
      validationSchema={validationSchema}
      component={FormLayout}
    />
  );
};

export default BookForm;
