import { TABLET } from '@Model/app/constants/constants';
import { createSelector } from 'reselect';
import getDevice from './getDevice';

const isTablet = createSelector(
  [getDevice],
  (device) => device === TABLET,
);

export default isTablet;
