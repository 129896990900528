import { atom } from 'recoil';

import en from '@/lang/en';
import { ITranslation } from './../types';

const mapEN = atom<ITranslation>({
  default: en,
  key: 'mapEN',
});

export default mapEN;
