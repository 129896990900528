import { EMPTY as EMPTY$, from as from$ } from 'rxjs';
import { filter as filter$, mergeMap as mergeMap$ } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import _Store from '@Store';
import {
  addVisitorData,
  newUserRegistration,
  registrationSuccess,
  registrationVisitorConfirmed,
  registrationWithoutCode,
  registrationWithTicketCode,
} from '../actions';

export const addDataToGTMWhenUserRegisterWithoutCode: _Store.IEpic = (
  action$,
  state$,
  { analyticsApi },
) => {
  return action$.pipe(
    filter$(isActionOf(registrationWithoutCode)),
    mergeMap$(() => {
      analyticsApi.pushBuyWithoutCode();
      return EMPTY$;
    }),
  );
};

export const addDataToGTMWhenUserRegisterWithTicketCode: _Store.IEpic = (
  action$,
  state$,
  { analyticsApi },
) => {
  return action$.pipe(
    filter$(isActionOf(registrationWithTicketCode)),
    mergeMap$(() => {
      analyticsApi.pushBuyWithTicketCode();
      return EMPTY$;
    }),
  );
};

export const addDataToGTMWhenNewUserRegistered: _Store.IEpic = (
  action$,
  state$,
  { analyticsApi },
) => {
  return action$.pipe(
    filter$(isActionOf(newUserRegistration)),
    mergeMap$((action) => {
      analyticsApi.pushNewUserRegistration(action.payload);
      return EMPTY$;
    }),
  );
};

export const addDataToGTMWhenVisitorDataAdd: _Store.IEpic = (
  action$,
  state$,
  { analyticsApi },
) => {
  return action$.pipe(
    filter$(isActionOf(addVisitorData)),
    mergeMap$((action) => {
      analyticsApi.pushAddVisitorData(action.payload);
      return EMPTY$;
    }),
  );
};

export const addDataToGTMWhenRegistratonVisitorConfirmed: _Store.IEpic = (
  action$,
  state$,
  { analyticsApi },
) => {
  return action$.pipe(
    filter$(isActionOf(registrationVisitorConfirmed)),
    mergeMap$((action) => {
      analyticsApi.pushRegistrationVisitorConfirmed(action.payload);
      return EMPTY$;
    }),
  );
};
export const addDataToGTMWhenRegistratinSuccess: _Store.IEpic = (
  action$,
  state$,
  { analyticsApi },
) => {
  return action$.pipe(
    filter$(isActionOf(registrationSuccess)),
    mergeMap$((action) => {
      analyticsApi.pushRegistrationSuccess(action.payload);
      return EMPTY$;
    }),
  );
};
