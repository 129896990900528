import { get } from '@Model/happening/selectors';
import { createSelector } from 'reselect';

const DEFAULT_NUMBER_OF_PLAYERS = 10;

const getRecommendedNumberOfPlayers = createSelector(
  [get],
  (happening) => {
    if (happening) {
      const numberOfPlayers = happening.spaces.map(
        (space) => space.maxNumberOfPeople,
      );

      const min = Math.min(...numberOfPlayers);
      const max = Math.max(...numberOfPlayers);

      if (min === max) {
        return min;
      }

      return `${min} - ${max}`;
    }

    return DEFAULT_NUMBER_OF_PLAYERS;
  },
);

export default getRecommendedNumberOfPlayers;
