import React from 'react';

import Checkbox from '@Compo/forms/Checkbox';
import CheckboxRound from '@Compo/forms/CheckboxRound';
import Config from '@Config';
import { ITermsProps } from './Terms.types';

import styles from './Terms.module.scss';

const READ_TEXT = '[przeczytaj]';
const TERMS_TEXT = 'Zgody';

const Terms = ({ setTerms, additionalTerms }: ITermsProps) => {
  const { isSaltos } = Config.theme;
  return (
    <div className={styles.terms}>
      <h4 className={styles.title}>{TERMS_TEXT}</h4>

      {!isSaltos &&
        additionalTerms.map((term) => (
          <Checkbox
            checked={term.selected || false}
            // tslint:disable-next-line: jsx-no-lambda
            onChange={() => setTerms(term.id)}
            key={term.id}
          >
            <span className={styles.checkBox}>
              {term.name}{' '}
              {term.link && (
                <a href={term.link} target="_blank">
                  {READ_TEXT}
                </a>
              )}
              {term.text && (
                <div className={styles.newsletterDesc}>{term.text}</div>
              )}
            </span>
          </Checkbox>
        ))}

      {isSaltos &&
        additionalTerms.map((term) => (
          <CheckboxRound
            checked={term.selected || false}
            // tslint:disable-next-line: jsx-no-lambda
            onChange={() => setTerms(term.id)}
            key={term.id}
          >
            <span className={styles.checkBox}>
              {term.name}{' '}
              {term.link && (
                <a href={term.link} target="_blank">
                  {READ_TEXT}
                </a>
              )}
              {term.text && (
                <div className={styles.newsletterDesc}>{term.text}</div>
              )}
            </span>
          </CheckboxRound>
        ))}
    </div>
  );
};

export default Terms;
