import {
  isBigDesktop,
  isDesktop,
  isMobile,
  isSmallMobile,
  isTablet,
} from '@Model/app/selectors';
import _Store from '@Store';
import { connect } from 'react-redux';
import AppClassName from './AppClassName.component';
import { IAppClassNameFromState } from './AppClassName.types';

const mapStateToProps = (state: _Store.IState): IAppClassNameFromState => ({
  isBigDesktop: isBigDesktop(state),
  isDesktop: isDesktop(state),
  isMobile: isMobile(state),
  isSmallMobile: isSmallMobile(state),
  isTablet: isTablet(state),
});

export default connect<IAppClassNameFromState, void, {}, _Store.IState>(
  mapStateToProps,
)(AppClassName);
