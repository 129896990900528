import { Variant } from '@material-ui/core/styles/createTypography';

export interface ITypographyGoingJPProps {
  variant?: TypographyGoingJPVariant;
  typographyVariant?: Variant;
}

export enum TypographyGoingJPVariant {
  grayedOut,
  yellowBgDarkTheme,
  yellowBg,
  yellowUnderline,
}
