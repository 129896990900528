import { createSelector } from 'reselect';

import { get } from '@Model/extendedPayment/selectors';

const getExtendedTransaction = createSelector(
  [get],
  (extendedPayment) => {
    return extendedPayment.transactionDetails;
  },
);

export default getExtendedTransaction;
