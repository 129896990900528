import React from 'react';

import { Link } from 'react-router-dom';

import getMetadataForLanguage from '@Misc/helpers/getMetadataForLanguage';
import {
  IHappeningsItem,
  IHappeningsItemMetadata,
} from '@Model/happenings/types';
import { IHappeningBoxProps } from './HappeningBox.types';

import styles from './HappeningBox.module.scss';

const HappeningBox = ({ happenings }: IHappeningBoxProps) => {
  const getMetadata = (happening: IHappeningsItem) =>
    getMetadataForLanguage<IHappeningsItemMetadata>(happening.metadata);

  return (
    <section className={styles.content}>
      <ul className={styles.happenings}>
        {happenings.map((happening) => (
          <li className={styles.happening} key={happening.id}>
            <Link
              className={styles.link}
              to={`/wydarzenie/${getMetadata(happening).slug}`}
            >
              {getMetadata(happening).title}
            </Link>
          </li>
        ))}
      </ul>
    </section>
  );
};

export default HappeningBox;
