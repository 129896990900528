import { LOCATION_CHANGE, push } from 'connected-react-router';
import { EMPTY as EMPTY$, from as from$, of as of$ } from 'rxjs';
import {
  catchError as catchError$,
  filter as filter$,
  map as map$,
  mergeMap as mergeMap$,
  takeUntil as takeUntil$,
  tap as tap$,
  withLatestFrom as withLatestFrom$,
} from 'rxjs/operators';
import { isActionOf, isOfType } from 'typesafe-actions';

import { getValues } from '@Model/booking/selector';
import { getSelectedSpaceProducts } from '@Model/happening/selectors';
import { runFbqAction } from '@Model/iframe/actions';
import { checkPrice } from '@Model/price/actions';
import { reservationMounted, setUpSell } from '@Model/reservation/actions';
import _Store from '@Store';
import {
  getCarnets,
  getProducts,
  selectProduct,
  setProductCount,
  unSelectProduct,
  updateList,
} from '../actions';
import { getProducts as getProductsSelector } from '../selectors';
import { IGetProductsPayloadSuccess } from '../types';
import { happeningMounted } from './../../happening/actions/index';
import { carnetsMounted, selectCarnet } from './../actions/index';

export const whenSummaryMountedGetProducts: _Store.IEpic = (
  action$,
  state$,
) => {
  return action$.pipe(
    filter$(isActionOf([reservationMounted])),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const values = getValues(state);

      if (!(values && values.space && values.day && values.space)) {
        return EMPTY$;
      }

      return of$(
        runFbqAction({
          action: 'track',
          payload: 'AddToCard',
        }),
        getProducts.success(getSelectedSpaceProducts(state)),
      );
    }),
  );
};

export const getProductsWhenRequest: _Store.IEpic = (
  action$,
  state$,
  { productsApi },
) => {
  return action$.pipe(
    filter$(isActionOf(getProducts.request)),
    mergeMap$((action) => {
      return from$(productsApi.getProducts(action.payload)).pipe(
        map$((data: IGetProductsPayloadSuccess) => {
          return getProducts.success(data.items);
        }),
        takeUntil$(
          action$.pipe(
            filter$(isOfType(LOCATION_CHANGE)),
            tap$(() => productsApi.cancelProducts()),
          ),
        ),
        catchError$((error: Error) => of$(getProducts.failure(error))),
      );
    }),
  );
};

export const selectProductWhenRequest: _Store.IEpic = (action$, state$) => {
  return action$.pipe(
    filter$(isActionOf(selectProduct)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const { items } = getProductsSelector(state);

      return of$(
        updateList(
          items.map((item) => {
            if (item.id === action.payload.id) {
              return {
                ...item,
                selected: true,
              };
            } else {
              return item;
            }
          }),
        ),
        checkPrice.request('product'),
      );
    }),
  );
};

export const setProductCountWhenRequest: _Store.IEpic = (action$, state$) => {
  return action$.pipe(
    filter$(isActionOf(setProductCount)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const { items } = getProductsSelector(state);
      const {
        product: { id },
        count,
      } = action.payload;

      return of$(
        updateList(
          items.map((item) => {
            if (item.id === id) {
              if (count) {
                return {
                  ...item,
                  count,
                  selected: true,
                };
              }
              return {
                ...item,
                count: 0,
                selected: false,
              };
            }
            return item;
          }),
        ),
        checkPrice.request('product'),
      );
    }),
  );
};

export const unSelectProductWhenRequest: _Store.IEpic = (action$, state$) => {
  return action$.pipe(
    filter$(isActionOf(unSelectProduct)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const { items } = getProductsSelector(state);

      return of$(
        updateList(
          items.map((item) => {
            if (item.id === action.payload.id) {
              return {
                ...item,
                count: 0,
                selected: false,
              };
            } else {
              return item;
            }
          }),
        ),
        checkPrice.request('product'),
      );
    }),
  );
};

export const unSelectAllProductsWhenRequest: _Store.IEpic = (
  action$,
  state$,
) => {
  return action$.pipe(
    filter$(isActionOf(happeningMounted)),
    withLatestFrom$(state$),
    mergeMap$(() => {
      return of$(updateList([]), setUpSell(false), checkPrice.request(''));
    }),
  );
};

export const getProductsWhenCarnetsMounted: _Store.IEpic = (
  action$,
  state$,
  { productsApi },
) => {
  return action$.pipe(
    filter$(isActionOf(carnetsMounted)),
    mergeMap$(() => {
      return from$(productsApi.getCarnets()).pipe(
        map$((data: IGetProductsPayloadSuccess) => {
          return getCarnets.success(data.items);
        }),
        takeUntil$(
          action$.pipe(
            filter$(isOfType(LOCATION_CHANGE)),
            tap$(() => productsApi.cancelProducts()),
          ),
        ),
        catchError$((error: Error) => of$(getCarnets.failure(error))),
      );
    }),
  );
};

export const redirectToReservationWhenSelectedCarnet: _Store.IEpic = (
  action$,
  state$,
  { linksProvider },
) => {
  return action$.pipe(
    filter$(isActionOf(selectCarnet)),
    mergeMap$((action) => {
      const { id } = action.payload;
      const redirectUrl = linksProvider.buildReservationLink(id);

      return of$(push(redirectUrl));
    }),
  );
};
