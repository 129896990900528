import React from 'react';

import moment from 'moment';
import DatePicker from 'react-datepicker';

import { IDatePickerModalProps } from './DatePickerModal.types';

import styles from './DatePickerModal.module.scss';

export const DatePickerModal = ({ onChange }: IDatePickerModalProps) => {
  return (
    <div className={styles.modal}>
      <div className={styles.body}>
        <div className={styles.right}>
          <div className="customHangarDatePicker">
            <DatePicker
              fixedHeight={true}
              maxDate={moment().toDate()}
              inline={true}
              locale="pl"
              onChange={onChange}
              // tslint:disable-next-line: jsx-no-lambda
              formatWeekDay={(nameOfDay: string): string =>
                nameOfDay.toString().substr(0, 1)
              }
              useWeekdaysShort={true}
              showYearDropdown={true}
              dateFormatCalendar="MMMM"
              yearDropdownItemNumber={70}
              scrollableYearDropdown={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DatePickerModal;
