import React, { useContext, useEffect } from 'react';

import { clearContext } from '@Compo/Basket/ZagrywkiBasketProvider/ZagrywkiBasketProvider';
import UpSellV2 from '@Compo/UpSellV2';
import _L from '@Misc/helpers/local';

import { CarnetField } from './Components/CarnetField';
import DiscountField from './Components/DiscountField/DiscountField.container';
import InvoiceField from './Components/InvoiceField';
import { IFormLayoutProps } from './ExtraFields.types';

import styles from './ExtraFields.module.scss';

const FormLayout = (props: IFormLayoutProps) => {
  const { haveUpSell, products, setValues } = props;

  const BagState = useContext(clearContext);
  useEffect(() => {
    if (BagState) {
      BagState.readLocalStorage();

      if (BagState.userData) {
        setValues(BagState.userData);
      }
    }
  }, []);

  const canDisplayUpSellBox =
    haveUpSell ||
    (products && products.length) ||
    (BagState &&
      BagState.basketItems.length &&
      BagState.basketItems.some((item) => item.upSell));

  return (
    <>
      <fieldset className={styles.box}>
        <div className={styles.factureFormRow}>
          <CarnetField {...props} />
          <DiscountField {...props} />
          <InvoiceField {...props} />
        </div>
      </fieldset>

      {!!canDisplayUpSellBox && (
        <section className={styles.section}>
          <UpSellV2 />
        </section>
      )}
    </>
  );
};

export default FormLayout;
