import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { captureIframeEvent } from '@Model/iframe/actions';
import { get } from '@Model/iframe/selectors';
import { IEvent } from '@Model/iframe/types';
import _Store from '@Store';
import ExternalForm from './ExternalForm.component';
import {
  IExternalFormFromDispatch,
  IExternalFormFromState,
} from './ExternalForm.types';

const mapStateToProps = (state: _Store.IState): IExternalFormFromState => {
  const { iframeHeight, parentUrl, externalFormUrl } = get(state);
  return {
    externalFormUrl,
    iframeHeight,
    parentUrl,
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>,
): IExternalFormFromDispatch => ({
  captureIframeEvent: (event: IEvent) => dispatch(captureIframeEvent(event)),
});

export default connect<
  IExternalFormFromState,
  IExternalFormFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps,
)(ExternalForm);
