import React, { useState } from 'react';

import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import cn from 'classnames';

import CheckboxRound from '@Compo/forms/CheckboxRound';
import FormField from '@Compo/forms/FormField';
import Config from '@Config';

import styles from '../Styles.module.scss';
import useStyles from './DiscountField.styles';
import { IDiscountProps } from './DiscountField.types';
import { staticCodesSaltos } from './staticDiscountCodes.constants';

const TITLE_TEXT = 'Posiadasz kod zniżkowy?';
const CARNET_NUMBER_TEXT = 'kod zniżkowy';
const HAVE_DISCOUNT_CODE = 'Mam kartę zniżkową';
const CALCULATE_TEXT = 'Przelicz';
const CHOOSE_STATIC_DISCOUNT_CODE_TEXT =
  'Wybierz kod znikowy z listy lub wpisz w pole poniżej';
const CHOOSE_STATIC_DISCOUNT_CODE_HELPER_TEXT =
  'Kody zniżkowe dotyczą tylko Biletu normalnego';

const { isHangar, isSaltos } = Config.theme;

export const DiscountField = ({
  setFieldValue,
  values: { discount },
  values,
  isCarnet,
  touched,
  errors,
  isCurrentReservation,
  checkDiscount,
}: IDiscountProps) => {
  const [selectedDiscount, setSelectedDiscount] = useState('default');
  const classes = useStyles();

  const isError = (fieldName: string): boolean =>
    // @ts-ignore
    touched[fieldName] && errors[fieldName] !== undefined;

  if (isCarnet || !isCurrentReservation) {
    return null;
  }

  const handleCalculate = () => checkDiscount(values['discountCode']);

  const handleChange = (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>,
  ) => {
    setSelectedDiscount(event.target.value as string);
    if (event.target.value !== 'default') {
      setFieldValue('discountCode', event.target.value);
    }
  };

  return (
    <div className={styles.carnetBox}>
      {discount && (
        <div className={styles.formRow}>
          <div className={styles.title}>{TITLE_TEXT}</div>

          {isSaltos && (
            <FormControl
              variant="standard"
              fullWidth={true}
              className={classes.selectWrapper}
            >
              <InputLabel variant="outlined">
                {CHOOSE_STATIC_DISCOUNT_CODE_TEXT}
              </InputLabel>
              <Select
                value={selectedDiscount}
                onChange={handleChange}
                defaultValue={'default'}
                fullWidth={true}
                name="discountCode"
                variant="outlined"
              >
                <MenuItem value={'default'}>-</MenuItem>
                {staticCodesSaltos.map((staticCode) => (
                  <MenuItem key={staticCode.value} value={staticCode.value}>
                    {staticCode.name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>
                {CHOOSE_STATIC_DISCOUNT_CODE_HELPER_TEXT}
              </FormHelperText>
            </FormControl>
          )}

          <div className={styles.row}>
            <label className={styles.label} htmlFor={'passCode'}>
              <div className={styles.labelText}>{CARNET_NUMBER_TEXT}</div>
              <div className={styles.input}>
                <FormField
                  id={'discountCode'}
                  name={'discountCode'}
                  type="text"
                  isError={isError('discountCode')}
                />
              </div>
            </label>
            <label
              className={cn(styles.label, styles.labelButton)}
              htmlFor={'passCode'}
            >
              <button
                className={styles.button}
                type="button"
                onClick={handleCalculate}
              >
                {CALCULATE_TEXT}
              </button>
            </label>
          </div>
        </div>
      )}

      {(isHangar || isSaltos) && !isCarnet && (
        <label className={styles.label} htmlFor="discount">
          <CheckboxRound
            checked={!!discount}
            // tslint:disable-next-line: jsx-no-lambda
            onChange={() => setFieldValue('discount', !discount)}
          >
            <span>{HAVE_DISCOUNT_CODE}</span>
          </CheckboxRound>
        </label>
      )}
    </div>
  );
};

export default DiscountField;
