import React from 'react';

import Config from '@Config';
import { priceFormatter } from '@Misc/helpers';
import CheckboxRound from '../forms/CheckboxRound';
import Products from './components/Products';
import { IUpSellProps } from './UpSellV2.types';

import styles from './UpSellV2.module.scss';

const ENLARGE_TEXT = 'Wydłuż grę';
const ENLARGE_SALTOS_TEXT = 'Wydłuż';
const CONJUNCTION_TEXT = ' o ';
const MINUTES_TEXT = 'minut';
const YOUR_PLAY_WILL_LAST_TILL_TEXT = 'Wasza zabawa będzie trwać do';
const PLAY_EVEN_BETTER_TEXT = 'Bawcie się jeszcze lepiej';

const UpSellV2 = ({
  additionalDuration,
  finishTime,
  isUpSellSelected,
  setUpSell,
  upSellPrice,
  haveUpSell,
  name,
}: IUpSellProps) => {
  const { isHangar, isSaltos } = Config.theme;

  const getAddingValue = (price: number): string =>
    `+ ${priceFormatter(price)} PLN`;

  const icon =
    isHangar || isSaltos
      ? '/static/another-hour-black.svg'
      : '/static/another-hour.svg';

  return (
    <div className={styles.upSell}>
      <div className={styles.line} />

      <div className={styles.title}>{PLAY_EVEN_BETTER_TEXT}</div>

      {haveUpSell && (
        <div className={styles.row}>
          <div className={styles.customRow}>
            <div className={styles.first}>
              <CheckboxRound
                checked={!!isUpSellSelected}
                // tslint:disable-next-line: jsx-no-lambda
                onChange={() => setUpSell(!isUpSellSelected)}
                closeIcon={true}
              />
              <div className={styles.checkBoxText}>
                <span className={styles.infoTitle}>
                  {isSaltos ? ENLARGE_SALTOS_TEXT : ENLARGE_TEXT} {name}{' '}
                  {CONJUNCTION_TEXT} {additionalDuration} {MINUTES_TEXT}
                </span>
                <span className={styles.infoDescription}>
                  {YOUR_PLAY_WILL_LAST_TILL_TEXT} {finishTime}
                </span>
              </div>
            </div>

            <div className={styles.second}>{getAddingValue(upSellPrice)}</div>
          </div>
        </div>
      )}

      <Products />
    </div>
  );
};

export default UpSellV2;
