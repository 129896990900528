import pl, { Translation } from './pl';

const plSaltos: Translation = {
  ...pl,
  entryListL: {
    ...pl.entryListL,
    ticketStep: {
      ...pl.entryListL.ticketStep,
      enterTicketCode: 'Wpisz kod biletu i dodaj osoby, które będą skakać',
    },
  },
};

export default plSaltos;
