import React from 'react';

import SliderComponent from '@Compo/forms/SliderMaterial';
import { ISliderProps } from './Slider.types';

const Slider = ({
  capacityLeft,
  maxPeopleCount,
  peopleCount,
  priceType,
  setPeopleCount,
  numberOfPeople,
  step,
}: ISliderProps) => (
  <SliderComponent
    capacityLeft={capacityLeft}
    maxValue={maxPeopleCount}
    onChange={setPeopleCount}
    step={step}
    value={numberOfPeople}
    priceType={priceType}
  />
);

export default Slider;
