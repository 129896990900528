import routes from '@/routes/routes';

class LinksProvider {
  public buildPaymentFailLink(parentUrl: string): string {
    return `${parentUrl}/${routes.paymentFail}`;
  }

  public buildHappeningLink(roomSlug: string): string {
    const happeningRoute = routes.happening.split('/');
    return `${happeningRoute[1]}/${roomSlug}`;
  }

  public buildSummaryFailLink(id: string): string {
    const paymentFailRoute = routes.paymentFail.split('/');

    return `/${paymentFailRoute[1]}/${id}/${paymentFailRoute[3]}`;
  }

  public buildSummaryLink(transactionId: string): string {
    const summaryRoute = routes.summary.split('/');

    return `${summaryRoute[1]}/${transactionId}`;
  }

  public removeURLParameter(url: string, parameter: string): string {
    const parts = url.split('?');
    if (parts.length >= 2) {
      const prefix = encodeURIComponent(parameter) + '=';
      const pars = parts[1].split(/[&;]/g);

      for (let i = pars.length; i-- > 0; ) {
        if (pars[i].lastIndexOf(prefix, 0) !== -1) {
          pars.splice(i, 1);
        }
      }

      return parts[0] + (pars.length > 0 ? '?' + pars.join('&') : '');
    }
    return url;
  }

  public buildReservationLink(carnetId: number): string {
    const summaryRoute = routes.reservation.split('/');

    return `${summaryRoute[1]}/${carnetId}`;
  }
}

export default new LinksProvider();
