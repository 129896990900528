const zagrywkiTheme = () => ({
  overrides: {
    MuiButton: {
      containedSizeLarge: {
        fontSize: 16,
        fontWeight: 700,
        lineHeight: 1,
        paddingBottom: 16,
        paddingLeft: 24,
        paddingRight: 24,
        paddingTop: 16,
      },
      outlinedSizeLarge: {
        color: '#111111',
        fontSize: 16,
        fontWeight: 700,
        lineHeight: 1,
        paddingBottom: 16,
        paddingLeft: 24,
        paddingRight: 24,
        paddingTop: 16,
      },
      root: {
        borderRadius: '0.5rem',
      },
      textPrimary: {
        color: '#111111',
        fontSize: 14,
      },
    },
    MuiCssBaseline: {
      '@global': {
        body: {
          color: '#0e066b',
        },
      },
    },

    MuiFormHelperText: {
      root: {
        fontSize: 12,
        fontWeight: 400,
        letterSpacing: '0.4px',
        lineHeight: '20px',
      },
    },
    MuiInputBase: {
      root: {
        fontSize: 16,
        fontWeight: 400,
        letterSpacing: '0.15px',
        lineHeight: '19px',
      },
    },
    MuiSvgIcon: {
      fontSizeLarge: {
        fontSize: '3.5rem',
      },
      root: {
        fontSize: '2.4rem',
      },
    },
    MuiTypography: {
      colorTextSecondary: {
        color: '#0F086B',
      },
    },
  },
  palette: {
    background: {
      default: '#ffbfbf',
    },
    info: {
      contrastText: '#ffffff',
      main: '#60748B',
    },
    other: {
      stroke: 'rgba(0, 0, 0, 0.23)',
    },
    primary: {
      contrastText: '#0e066b',
      dark: '#ede40c',
      light: '#ffff57',
      main: '#eff200',
    },
    secondary: {
      contrastText: '#ffffff',
      dark: '#34495e',
      light: '#8ea3bb',
      main: '#60748b',
    },
  },
  spacing: [0, 4, 8, 16, 24, 32, 48, 96, 120, 160],
  typography: {
    fontFamily: [
      '"Faktum"',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),

    body1: {
      fontSize: 18,
      fontWeight: 400,
      letterSpacing: '0.15px',
      lineHeight: '27px',
    },
    body2: {
      fontSize: 18,
      fontWeight: 300,
    },

    fontWeightBold: 500,

    h1: {
      fontSize: 32,
      fontWeight: 500,
      letterSpacing: '-1.5px',
      lineHeight: '41px',
    },
    h2: {
      fontWeight: 500,
    },
    h3: {
      fontWeight: 500,
    },
    h4: {
      fontWeight: 500,
    },
    h5: {
      fontWeight: 500,
    },
    h6: {
      fontSize: 20,
      fontWeight: 400,
      lineHeight: '32px',
    },
  },
});

export default zagrywkiTheme;
