import CryptoJS from 'crypto-js';

class AnalyticsApi {
  public pushBuyWithoutCode() {
    this.addToDataLayer({
      event: 'registration_start_without_code',
      eventInfo: {
        text_displayed: 'NIE',
      },
      category: {
        primary_category: 'registration',
      },
    });
  }

  public pushBuyWithTicketCode() {
    this.addToDataLayer({
      event: 'registration_start_with_code',
      eventInfo: {
        text_displayed: 'TAK',
      },
      category: {
        primary_category: 'registration',
      },
    });
  }

  public pushNewUserRegistration(email: string) {
    this.addToDataLayer({
      event: 'registration_first_user',
      eventInfo: { text_displayed: 'TAK' },
      category: {
        primary_category: 'registration',
        user: {
          profile: {
            email: CryptoJS.SHA256(email).toString(),
          },
        },
      },
    });
  }

  public pushAddVisitorData(email: string) {
    this.addToDataLayer({
      event: 'registration_add_visitor',
      eventInfo: {
        text_displayed: 'DALEJ',
      },
      category: {
        primary_category: 'registration',
      },
      user: {
        profile: {
          email: CryptoJS.SHA256(email).toString(),
        },
      },
    });
  }

  public pushRegistrationVisitorConfirmed(email: string) {
    this.addToDataLayer({
      event: 'registration_visitor_confirmed',
      eventInfo: {
        text_displayed: 'POTWIERDZAM',
      },
      category: {
        primary_category: 'registration',
      },
      user: {
        profile: {
          email: CryptoJS.SHA256(email).toString(),
        },
      },
    });
  }

  public pushRegistrationSuccess(email: string) {
    this.addToDataLayer({
      event: 'registration_success',
      eventInfo: {
        text_displayed: 'ZAKOŃCZ',
      },
      category: {
        primary_category: 'registration',
      },
      user: {
        profile: {
          email: CryptoJS.SHA256(email).toString(),
        },
      },
    });
  }

  protected isDataLayerEnabled() {
    return typeof window !== 'undefined' && window.dataLayer;
  }

  protected addToDataLayer(commonLayer: object, layer?: object) {
    if (this.isDataLayerEnabled()) {
      window.dataLayer.push({
        ecommerce: null,
      });
      window.dataLayer.push({
        ...commonLayer,
        ...layer,
      });
    }
  }
}

export default new AnalyticsApi();
