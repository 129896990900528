import locals from '@App/_current/vars';

const local = (key: string) => {
  if (Object.prototype.hasOwnProperty.call(locals, key)) {
    return locals[key];
  }

  return '';
};

export default local;
