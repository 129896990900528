import React, { useEffect } from 'react';

import cn from 'classnames';

import { priceFormatter } from '@Misc/helpers';
import Slider from '../Slider';
import { ISingularProductProps } from './SingularProduct.types';

import styles from './SingularProduct.module.scss';

const ADD_TEXT = 'Dodaj';
const ADD_SMALL_TEXT = '+';
const REMOVE_TEXT = 'Usuń';
const REMOVE_SMALL_TEXT = '-';

const SingularProduct = ({
  isBigDesktop,
  isDesktop,
  peopleCount,
  product,
  product: { count, autocomplete, imageUrl },
  selectProduct,
  setProductCount,
  unSelectProduct,
}: ISingularProductProps) => {
  const showFullText = isBigDesktop || isDesktop;
  const multiple = true;

  const getIconUrl = (): string => {
    if (imageUrl) {
      return imageUrl;
    }
    return '';
  };

  useEffect(() => {
    if (autocomplete && peopleCount) {
      setProductCount({ product, count: peopleCount });
    }
  }, []);

  const cathProductCount = (newCount: number) => {
    setProductCount({ product, count: newCount });
  };

  const catchSelectProduct = () => {
    if (product && product.count && product.count > 0) {
      selectProduct(product);
    } else {
      setProductCount({ product, count: 1 });
    }
  };

  const catchUsSelectProduct = () => {
    unSelectProduct(product);
  };

  const renderSlider = () => {
    const sliderValue = count || 0;
    return (
      <Slider
        minValue={0}
        onChange={cathProductCount}
        showFullText={showFullText}
        maxValue={1000}
        value={sliderValue}
      />
    );
  };

  const showMobilePositionSlider = !showFullText;
  const showDesktopPositionSlider = showFullText;

  return (
    <div className={styles.row} key={product.id}>
      <img className={styles.image} src={getIconUrl()} />
      <div className={styles.info}>
        <span className={styles.infoTitle}>{product.name}</span>
        <span className={styles.infoDescription}>{product.description}</span>
        {showMobilePositionSlider && (
          <div className={styles.mainWrapper}>{multiple && renderSlider()}</div>
        )}
      </div>
      <div className={styles.actions}>
        <div className={styles.addingPrice}>
          + {priceFormatter(product.price)} PLN
        </div>
        <div className={styles.actionsContainer}>
          {showDesktopPositionSlider && (
            <div className={styles.mainWrapper}>
              {multiple && renderSlider()}
            </div>
          )}

          <div className={styles.buttons}>
            {product.selected ? (
              <button
                type="button"
                className={cn(styles.button, styles.buttonSelected)}
                onClick={catchUsSelectProduct}
              >
                {showFullText && REMOVE_TEXT}
                {!showFullText && REMOVE_SMALL_TEXT}
              </button>
            ) : (
              <button
                className={styles.button}
                type="button"
                onClick={catchSelectProduct}
              >
                {showFullText && ADD_TEXT}
                {!showFullText && ADD_SMALL_TEXT}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingularProduct;
