import React, { useEffect } from 'react';

import CheckboxRound from '@Compo/forms/CheckboxRound';
import { priceFormatter } from '@Misc/helpers';
import Slider from '../Slider';
import { ISingularProductProps } from './SingularProduct.types';

import styles from './SingularProduct.module.scss';

const SingularProduct = ({
  isBigDesktop,
  isDesktop,
  peopleCount,
  product,
  product: { count, autocomplete },
  setProductCount,
  unSelectProduct,
}: ISingularProductProps) => {
  const showFullText = isBigDesktop || isDesktop;
  const multiple = true;

  useEffect(() => {
    if (autocomplete && peopleCount) {
      setProductCount({ product, count: peopleCount });
    }
  }, []);

  const cathProductCount = (newCount: number) => {
    setProductCount({ product, count: newCount });
  };

  const catchUsSelectProduct = () => {
    unSelectProduct(product);
  };

  const renderSlider = () => {
    const sliderValue = count || 0;
    return (
      <Slider
        minValue={0}
        onChange={cathProductCount}
        showFullText={showFullText}
        maxValue={1000}
        value={sliderValue}
        round={true}
      />
    );
  };

  return (
    <>
      <div className={styles.row}>
        <div className={styles.customRow}>
          <div className={styles.first}>
            <CheckboxRound
              checked={!!product.selected}
              onChange={catchUsSelectProduct}
              closeIcon={true}
            />
            <div className={styles.checkBoxText}>
              <span className={styles.infoTitle}>{product.name}</span>
              {product.description && product.description.length && (
                <span className={styles.infoDescription}>
                  {product.description}
                </span>
              )}
            </div>
          </div>

          <div className={styles.second}>
            + {priceFormatter(product.price)} PLN
          </div>
          <div className={styles.third}>
            <div className={styles.mainWrapper}>
              {multiple && renderSlider()}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SingularProduct;
