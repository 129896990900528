import { IZagrywkiBasket } from '@Model/basket/types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { AnyAction, Dispatch } from 'redux';

import { isMobile, isSmallMobile, isTablet } from '@Model/app/selectors';
import getBasketData from '@Model/basket/selector/getBasketData';
import { resetSpaceAndSlot } from '@Model/booking/actions';
import {
  getRecommendedNumberOfPlayers,
  getSelectedPrices,
  getSelectedSpace,
} from '@Model/booking/selector';
import calculatePrice from '@Model/booking/selector/calculatePrice';
import isBookingAvailable from '@Model/booking/selector/isBookingAvailable';
import { happeningMounted, resetState } from '@Model/happening/actions';
import { get as getHappening } from '@Model/happening/selectors';
import { happeningsMounted } from '@Model/happenings/actions';
import { addToGlobalBasket, redirectParentTo } from '@Model/iframe/actions';
import { get as getIframe } from '@Model/iframe/selectors';
import { get as getCheckedPrice } from '@Model/price/selectors';
import {
  MODULE_AVAILABILITIES,
  MODULE_HAPPENING,
} from '@Model/state/constants/constants';
import { getLoading } from '@Model/state/selectors';
import _Store from '@Store';
import Happening from './Happening.component';
import { IHappeningFromDispatch, IHappeningFromState } from './Happening.types';

const mapStateToProps = (state: _Store.IState): IHappeningFromState => {
  const getPrice = (): number => {
    const checkedPrice = getCheckedPrice(state);
    const calculatedPrice = calculatePrice(state);

    if (checkedPrice && checkedPrice.value) {
      return checkedPrice.value;
    } else if (calculatedPrice) {
      return calculatedPrice;
    }

    return 0;
  };

  return {
    addBasketRedirect: getIframe(state).addBasketRedirect,
    currentBasketData: getBasketData(state),
    happening: getHappening(state),
    isBookingAvailable: isBookingAvailable(state),
    isLoading: getLoading(MODULE_HAPPENING)(state),
    isLoadingAvailabilities: getLoading(MODULE_AVAILABILITIES)(state),
    isSmallScreen: isSmallMobile(state) || isMobile(state) || isTablet(state),
    price: getPrice(),
    recommendedNumberOfPlayers: getRecommendedNumberOfPlayers(state),
    selectedPrices: getSelectedPrices(state),
    selectedSpace: getSelectedSpace(state),
    showBasket: getIframe(state).showBasket,
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>,
): IHappeningFromDispatch => ({
  addToGlobalBasket: (items: IZagrywkiBasket[]) =>
    dispatch(addToGlobalBasket(items)),
  getHappenings: () => dispatch(happeningsMounted()),
  mounted: () => dispatch(happeningMounted()),
  redirectParentTo: (url) => dispatch(redirectParentTo(url)),
  resetSpaceAndSlot: () => dispatch(resetSpaceAndSlot()),
  unmount: () => dispatch(resetState()),
});

export default withRouter(
  connect<IHappeningFromState, IHappeningFromDispatch, {}, _Store.IState>(
    mapStateToProps,
    mapDispatchToProps,
  )(Happening),
);
