import React from 'react';

import Grid from '@Compo/layout/Grid';
import PageWrapper from '@Compo/layout/PageWrapper';
import SummaryFail from '@Compo/SummaryFail';

const SummaryFailPage = () => (
  <Grid>
    <PageWrapper>
      <SummaryFail />
    </PageWrapper>
  </Grid>
);

export default SummaryFailPage;
