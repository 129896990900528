import { getHappenings } from '@Model/happenings/actions';
import _Store from '@Store';
import { filter as filter$, map as map$ } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { resetLoading, setLoading } from './../actions';
import { MODULE_HAPPENINGS } from './../constants/constants';

export const whenHappeningsRequestedSetLoading: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf(getHappenings.request)),
    map$(() => setLoading(MODULE_HAPPENINGS)),
  );
};

export const whenHappeningsFinishedResetLoading: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$((action: _Store.IAction) =>
      isActionOf([getHappenings.success, getHappenings.failure], action),
    ),
    map$(() => resetLoading(MODULE_HAPPENINGS)),
  );
};
