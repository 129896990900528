import { providers } from 'goingapp-shared';
import { createAsyncAction, createStandardAction } from 'typesafe-actions';

import { IBookFormValues } from '@Compo/BookForm/BookForm.types';
import { IZagrywkiBasket } from '@Model/basket/types';
import {
  ICompanyDataReducer,
  IGetTermsSuccessPayload,
  IReservationUser,
} from '@Model/reservation/types';
import {
  _GET_COMPANY_DATA_FAILURE,
  _GET_COMPANY_DATA_REQUEST,
  _GET_COMPANY_DATA_SUCCESS,
  _GET_REGULATIONS_FAILURE,
  _GET_REGULATIONS_REQUEST,
  _GET_REGULATIONS_SUCCESS,
  CLEAR_DISCOUNT,
  GET_COMPANY_DATA,
  RE_TRANSACTION,
  RESERVATION_MOUNTED,
  RESET_STATE,
  SET_COMPANY_DATA,
  SET_DISCOUNT,
  SET_FORM_DATA,
  SET_TERMS,
  SET_UPSELL,
  SET_USER_DATA,
} from './../constants/actions';

export const getCompanyData = createAsyncAction(
  _GET_COMPANY_DATA_REQUEST,
  _GET_COMPANY_DATA_SUCCESS,
  _GET_COMPANY_DATA_FAILURE,
)<string, undefined, undefined>();

export const getTerms = createAsyncAction(
  _GET_REGULATIONS_REQUEST,
  _GET_REGULATIONS_SUCCESS,
  _GET_REGULATIONS_FAILURE,
)<undefined, IGetTermsSuccessPayload, Error>();

export const reservationMounted = createStandardAction(RESERVATION_MOUNTED)();

export const setUserData = createStandardAction(SET_USER_DATA)<{
  data: IReservationUser;
  basketItems: IZagrywkiBasket[];
  onDone: () => void;
  idempotencyKey: string;
  basketError?: providers.IGoingBagTypes.IBasketError;
}>();

export const reTransaction = createStandardAction(RE_TRANSACTION)<{
  basketError: providers.IGoingBagTypes.IBasketError;
  basketItems: IZagrywkiBasket[];
  onDone: () => void;
  idempotencyKey?: string;
}>();

export const setTerms = createStandardAction(SET_TERMS)<number>();

export const setDiscount = createStandardAction(SET_DISCOUNT)<string>();

export const clearDiscount = createStandardAction(CLEAR_DISCOUNT)();

export const resetState = createStandardAction(RESET_STATE)();

export const setUpSell = createStandardAction(SET_UPSELL)<boolean>();

export const catchCompanyData = createStandardAction(GET_COMPANY_DATA)<
  string
>();

export const setFormData = createStandardAction(SET_FORM_DATA)<
  IBookFormValues
>();

export const setCompanyData = createStandardAction(SET_COMPANY_DATA)<
  ICompanyDataReducer
>();
