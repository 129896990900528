import React from 'react';

import Happenings from '@Compo/Happenings';
import Grid from '@Compo/layout/Grid';
import PageWrapper from '@Compo/layout/PageWrapper';

const MainPage = () => (
  <Grid>
    <PageWrapper>
      <Happenings />
    </PageWrapper>
  </Grid>
);

export default MainPage;
