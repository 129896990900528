import React, { FC, useContext } from 'react';

import { providers } from 'goingapp-shared';
import moment from 'moment';

import cn from 'classnames';
import { clearContext } from '../Basket/ZagrywkiBasketProvider/ZagrywkiBasketProvider';
import styles from './Error.module.scss';
import { IErrorProps } from './Error.types';

const RESERVATION_ERROR_TEXT = 'Niestety nie udało się zarezerwować pozycji:';
const CONTINUE_TEXT = 'Mimo to kontynuuje i idę do płatności';
const OK_TEXT = 'OK';

const Error: FC<IErrorProps> = ({ remove, error, reTransaction }) => {
  if (!error) {
    return null;
  }

  const state = useContext(clearContext);

  const handleContinueTransaction = () => {
    remove();

    if (error && providers.IGoingBagTypes.checkIsInstanceOfBasketError(error)) {
      if (state && state.basketItems) {
        const idFromBasket = state.basketItems.find(
          (item) =>
            item.dateTime === error.dateTime &&
            item.happeningId.toString() === error.happeningId &&
            item.spaceId.toString() === error.spaceId,
        );

        if (idFromBasket && idFromBasket.id) {
          const newList = state.removeFromBag(idFromBasket.id);

          reTransaction({
            basketError: error,
            basketItems: newList || [],
            onDone: state && state.clearBasket ? state.clearBasket : () => null,
          });
        } else {
          reTransaction({
            basketError: error,
            basketItems: state && state.basketItems ? state.basketItems : [],
            onDone: state && state.clearBasket ? state.clearBasket : () => null,
          });
        }
      } else {
        reTransaction({
          basketError: error,
          basketItems: [],
          onDone: state && state.clearBasket ? state.clearBasket : () => null,
        });
      }
    }
  };

  const renderErrorMessages = () => {
    if (
      error &&
      providers.IGoingBagTypes.checkIsInstanceOfBasketError(error) &&
      state &&
      state.basketItems.length
    ) {
      const finallyTitle = `${error.text} ${moment(error.dateTime).format(
        'DD-MM-YYYY MM:mm',
      )} ${error.errorMessage}`;
      return (
        <div>
          <div className={styles.errorMessageTitle}>
            {RESERVATION_ERROR_TEXT}
          </div>
          <div className={styles.errorMessage}>{finallyTitle}</div>
        </div>
      );
    } else if (
      error &&
      providers.IGoingBagTypes.checkIsInstanceOfBasketError(error)
    ) {
      return <div className={styles.errorMessage}>{error.errorMessage}</div>;
    } else if (error && typeof error.text === 'string') {
      return <div className={styles.errorMessage}>{error.text}</div>;
    }
    return null;
  };

  const renderButtonsMessages = () => {
    if (
      providers.IGoingBagTypes.checkIsInstanceOfBasketError(error) &&
      state &&
      state.basketItems.length
    ) {
      return (
        <div className={styles.buttonsWrapper}>
          <button className={cn(styles.button)} onClick={remove}>
            {OK_TEXT}
          </button>
          <button
            className={cn(styles.button)}
            onClick={handleContinueTransaction}
          >
            {CONTINUE_TEXT}
          </button>
        </div>
      );
    }
    return (
      <button className={cn(styles.button)} onClick={remove}>
        {OK_TEXT}
      </button>
    );
  };

  return (
    <div className={styles.errorBody}>
      <div className={styles.inner}>
        {renderErrorMessages()}

        {renderButtonsMessages()}
      </div>
    </div>
  );
};

export default Error;
