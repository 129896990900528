import React from 'react';

import Carnets from '@Compo/Carnets';
import Grid from '@Compo/layout/Grid';
import PageWrapper from '@Compo/layout/PageWrapper';

const CarnetsPage = () => (
  <Grid>
    <PageWrapper>
      <Carnets />
    </PageWrapper>
  </Grid>
);

export default CarnetsPage;
