import { createAsyncAction, createStandardAction } from 'typesafe-actions';

import { IPage2Submission } from '@Compo/CustomByClient/Hangar/EntryTerm/EntryTerm.types';
import * as ACTIONS from '../constants/actions';

export const editUserData = createAsyncAction(
  ACTIONS._EDIT_USER_REQUEST,
  ACTIONS._EDIT_USER_SUCCESS,
  ACTIONS._EDIT_USER_FAILURE,
)<{ data: IPage2Submission; url: string }, string, Error>();

export const catchSaveUser = createStandardAction(ACTIONS.CATCH_SAVE_USER)<{
  data: IPage2Submission;
  url: string;
}>();
