import { combineEpics } from 'redux-observable';

// Import modules
import * as App from '@Model/app/epics';
import * as Booking from '@Model/booking/epics';
import * as Errors from '@Model/errors/epics';
import * as ExtendedPayment from '@Model/extendedPayment/epics';
import * as FormIo from '@Model/formio/epics';
import * as Happening from '@Model/happening/epics';
import * as Happenings from '@Model/happenings/epics';
import * as Iframe from '@Model/iframe/epics';
import * as Price from '@Model/price/epics';
import * as Products from '@Model/products/epics';
import * as State from '@Model/state/epics';
import * as Transaction from '@Model/transaction/epics';
import * as Analytics from '@Model/analytics/epics';

/* @@STORE_COMPONENT@@ */
export default combineEpics(
  // Analytics
  Analytics.addDataToGTMWhenUserRegisterWithoutCode,
  Analytics.addDataToGTMWhenUserRegisterWithTicketCode,
  Analytics.addDataToGTMWhenNewUserRegistered,
  Analytics.addDataToGTMWhenVisitorDataAdd,
  Analytics.addDataToGTMWhenRegistratinSuccess,
  Analytics.addDataToGTMWhenRegistratonVisitorConfirmed,

  // App
  App.whenSizesChangedSetDevice,
  App.asyncRedirectWhenAction,

  // Booking
  Booking.whenDayIsSetGetAvailabilities,
  Booking.whenGetAvailabilitiesRequested,
  Booking.whenHappeningMountedSetDay,
  Booking.whenReservationMounted,
  Booking.whenReservationMountedGetRegulations,
  Booking.whenBasketIsReadyTerms,
  Booking.catchSetTermsWhenAction,
  Booking.whenGetHappeningCheckTimeIsBetweenMidnightAndTime,
  Booking.setCapacityLeftWhenSelectedPriceChanged,

  // Errors
  Errors.addErrorWhenRequested,

  // Iframe
  Iframe.setStartStateWhenAppMounted,
  Iframe.runResizeMethodWhenSetDevice,
  Iframe.pingToApiWhenMounted,
  Iframe.captureIframeEventWhenPostMessage,
  Iframe.setRoomWhenRequest,
  Iframe.scrollToTopIframeWhenActions,
  Iframe.scrollToTopIframeWhenSummaryMounted,
  Iframe.redirectToTryAgainWhenAction,
  Iframe.scrollIframeToIframeWhenActions,
  Iframe.refreshPageWhenAction,
  Iframe.runFnqActionWhenAction,
  Iframe.setGlobalAction,
  Iframe.setGlobalActionEmpty,
  Iframe.redirectParentToWhenAction,

  // Happenings
  Happening.whenHappeningMounted,
  Happening.whenHappeningRequested,
  Happening.redirectToHappeningWhenAction,
  Happening.changeHappening,
  Happening.scrollToWhenAction,
  Happening.scrollToSecondStepWhenAction,

  // Happenings
  Happenings.whenHappeningsMounted,
  Happenings.whenHappeningsRequested,

  // Price
  Price.requestForDiscountCheckOnCalculateDiscount,
  Price.requestForDiscountCheckOnCalculateUpsell,
  Price.checkDiscountWhenRequested,
  Price.checkPriceWhenSetPriceType,
  Price.displayErrorMessageWhenCheckPriceFailure,
  Price.catchCheckPriceWhenReservationStateHasBeChanged,
  Price.whenSetSlotSetSpaceAction,

  // State
  State.whenAvailabilitiesFinishedResetLoading,
  State.whenAvailabilitiesRequestedSetLoading,
  State.whenHappeningFinishedResetLoading,
  State.whenHappeningRequestedSetLoading,
  State.whenHappeningsFinishedResetLoading,
  State.whenHappeningsRequestedSetLoading,
  State.setLoaderPositionWhenBookingSendRequested,
  State.setLoaderWhenBookingSendFinished,
  State.setUpStateWhenCompanyDataRequested,
  State.setLoaderWhenCompanyDataFinished,
  State.whenGetTransationDetailsFinishedResetLoading,
  State.whenGetTransactionDetailsSetLoading,

  // Transaction
  Transaction.captureTransactionDetailsWhenSummaryMounted,
  Transaction.runFbqActionWhenSummaryMounted,
  Transaction.captureTransactionEmpikDetailsWhenRequest,
  Transaction.postTransactionWhenRequested,
  Transaction.showErrorMessageWhenBookingFailed,
  Transaction.redirectToPayIWhenTransactionPosted,
  Transaction.getCompanyDataWhenRequest,
  Transaction.catchCompanyDataWhenRequest,
  Transaction.runRedirectAfterSaleWhenRequest,

  // Products
  Products.getProductsWhenRequest,
  Products.whenSummaryMountedGetProducts,
  Products.selectProductWhenRequest,
  Products.unSelectAllProductsWhenRequest,
  Products.unSelectProductWhenRequest,
  Products.getProductsWhenCarnetsMounted,
  Products.setProductCountWhenRequest,
  Products.redirectToReservationWhenSelectedCarnet,

  // FormIo
  FormIo.catchSaveUserWhenRequest,
  FormIo.saveUserDataWhenRequest,

  // ExtendedPayment
  ExtendedPayment.getExtendedPaymentWhenRequested,
  ExtendedPayment.whenExtendedPaymentMounted,
  ExtendedPayment.payWhenRequest,
  ExtendedPayment.catchPayWhenRequest,
  ExtendedPayment.redirectToPaymentWhenAction,
  ExtendedPayment.showErrorMessageWhenBookingFailed,
  ExtendedPayment.getUpdatedExtendedPaymentWhenRequested,
);
