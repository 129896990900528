import { selectorFamily } from 'recoil';

import FormIoApi from '@Services/$formio-api';
import { iframeParams } from '../atoms';

const getOldData = selectorFamily({
  get: (data: {
    userName: string;
    userLastName: string;
    userEmail: string;
  }) => async ({ get }) => {
    try {
      const { entryListUrl } = get(iframeParams);

      const id = await FormIoApi.checkUserExist(
        data.userEmail,
        data.userName,
        data.userLastName,
        entryListUrl,
      );

      return await FormIoApi.getOldUserData(id, entryListUrl);
    } catch {}
  },
  key: 'getOldData',
});

export default getOldData;
