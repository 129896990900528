import React from 'react';

import FormField from '@Compo/forms/FormField';
import styles from './Discount.module.scss';
import { IDiscountProps } from './Discount.types';

const DISCOUNT_TEXT = 'Kod zniżkowy';
const CALCULATE_TEXT = "Przelicz";

const Discount = ({
  checkDiscount,
  errorMessage,
  isDiscountInvalid,
  touched,
  values,
}: IDiscountProps) => {
  const handleCalculate = () => checkDiscount(values['discountCode']);

  return (
    <div className={styles.discount}>
      <div className={styles.row}>
        <label className={styles.label} htmlFor="firstName">
          {DISCOUNT_TEXT}
          <FormField
            customError={errorMessage}
            id="discountCode"
            name="discountCode"
            autoComplete={'discountCode'}
            type="text"
            isError={touched['discountCode'] && isDiscountInvalid}
          />
        </label>

        <button
          className={styles.button}
          type="button"
          onClick={handleCalculate}
        >
          {CALCULATE_TEXT}
        </button>
      </div>
    </div>
  );
};

export default Discount;
