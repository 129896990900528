import { selectorFamily } from 'recoil';

import FormIoApi from '@Services/$formio-api';
import getOldData from './getOldData';

const getFormIoUser = selectorFamily({
  get: (data: {
    userName: string;
    userEmail: string;
    userLastName: string;
    formIoId: string | null;
  }) => async ({ get }) => {
    try {
      return await FormIoApi.getFormIoUserWhenExist(
        data.userEmail,
        data.userName,
        data.userLastName,
        data.formIoId,
      );
    } catch (error) {
      if (error.response.status === 404) {
        return get(
          getOldData({
            userEmail: data.userEmail,
            userLastName: data.userLastName,
            userName: data.userName,
          }),
        );
      }
    }
  },
  key: 'getFormIoUser',
});

export default getFormIoUser;
