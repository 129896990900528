import { useEffect } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { formioData, groupCode, peopleState } from '@Recoil/formio/atoms';
import { isLoading } from '@Recoil/loading/atoms';
import FormIoApi from '@Services/$formio-api';

export const useGroupApi = () => {
  const [code, setCode] = useRecoilState(groupCode);
  const { peopleCount } = useRecoilValue(formioData);
  const people = useRecoilValue(peopleState);
  const setLoading = useSetRecoilState(isLoading);

  const getGroupCode = async () => {
    setLoading(true);

    try {
      const submissionIds: string[] = [];

      people.forEach((person) => {
        if (person.submissionId) {
          submissionIds.push(person.submissionId);
        }
      });

      const group = await FormIoApi.getEntryGroup(
        Number(peopleCount),
        submissionIds,
      );
      setCode(group.slug);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getGroupCode();
  }, []);

  return { code };
};
