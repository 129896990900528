import moment from 'moment';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import getMetadataForLanguage from '@Misc/helpers/getMetadataForLanguage';
import {
  getSelectedDayIncludeTimeSlot,
  getSelectedNumberOfPlayers,
  getSelectedPrices,
  getValues,
} from '@Model/booking/selector';
import calculateUpSellProps from '@Model/booking/selector/calculateUpSellProps';
import { resetState } from '@Model/happening/actions';
import { get as getHappening } from '@Model/happening/selectors';
import { IHappeningSpaceMetadata } from '@Model/happening/types';
import { IHappeningsItemMetadata } from '@Model/happenings/types/index';
import { addToGlobalBasket } from '@Model/iframe/actions';
import { get as getIframe } from '@Model/iframe/selectors/index';
import { get as getPrice } from '@Model/price/selectors';
import {
  getProducts,
  getSelectedProducts,
} from '@Model/products/selectors/index';
import { isUpSellEnabled } from '@Model/reservation/selectors';
import calculateSummaryProps from '@Model/reservation/selectors/calculateSummaryProps';
import _Store from '@Store';
import SummaryBox from './SummaryBox.component';
import {
  ISummaryBoxFromDispatch,
  ISummaryBoxFromState,
} from './SummaryBox.types';

const ONE_PERSON_TEXT = 'osoba';
const TWO_TO_FOUR_PEOPLE_TEXT = 'osoby';
const MORE_THAN_FOUR_PEOPLE_TEXT = 'osób';

const mapStateToProps = (state: _Store.IState): ISummaryBoxFromState => {
  const happening = getHappening(state);
  const { selectedCarnet } = getProducts(state);
  const values = getValues(state);
  const selectedPrices = getSelectedPrices(state);

  const getPropsBySummary = () => {
    if (happening && happening.metadata && happening.metadata.length) {
      const selectedSpace =
        happening &&
        happening.spaces.find((_space) => _space.id === values.space);

      const getSpaceMetadata = (): string => {
        if (values) {
          if (selectedSpace) {
            return getMetadataForLanguage<IHappeningSpaceMetadata>(
              selectedSpace.metadata,
            ).title;
          }
        }
        return '';
      };

      const happeningMetaData = getMetadataForLanguage<IHappeningsItemMetadata>(
        happening.metadata,
      ).title;

      const getNumberOfPeople = (): number => {
        if (happening && selectedSpace) {
          if (selectedPrices.length) {
            return selectedPrices.reduce(
              (total, obj) => obj.numberOfPeople + total,
              0,
            );
          }
          if (happening.calculatePricePerPerson && values.numberOfPlayers) {
            return values.numberOfPlayers;
          }

          return selectedSpace.maxNumberOfPeople;
        }

        return 0;
      };
      const numberOfPeople = getNumberOfPeople();

      const getSpellNumberOfPeople = (count: number): string => {
        if (count === 1) {
          return ONE_PERSON_TEXT;
        } else if (count > 1 && count < 5) {
          return TWO_TO_FOUR_PEOPLE_TEXT;
        } else {
          return MORE_THAN_FOUR_PEOPLE_TEXT;
        }
      };

      const numberOfPeopleTest = numberOfPeople
        ? `(${numberOfPeople} ${getSpellNumberOfPeople(numberOfPeople)})`
        : '';

      return {
        happeningNumberOfPeopleText: numberOfPeopleTest,
        happeningSpace: getSpaceMetadata(),
        happeningTitle: happeningMetaData,
        numberOfPeople: getNumberOfPeople(),
        ...calculateSummaryProps(state),
      };
    } else if (selectedCarnet && selectedCarnet.name) {
      const currentDate = moment().format('DD-MM-YYYY');

      return {
        carnet: true,
        happeningTitle: selectedCarnet.name,
        numberOfPeople: 0,
        price: selectedCarnet.price,
        selectedTimeRange: currentDate,
      };
    }
    return {
      happeningTitle: '',
      numberOfPeople: 0,
      price: 0,
      selectedTimeRange: '',
    };
  };

  return {
    ...getPropsBySummary(),
    calculateUpSellProps: calculateUpSellProps(state),
    calculatedPrice: getPrice(state),
    calculatedPricePerPerson: happening?.calculatePricePerPerson,
    isOverbookingAllowed: happening
      ? happening.allowNextSlotWhenOverbooked
      : false,
    isUpSellEnabled: isUpSellEnabled(state),
    selectedDay: getSelectedDayIncludeTimeSlot(state) || new Date(),
    selectedPeopleCount: getSelectedNumberOfPlayers(state),
    selectedProduct: getSelectedProducts(state),
    showBasket: getIframe(state).showBasket,
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>,
): ISummaryBoxFromDispatch => ({
  addToGlobalBasket: (items) => dispatch(addToGlobalBasket(items)),
  resetState: () => dispatch(resetState()),
});

export default connect<
  ISummaryBoxFromState,
  ISummaryBoxFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps,
)(SummaryBox);
