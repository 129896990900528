import React, { FC } from 'react';

import { Typography } from '@material-ui/core';

import {
  ITypographyGoingJPProps,
  TypographyGoingJPVariant,
} from './models/TypographyGoingJP';
import { useStyles } from './styles/TypographyGoingJP.styles';

export const TypographyGoingJP: FC<ITypographyGoingJPProps> = ({
  children,
  variant,
  typographyVariant,
}) => {
  const styles = useStyles();

  const getStyleBasedOnVariant = () => {
    switch (variant) {
      case TypographyGoingJPVariant.grayedOut:
        return styles.grayedOut;
      case TypographyGoingJPVariant.yellowBg:
        return styles.yellowBg;
      case TypographyGoingJPVariant.yellowBgDarkTheme:
        return styles.yellowBgDarkTheme;
      case TypographyGoingJPVariant.yellowUnderline:
        return styles.yellowUnderline;
      default:
        return styles.yellowUnderline;
    }
  };

  return (
    <Typography
      variant={typographyVariant || 'body1'}
      className={getStyleBasedOnVariant()}
    >
      {children}
    </Typography>
  );
};
