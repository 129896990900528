import { providers } from 'goingapp-shared';

import { IReservation } from '@Services/$booking-api/types';

export const getErrorMessageFromString = (
  errorMessage: string,
  path: string,
  reservations: IReservation[],
): providers.IGoingBagTypes.IBasketError | null => {
  const errors = path.split(']');

  const happeningIdError = errors.find((error) =>
    error.includes('happeningId'),
  );
  const spaceIdError = errors.find((error) => error.includes('spaceId'));
  const dateTimeError = errors.find((error) => error.includes('dateTime'));

  if (happeningIdError && spaceIdError && dateTimeError) {
    const spaceId = spaceIdError.split('[')[1] || '0';
    const happeningId = happeningIdError.split('[')[1] || '0';

    const reservationObject = reservations.find(
      (reservation) =>
        reservation.spaceId.toString() === spaceId &&
        reservation.happeningId.toString() === happeningId,
    );

    if (reservationObject) {
      return {
        dateTime: dateTimeError.split('[')[1] || '0',
        errorMessage,
        happeningId,
        spaceId,
        text: reservationObject.title,
      };
    }
  }

  return null;
};
