import cn from 'classnames';
import RcSlider from 'rc-slider';
import React from 'react';

import styles from './Slider.module.scss';
import { ISliderProps } from './Slider.types';

const Slider = ({
  className,
  maxValue,
  minValue,
  step,
  value,
  onChange,
}: ISliderProps) => {
  const y = -1;

  const up = () => {
    if (value < maxValue) {
      onChange(value + step);
    }
  };

  const down = () => {
    if (value > (minValue || 1)) {
      onChange(value - step);
    }
  };

  const handleOnChange = (x: number) => {
    onChange(x);
  };

  return (
    <div className={styles.mainWrapper}>
      <span className={styles.additionalButton} onClick={down}>
        -
      </span>
      <div className={styles.wrapper}>
        <div className={styles.container}>
          <RcSlider
            className={cn(className, styles.slider)}
            onChange={handleOnChange}
            value={value}
            min={minValue || step}
            max={maxValue}
            step={step}
          />
        </div>
        <div className={styles.value}>{value}</div>
      </div>
      <span className={styles.additionalButton} onClick={up}>
        +
      </span>
    </div>
  );
};

export default Slider;
