import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      width: '100%',
    },
    dialog: {
      height: '100%',
    },
    headerContent: {
      borderWidth: 0,
    },
    listItem: {
      justifyContent: 'space-between',
    },
  }),
);

export default useStyles;
