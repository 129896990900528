import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  actions: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
}));

export default useStyles;
