import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  grayedOut: {
    color: theme.palette.text.secondary,
  },
  yellowBg: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.text.primary,
  },
  yellowBgDarkTheme: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  yellowUnderline: {
    textDecoration: 'underline',
    textDecorationColor: theme.palette.primary.main,
  },
}));
