import config from '@Config';
import catchHttpError from '@Misc/helpers/api/catchHttpError';
import getData from '@Misc/helpers/api/getData';
import axios, { CancelTokenSource } from 'axios';
import { ICheckUpSellBody, ICheckUpSellResponse } from './types';

class UpSellApi {
  private static getCheckUpSellUrl(): string {
    return `${config.api.baseUrl}upsells/check`;
  }

  private cancelTokenCheckUpSell?: CancelTokenSource;

  public checkUpSell(body: ICheckUpSellBody): Promise<ICheckUpSellResponse> {
    return new Promise<ICheckUpSellResponse>((resolve, reject) => {
      this.cancelTokenCheckUpSell = axios.CancelToken.source();

      axios
        .post(UpSellApi.getCheckUpSellUrl(), body, {
          cancelToken: this.cancelTokenCheckUpSell.token,
        })
        .then(getData)
        .then((response: ICheckUpSellResponse) => {
          resolve(response);
        })
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public cancelCheckUpSell() {
    if (this.cancelTokenCheckUpSell) {
      this.cancelTokenCheckUpSell.cancel();
      this.cancelTokenCheckUpSell = undefined;
    }
  }
}

export default new UpSellApi();
