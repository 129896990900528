import React from 'react';

import CheckboxRound from '@Compo/forms/CheckboxRound';
import FormField from '@Compo/forms/FormField';
import Config from '@Config';
import { IFormLayoutProps } from '../ExtraFields.types';

import styles from './Styles.module.scss';

const I_HAVE_PASS = 'Mam karnet';
const CARNET_NUMBER_TEXT = 'numer karnetu';

const { isHangar, isSaltos } = Config.theme;

export const CarnetField = ({
  setFieldValue,
  values: { pass },
  isCarnet,
  touched,
  errors,
}: IFormLayoutProps) => {
  const isError = (fieldName: string): boolean =>
    // @ts-ignore
    touched[fieldName] && errors[fieldName] !== undefined;

  return (
    <div className={styles.carnetBox}>
      {pass && (
        <div className={styles.formRow}>
          <div className={styles.title}>{I_HAVE_PASS}</div>

          <label className={styles.label} htmlFor={'passCode'}>
            <div className={styles.labelText}>{CARNET_NUMBER_TEXT}</div>
            <div className={styles.input}>
              <FormField
                id={'passCode'}
                name={'passCode'}
                type="text"
                isError={isError('passCode')}
              />
            </div>
          </label>
        </div>
      )}

      {(isHangar || isSaltos) && !isCarnet && (
        <label className={styles.label} htmlFor="pass">
          <CheckboxRound
            checked={!!pass}
            // tslint:disable-next-line: jsx-no-lambda
            onChange={() => setFieldValue('pass', !pass)}
          >
            <span>{I_HAVE_PASS}</span>
          </CheckboxRound>
        </label>
      )}
    </div>
  );
};

export default CarnetField;
