import { providers } from 'goingapp-shared';

import { IAddErrorPayload, IError } from '@Model/errors/types';
import { createStandardAction } from 'typesafe-actions';
import { ADD, ADD_REQUEST, REMOVE } from '../constants/actions';

export const add = createStandardAction(ADD_REQUEST)<
  IError | providers.IGoingBagTypes.IBasketError
>();

export const addError = createStandardAction(ADD)<IAddErrorPayload>();

export const removeError = createStandardAction(REMOVE)<undefined>();
