import React, { useEffect } from 'react';

import { CssBaseline, ThemeProvider } from '@material-ui/core';

import theme from '@/config/theme/index';
import Routes from '@/routes';
import Error from '@Compo/Error';
import Toast from '@Compo/errors/Toast';
import Resizer from '@Compo/functional/Resizer';
import Analytics from '@Services/$analytics-provider';
import { BagStore } from '../Basket/ZagrywkiBasketProvider/ZagrywkiBasketProvider';
import { IAppProps } from './App.types';

import '@Misc/styles/global.module.scss';
import 'rc-slider/assets/index.css';

// TODO: rebuild app

const App = ({ selectedTheme }: IAppProps) => {
  useEffect(() => {
    Analytics.initializeApp();
  }, []);

  return (
    <>
      <ThemeProvider theme={theme(selectedTheme).theme}>
        <BagStore>
          {/* Components which have side-effects e.g.: connected with redux */}
          <CssBaseline />

          <Resizer />

          <Error />

          <Toast />

          {/* react-router v4 routing */}
          <Routes />
        </BagStore>
      </ThemeProvider>
    </>
  );
};

export default App;
