import { atom } from 'recoil';

import { ISuccessMessage } from './../types';

const successMessageState = atom<ISuccessMessage | null>({
  default: null,
  key: 'successMessageState',
});

export default successMessageState;
