import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    alert: {
      marginBottom: theme.spacing(3),
    },
    buttonsContainer: {
      paddingBottom: theme.spacing(5),
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
    },
    formio: {
      marginRight: 3,

      '&::-webkit-scrollbar': {
        WebkitAppearance: 'none',
        width: 7,
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgba(0,0,0,.5)',
        borderRadius: 4,
        boxShadow: '0 0 1px rgba(255,255,255,.5)',
      },
    },
  }),
);

export default useStyles;
