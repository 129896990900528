import React, { Suspense } from 'react';

import { Dialog, DialogTitle } from '@material-ui/core';
import { useRecoilValue } from 'recoil';

import Loading from '@Compo/layout/Loading';
import { translation } from '@Recoil/lang/selectors';
import EntryTerm from './EntryTerm';
import { IRegisterNewPersonModalProps } from './RegisterNewPersonModal.types';

const loading = <Loading />;

const RegisterNewPersonModal = ({
  open,
  close,
  openAddPersonAlert,
  openAlert,
  setOpenEmailModal,
}: IRegisterNewPersonModalProps) => {
  const { entryListL } = useRecoilValue(translation);

  return (
    <Dialog
      open={open}
      maxWidth="sm"
      fullWidth={true}
      onClose={close}
      scroll="paper"
    >
      <DialogTitle>{entryListL.registerNewPerson.title}</DialogTitle>
      <Suspense fallback={loading}>
        <EntryTerm
          closeModal={close}
          openAlert={openAlert}
          openAddPersonAlert={openAddPersonAlert}
          setOpenEmailModal={setOpenEmailModal}
        />
      </Suspense>
    </Dialog>
  );
};

export default RegisterNewPersonModal;
