import {
  getAnalytics,
  initializeAnalytics,
  logEvent,
} from 'firebase/analytics';
import { getApps, initializeApp } from 'firebase/app';

import { ILogEventDataType } from './types';

class Analytics {
  public initializeApp() {
    const apps = getApps();
    if (apps.length === 0) {
      const app = initializeApp({
        apiKey: 'AIzaSyAOY597svmmju2yYB1IzpUTkXeAIej-7t4',
        appId: '1:477030840903:web:da63b984ecfca979460f62',
        authDomain: 'going-forms.firebaseapp.com',
        measurementId: 'G-P8SRFKGFYP',
        messagingSenderId: '477030840903',
        projectId: 'going-forms',
        storageBucket: 'going-forms.appspot.com',
      });
      const analytics = initializeAnalytics(app, {
        config: { cookie_domain: 'none', cookie_flags: 'secure;samesite=none' },
      });
    }
  }

  public logEvent(event: string, params?: ILogEventDataType) {
    const apps = getApps();
    if (apps.length > 0) {
      const analytics = getAnalytics();
      if (analytics) {
        logEvent(analytics, event, params);
      }
    }
  }
}

export default new Analytics();
