import { connect } from 'react-redux';

import { getTransactionDetails } from '@Model/reservation/selectors';
import _Store from '@Store';

import ItemsView from './ItemsView.component';
import { IItemsViewFromState } from './ItemsView.types';

const mapStateToProps = (state: _Store.IState): IItemsViewFromState => {
  const transactionDetails = getTransactionDetails(state);

  return {
    transactionItems: transactionDetails.transactionItems,
  };
};

export default connect<IItemsViewFromState, {}, {}, _Store.IState>(
  mapStateToProps,
)(ItemsView);
