import { setTerms } from '@Model/reservation/actions';
import getAdditionalTerms from '@Model/reservation/selectors/getAdditionalTerms';
import _Store from '@Store';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';
import Terms from './Terms.component';
import { ITermsFromDispatch, ITermsFromState } from './Terms.types';

const mapStateToProps = (state: _Store.IState): ITermsFromState => {
  return {
    additionalTerms: getAdditionalTerms(state),
    selected: false,
    selectedNewsletterTerms: false,
    selectedPrivacyPolicy: false,
    selectedTimeTerms: false,
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>,
): ITermsFromDispatch => ({
  setTerms: (id: number) => dispatch(setTerms(id)),
});

export default connect<ITermsFromState, ITermsFromDispatch, {}, _Store.IState>(
  mapStateToProps,
  mapDispatchToProps,
)(Terms);
