import { goBack, push } from 'connected-react-router';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import routes from '@/routes/routes';
import _Store from '@Store';
import BackButton from './BackButton.component';
import { IBackButtonFromDispatch } from './BackButton.types';

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>,
): IBackButtonFromDispatch => ({
  goBack: () => dispatch(goBack()),
  goToIndex: () => dispatch(push(routes.index)),
});

export default connect<void, IBackButtonFromDispatch, {}, _Store.IState>(
  null,
  mapDispatchToProps,
)(BackButton);
