import { getSearch, push } from 'connected-react-router';
import normalizeUrl from 'normalize-url';
import queryString from 'query-string';
import { promiseSetRecoil } from 'recoil-outside';
import { EMPTY, of as of$ } from 'rxjs';
import {
  filter as filter$,
  mergeMap as mergeMap$,
  withLatestFrom as withLatestFrom$,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import routes from '@/routes/routes';
import { analytics } from '@Misc/helpers/analytics/analytics';
import oblateParentUrl from '@Misc/helpers/api/makeParentUrl';
import makeUrlForPaymentFromRouteString from '@Misc/helpers/api/makeUrlForPaymentFromRouteString';
import checkIsAllowedAction from '@Misc/helpers/checkIsAllowedAction';
import { setInnerSizes } from '@Model/app/actions';
import {
  addToGlobalBasket,
  captureIframeEvent,
  mounted,
  redirectParentTo,
  redirectToTryAgain,
  refreshPage,
  runFbqAction,
  scrollIframeTo,
  scrollIframeToTop,
  setAddBasketRedirect,
  setBasket,
  setEmbedBasket,
  setEnterFromUrl,
  setExtendedPayment,
  setExternalFormUrl,
  setParentUrl,
  setPartnerId,
  setPassCode,
  setRoom,
  setRoomUrl,
  setTemplate,
  setTheme,
  startMounted,
} from '@Model/iframe/actions';
import { get as getIframe } from '@Model/iframe/selectors';
import { IAction as IIframeActions } from '@Model/iframe/types';
import { summaryMounted } from '@Model/summaries/actions';
import { sendTransaction } from '@Model/transaction/actions';
import { iframeParams } from '@Recoil/formio/atoms';
import _Store from '@Store';
import { allowedActions } from '../constants/actions';
import { ISearch } from '../types';

export const setStartStateWhenAppMounted: _Store.IEpic = (
  action$,
  state$,
  { linksProvider },
) => {
  return action$.pipe(
    filter$(isActionOf(mounted)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const search: ISearch = queryString.parse(getSearch(state));

      const parentUrl = search.parentUrl || '';
      const partnerId = search.partnerId || null;
      const enterFromUrl = search.enterFromUrl || null;
      const externalFormUrl = search.externalFormUrl || null;
      const passCode = search.passCode || null;
      const template = search.template || null;
      const extendedPayment = search.extendedPayment || null;
      const googleGtmKey = search.googleGtmKey || null;
      const facebookPixelKey = search.facebookPixelKey || null;
      const showBasket = search.showBasket || false;
      const embedBasket = search.embedBasket || false;
      const redirectAddBasket = search.addBasketRedirect || null;
      const ticketCode = search.ticketCode || null;
      const transactionHash = search.transactionHash || null;
      const theme = search.theme || null;
      const formIoId = search.formIoId || null;
      const entryListUrl = search.entryListUrl || null;

      promiseSetRecoil(iframeParams, {
        entryListUrl,
        formIoId,
        ticketCode,
        transactionHash,
      });

      const getRoomUrl = (): string => {
        if (search.roomId) {
          const { roomId } = search;

          return linksProvider.buildHappeningLink(roomId);
        }
        return '';
      };

      const roomUrl = getRoomUrl();

      const getRedirectUrl = (): string => {
        if (search.transactionId && typeof search.transactionId === 'string') {
          const { transactionId } = search;

          if (
            search.error &&
            (search.error === '500' || search.error === '501')
          ) {
            return linksProvider.buildSummaryFailLink(transactionId);
          }

          return linksProvider.buildSummaryLink(transactionId);
        } else if (
          search.enterFromUrl &&
          typeof search.enterFromUrl === 'string'
        ) {
          if (search.enterFromUrl === 'newEnterForm') {
            return routes.newTermsHangar;
          }
          return routes.termsHangar;
        } else if (
          search.externalFormUrl &&
          typeof search.externalFormUrl === 'string'
        ) {
          return routes.externalForm;
        } else if (search.carnets) {
          return routes.carnets;
        } else if (extendedPayment) {
          return routes.extendedPayment;
        } else if (!!embedBasket) {
          return routes.reservation;
        } else if (roomUrl && roomUrl.length) {
          return roomUrl;
        }

        return routes.index;
      };

      const url = getRedirectUrl();

      analytics({ facebookPixelKey, googleGtmKey });

      return [
        push(url),
        setParentUrl(parentUrl),
        setExternalFormUrl(externalFormUrl),
        setPartnerId(partnerId),
        setRoomUrl(`/${roomUrl}`),
        setEnterFromUrl(enterFromUrl),
        setTemplate(template),
        setExtendedPayment(extendedPayment),
        setPassCode(passCode),
        startMounted(),
        setBasket(!!showBasket),
        setEmbedBasket(!!embedBasket),
        setAddBasketRedirect(redirectAddBasket),
        setTheme(theme),
      ];
    }),
  );
};

export const redirectToTryAgainWhenAction: _Store.IEpic = (
  action$,
  state$,
  { iframeProvider, linksProvider },
) => {
  return action$.pipe(
    filter$(isActionOf(redirectToTryAgain)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      let { parentUrl } = getIframe(state);
      const { removeURLParameter } = linksProvider;
      parentUrl = oblateParentUrl(parentUrl);

      let redirectUrl = makeUrlForPaymentFromRouteString(
        normalizeUrl(parentUrl),
      );

      redirectUrl = removeURLParameter(redirectUrl, 'transactionId');
      redirectUrl = removeURLParameter(redirectUrl, 'error');

      iframeProvider.runRedirectParentMethod(redirectUrl);

      return EMPTY;
    }),
  );
};

export const redirectParentToWhenAction: _Store.IEpic = (
  action$,
  state$,
  { iframeProvider },
) => {
  return action$.pipe(
    filter$(isActionOf(redirectParentTo)),
    mergeMap$((action) => {
      iframeProvider.runRedirectParentMethod(action.payload);

      return EMPTY;
    }),
  );
};

export const pingToApiWhenMounted: _Store.IEpic = (
  action$,
  state$,
  { iframeProvider },
) => {
  return action$.pipe(
    filter$(isActionOf(mounted)),
    mergeMap$(() => {
      iframeProvider.pingMethod();

      return EMPTY;
    }),
  );
};

export const runResizeMethodWhenSetDevice: _Store.IEpic = (
  action$,
  state$,
  { iframeProvider },
) => {
  return action$.pipe(
    filter$(isActionOf(setInnerSizes)),
    mergeMap$((action) => {
      const { height } = action.payload;

      iframeProvider.runResizeMethod(height + 'px');

      return EMPTY;
    }),
  );
};

export const setRoomWhenRequest: _Store.IEpic = (
  action$,
  state$,
  { linksProvider },
) => {
  return action$.pipe(
    filter$(isActionOf(setRoom)),
    mergeMap$((action) => {
      return of$(push(linksProvider.buildHappeningLink(action.payload)));
    }),
  );
};

export const captureIframeEventWhenPostMessage: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf(captureIframeEvent)),
    mergeMap$((action) => {
      const { type } = action.payload;
      const { payload } = action.payload;
      const isAllowedAction = checkIsAllowedAction(type, allowedActions);

      if (isAllowedAction) {
        return of$({ type, payload } as IIframeActions);
      }
      return EMPTY;
    }),
  );
};

export const scrollToTopIframeWhenSummaryMounted: _Store.IEpic = (
  action$,
  state$,
  { iframeProvider },
) => {
  return action$.pipe(
    filter$(isActionOf(summaryMounted)),
    mergeMap$((action) => {
      const { runScrollTopMethod } = iframeProvider;

      runScrollTopMethod();
      return EMPTY;
    }),
  );
};

export const scrollToTopIframeWhenActions: _Store.IEpic = (
  action$,
  state$,
  { iframeProvider },
) => {
  return action$.pipe(
    filter$(isActionOf(scrollIframeToTop)),
    mergeMap$(() => {
      const { runScrollTopMethodWithOutAsync } = iframeProvider;

      runScrollTopMethodWithOutAsync();
      return EMPTY;
    }),
  );
};

export const scrollIframeToIframeWhenActions: _Store.IEpic = (
  action$,
  state$,
  { iframeProvider },
) => {
  return action$.pipe(
    filter$(isActionOf(scrollIframeTo)),
    mergeMap$((action) => {
      const { runScrollIframeToMethod } = iframeProvider;

      runScrollIframeToMethod(action.payload);
      return EMPTY;
    }),
  );
};

export const refreshPageWhenAction: _Store.IEpic = (
  action$,
  state$,
  { iframeProvider },
) => {
  return action$.pipe(
    filter$(isActionOf(refreshPage)),
    mergeMap$(() => {
      const { refreshPageMethod } = iframeProvider;

      refreshPageMethod();
      return EMPTY;
    }),
  );
};

export const runFnqActionWhenAction: _Store.IEpic = (
  action$,
  state$,
  { iframeProvider },
) => {
  return action$.pipe(
    filter$(isActionOf(runFbqAction)),
    mergeMap$((action) => {
      const { runFbqMethod } = iframeProvider;

      runFbqMethod(action.payload);
      return EMPTY;
    }),
  );
};

export const setGlobalAction: _Store.IEpic = (
  action$,
  state$,
  { iframeProvider },
) => {
  return action$.pipe(
    filter$(isActionOf(addToGlobalBasket)),
    mergeMap$((action) => {
      const { setGlobalBasket } = iframeProvider;

      setGlobalBasket(action.payload);
      return EMPTY;
    }),
  );
};
export const setGlobalActionEmpty: _Store.IEpic = (
  action$,
  state$,
  { iframeProvider },
) => {
  return action$.pipe(
    filter$(isActionOf(sendTransaction.success)),
    mergeMap$((action) => {
      const { setGlobalBasket } = iframeProvider;

      setGlobalBasket([]);
      return EMPTY;
    }),
  );
};
