import { createSelector } from 'reselect';

import getSpaces from './getSpaces';
import getValues from './getValues';

const getSelectedSpaceIsScopeView = createSelector(
  [getValues, getSpaces],
  (selected, spaces) => {
    const selectedSpace =
      spaces.find((space) => selected.space === space.id) || spaces[0];
    if (selectedSpace) {
      return selectedSpace.isScopeView;
    }
    return false;
  },
);

export default getSelectedSpaceIsScopeView;
