import React from 'react';

import format from 'date-fns/format';
import moment from 'moment';

import capitalize from '@Misc/helpers/strings/capitalise';
import { ITransactionItemsSortedByDate } from '@Model/extendedPayment/types/index';
import pl from 'date-fns/locale/pl';
import { ISummaryOnwProps } from './Summary.types';

import styles from './Summary.module.scss';

const LOCAL_CURRENCY = ' zł';
const LOCAL_CURRENCY_SECOND = ' PLN';
const ONE_PERSON_TEXT = 'osoba';
const TWO_TO_FOUR_PEOPLE_TEXT = 'osoby';
const MORE_THAN_FOUR_PEOPLE_TEXT = 'osób';
const SUMMARY_TEXT = 'Podsumowanie';
const SUMMARY_PAY_TEXT = 'Do zaplaty';
const PRODUCTS_QUANTITY = 'Ilość:';

const Summary = ({ extendedPayment }: ISummaryOnwProps) => {
  const { transactionItems, transactionProducts, revenue } = extendedPayment;
  if (!transactionItems || !transactionItems.length) {
    return null;
  }

  const getSortedItems = (): ITransactionItemsSortedByDate[] => {
    const sortedItemsByDate: ITransactionItemsSortedByDate[] = [];
    transactionItems.forEach((item) => {
      const date = moment(item.startDate || new Date()).format('DD.MM.YYYY');
      const objectKey = sortedItemsByDate.findIndex(
        (sortedItem) => sortedItem.date === date,
      );

      if (objectKey !== -1) {
        sortedItemsByDate[objectKey].items.push(item);
      } else {
        const newObject: ITransactionItemsSortedByDate = {
          date,
          items: [item],
        };
        sortedItemsByDate.push(newObject);
      }
    });

    return sortedItemsByDate.sort((a, b) => {
      return new Date(b.date).getTime() - new Date(a.date).getTime();
    });
  };

  const getSelectedDayString = (day: string | null) => {
    if (day) {
      const date = moment(day, 'DD.MM.YYYY').toDate();
      return capitalize(format(date, 'EEEE dd.MM', { locale: pl }));
    }
  };

  const sortedItems = getSortedItems();

  const getAdditionalPersonInfo = (count: number): string => {
    if (count) {
      if (count === 1) {
        return ONE_PERSON_TEXT;
      } else if (count > 1 && count < 5) {
        return TWO_TO_FOUR_PEOPLE_TEXT;
      } else {
        return MORE_THAN_FOUR_PEOPLE_TEXT;
      }
    }
    return '';
  };

  return (
    <>
      <section className={styles.summary}>
        <div className={styles.title}>{SUMMARY_TEXT}</div>
        <div className={styles.summaryWrapper}>
          {sortedItems.map(({ date, items: currentItems }) => (
            <section key={date} className={styles.dayWrapper}>
              <div className={styles.day}>{getSelectedDayString(date)}</div>

              {currentItems.map(
                ({
                  uuid,
                  productName,
                  startDate,
                  endDate,
                  price,
                  quantity,
                }) => (
                  <div className={styles.reservation} key={uuid}>
                    <div className={styles.reservationTitle}>
                      <div className={styles.mainTitle}>
                        {productName} ({quantity}{' '}
                        {getAdditionalPersonInfo(quantity)})
                      </div>
                      <div className={styles.time}>
                        {moment(startDate).format('HH:mm')}-
                        {moment(endDate).format('HH:mm')}
                      </div>
                    </div>
                    <div className={styles.price}>
                      {price}
                      {LOCAL_CURRENCY}
                    </div>
                  </div>
                ),
              )}
            </section>
          ))}
          {transactionProducts.map((product) => (
            <section key={product.productName} className={styles.dayWrapper}>
              <div className={styles.reservation}>
                <div className={styles.reservationTitle}>
                  <div className={styles.mainTitle}>{product.productName}</div>
                  <div className={styles.time}>
                    {PRODUCTS_QUANTITY} {product.quantity}
                  </div>
                </div>
                <div className={styles.price}>
                  {product.price.toFixed(2)}
                  {LOCAL_CURRENCY}
                </div>
              </div>
            </section>
          ))}
        </div>
        <div className={styles.completePrice}>
          <div className={styles.completePriceTitle}>{SUMMARY_PAY_TEXT}</div>
          <div className={styles.price}>
            {revenue.toFixed(2)} {LOCAL_CURRENCY_SECOND}
          </div>
        </div>
      </section>
    </>
  );
};

export default Summary;
