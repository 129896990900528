import { DESKTOP } from '@Model/app/constants/constants';
import { createSelector } from 'reselect';
import getDevice from './getDevice';

const isDesktop = createSelector(
  [getDevice],
  (device) => device === DESKTOP,
);

export default isDesktop;
