import { atom } from 'recoil';

import { IFormIoData } from './../types';

const formioData = atom<IFormIoData>({
  default: { reservationCode: '', peopleCount: '' },
  key: 'formioData',
});

export default formioData;
