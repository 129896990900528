import React from 'react';

import { Grid } from '@material-ui/core';
import TicketTypeSingle from './../TicketTypeSingle';
import { ITicketTypePickerProps } from './TicketTypePicker.types';

const TicketTypePicker = ({
  availablePriceTypes,
  selectedPrices,
  setPeopleCount,
}: ITicketTypePickerProps) => {
  const showAdditionalInfo = availablePriceTypes.length > 1;

  return (
    <Grid container={true} spacing={4}>
      {availablePriceTypes.map((priceType, index) => (
        <Grid item={true} xs={12} key={index}>
          <TicketTypeSingle
            showAdditionalInfo={showAdditionalInfo}
            selectPriceType={priceType}
            values={selectedPrices}
            availablePriceTypes={availablePriceTypes}
            setPeopleCount={setPeopleCount}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default TicketTypePicker;
