import { redirectToTryAgain } from '@Model/iframe/actions';
import {
  getTransactionBookingIdentifier,
  getTransactionDetails,
} from '@Model/reservation/selectors';
import { MODULE_TRANSACTION_DETAILS } from '@Model/state/constants/constants';
import { getLoading } from '@Model/state/selectors';
import { summaryMounted } from '@Model/summaries/actions';
import _Store from '@Store';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';
import Summary from './Summary.component';
import { ISummaryFromDispatch, ISummaryFromState } from './Summary.types';

const mapStateToProps = (state: _Store.IState): ISummaryFromState => {
  const transactionDetails = getTransactionDetails(state);

  return {
    bookingIdentifier: getTransactionBookingIdentifier(state),
    isLoading: getLoading(MODULE_TRANSACTION_DETAILS)(state),
    transactionItems: transactionDetails.transactionItems,
    transactionProducts: transactionDetails.transactionProducts,
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>,
): ISummaryFromDispatch => ({
  mounted: () => dispatch(summaryMounted()),
  redirectToTryAgain: () => dispatch(redirectToTryAgain()),
});

export default connect<
  ISummaryFromState,
  ISummaryFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps,
)(Summary);
