import { setUpSell } from '@Model/reservation/actions';
import { getType } from 'typesafe-actions';
import { IAction, IReservationUpSell } from './../types';

const initialState: IReservationUpSell = false;

const upSellReducer = (
  state: IReservationUpSell = initialState,
  action: IAction,
): IReservationUpSell => {
  switch (action.type) {
    case getType(setUpSell):
      return action.payload;

    default:
      return state;
  }
};

export default upSellReducer;
