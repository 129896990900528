import { createSelector } from 'reselect';

import { get as getHappening, getSpaces } from '@Model/happening/selectors';
import getAvailabilities from './getAvailabilities';
import getGroupedTimeSlots from './getGroupedTimeSlots';
import getValues from './getValues';

const calculateAvailableSpaces = createSelector(
  [getAvailabilities, getValues, getSpaces, getHappening, getGroupedTimeSlots],
  (availabilities, values, spaces, happening, groupedSlots) => {
    const { slot, day } = values;

    if (!(slot && day && availabilities && spaces)) {
      return [];
    }

    const { currentDay, otherDays } = availabilities;

    let availability = currentDay[slot];

    if (otherDays && otherDays[slot]) {
      availability = otherDays[slot];
    }

    if (!availability) {
      return [];
    }

    const allowNextSlotWhenOverbooked =
      happening && happening.allowNextSlotWhenOverbooked
        ? happening.allowNextSlotWhenOverbooked
        : false;

    return spaces.map((_space) => {
      const slotBlock = availability.find(
        (_availability) => _availability.spaceId === _space.id,
      );

      let isDisable = true;

      if (slotBlock) {
        isDisable = !slotBlock.available;
      }

      if (
        values &&
        values.numberOfPlayers &&
        !isDisable &&
        !allowNextSlotWhenOverbooked
      ) {
        isDisable = _space.maxNumberOfPeople < values.numberOfPlayers;
      }

      if (values && values.numberOfPlayers && allowNextSlotWhenOverbooked) {
        const selectedGroupSlots = groupedSlots.find(
          (groupedSlot) => groupedSlot.hour === slot.split(':')[0] + ':00',
        );

        selectedGroupSlots?.slots.forEach((value) => {
          const index = selectedGroupSlots?.slots.findIndex(
            (v) => v.start === slot,
          );
          if (index) {
            const previousSlot = selectedGroupSlots?.slots[
              index - 1
            ].spaces.find((v) => v.spaceId === _space.id);
            const currentSlot = value.spaces.find(
              (v) => v.spaceId === _space.id,
            );
            if (previousSlot?.available && currentSlot?.available) {
              isDisable = true;
            }
          }
        });
      }

      return {
        ..._space,
        isDisable,
      };
    });
  },
);

export default calculateAvailableSpaces;
