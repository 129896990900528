interface IMetadata {
  language: ILanguage;
}

const DEFAULT_LANGUAGE = 'pl';

const getMetadataForLanguage = <T extends IMetadata>(
  metadata: T[] = [],
  language = DEFAULT_LANGUAGE,
): T => {
  return (
    (metadata.find((meta) => meta.language.toLowerCase() === language) as T) ||
    {}
  );
};

export default getMetadataForLanguage;
