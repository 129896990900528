import _Store from '@Store';
import { createSelector } from 'reselect';
import { IPriceCheckReducer } from './../types';
import get from './get';

const isPriceCheckIncorrect = createSelector<
  _Store.IState,
  IPriceCheckReducer,
  boolean
>(
  [get],
  (priceCheck) =>
    (priceCheck && priceCheck.message !== null && priceCheck.value === null) ||
    false,
);

export default isPriceCheckIncorrect;
