import React, { useState } from 'react';

import CheckboxRound from '@Compo/forms/CheckboxRound';
import FormField from '@Compo/forms/FormField';
import Loading from '@Compo/layout/Loading';
import Config from '@Config';
import { IFormLayoutProps } from '../ExtraFields.types';

import styles from './Styles.module.scss';

const TITLE_TEXT = 'Chcę fakturę';
const NIP_NUMBER_TEXT = 'NIP';
const COMPANY_NAME_TEXT = 'Nazwa firmy (imię i nazwisko)';
const STREET_TEXT = 'Ulica';
const HOUSE_NUMBER_TEXT = 'Numer';
const ZIP_CODE_TEXT = 'Kod pocztowy';
const CITY_TEXT = 'Miasto';
const DATE_FOR_FACTURE_TEXT = 'Dane do faktury';

const { isHangar, isSaltos } = Config.theme;

export const InvoiceField = ({
  setFieldValue,
  values: { facture },
  values,
  isCarnet,
  touched,
  errors,
  getCompanyData,
  setFormData,
  isInvoiceLoading,
}: IFormLayoutProps) => {
  const isError = (fieldName: string): boolean =>
    // @ts-ignore
    touched[fieldName] && errors[fieldName] !== undefined;
  const [timeOut, setTimeOut] = useState<NodeJS.Timeout | null>(null);

  const setNipValue = (e: React.FormEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;

    setFieldValue('nip', value);

    if (timeOut) {
      clearTimeout(timeOut);
    }

    setTimeOut(
      setTimeout(() => {
        getCompanyData(value);
        setFormData({ ...values, nip: value });
      }, 1000),
    );
  };

  const renderFormField = (fieldName: string, text: string) => (
    <label className={styles.label} htmlFor={fieldName}>
      {text}
      <FormField
        id={fieldName}
        name={fieldName}
        type="text"
        isError={isError(fieldName)}
      />
    </label>
  );

  const renderNipField = (fieldName: string, text: string) => (
    <label className={styles.label} htmlFor={fieldName}>
      {text}
      <FormField
        id={fieldName}
        name={fieldName}
        type="text"
        isError={isError(fieldName)}
        onChange={setNipValue}
      />
    </label>
  );

  return (
    <div className={styles.carnetBox}>
      {facture && (
        <>
          <div className={styles.formRow}>
            <h3 className={styles.factureTitle}>{DATE_FOR_FACTURE_TEXT}</h3>
          </div>
          {isInvoiceLoading && <Loading />}
          <div className={styles.doubleFormRow}>
            {renderNipField('nip', NIP_NUMBER_TEXT)}
            {renderFormField('companyName', COMPANY_NAME_TEXT)}
          </div>

          <>
            <div className={styles.doubleFormRow}>
              {renderFormField('street', STREET_TEXT)}
              {renderFormField('houseNumber', HOUSE_NUMBER_TEXT)}
            </div>
            <div className={styles.doubleFormRow}>
              {renderFormField('zipCode', ZIP_CODE_TEXT)}
              {renderFormField('city', CITY_TEXT)}
            </div>
          </>
        </>
      )}

      {(isHangar || isSaltos) && !isCarnet && (
        <label className={styles.label} htmlFor="facture">
          <CheckboxRound
            checked={!!facture}
            // tslint:disable-next-line: jsx-no-lambda
            onChange={() => setFieldValue('facture', !facture)}
          >
            <span>{TITLE_TEXT}</span>
          </CheckboxRound>
        </label>
      )}
    </div>
  );
};

export default InvoiceField;
