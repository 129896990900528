export const DEFAULT_PHONE_NUMBER = '+48111111111';

export const sendSms = (
  smsGateWay: string,
  phoneNumber: string,
  entryCode: number,
  smsText: string
) => {
  const data = {
    message: `KOD: ${entryCode} ${smsText}`,
    receiver: phoneNumber,
  };
  if (phoneNumber !== DEFAULT_PHONE_NUMBER) {
    fetch(smsGateWay, {
      body: JSON.stringify(data),
      headers: {
        'content-type': 'application/json',
      },
      method: 'POST',
      mode: 'no-cors',
    }).then((response) => {
      return response;
    });
  }
};
