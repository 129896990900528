const colors = {
  actions: {
    background: 'rgba(0, 0, 0, 0.08);',
    disabled: 'rgba(0,0,0,0.12);',
    disabledBackground:
      'linear-gradient(0deg, rgba(0, 0, 0, 0.12), rgba(0, 0, 0, 0.12))',
    disabledText: 'rgba(0, 0, 0, 0.26);',
    main: '#E0E0E0',
  },
  other: {
    activeRating: '#FFB400',
    backdrop: 'rgba(0, 0, 0, 0.5);',
    divider: '#E0E0E0',
    snackbar: '#323232',
    stroke: '#111111',
    white: '#ffffff',
  },
  primary: {
    background:
      'linear-gradient(180deg, rgba(255, 0, 206, 0.08) 0%, rgba(254, 15, 0, 0.08) 51.04%, rgba(254, 123, 0, 0.08) 100%);',
    border: '#111111',
    contrastText: 'rgba(0, 0, 0, 0.87);',
    dark: 'linear-gradient(180deg, #C7009D 0%, #C10000 45.83%, #C44C00 100%);',
    light: 'linear-gradient(180deg, #FF60FF 0%, #FF5D36 48.96%, #FFAC42 100%);',
    main: 'linear-gradient(90deg, #FE7B00 0%, #FE0F00 50.52%, #FF00CE 100%);',
  },
  secondary: {
    background: 'rgba(255, 80, 95, 0.08);',
    border: 'rgba(255, 80, 95, 0.5);',
    contrastText: '#ffffff',
    dark: '#000000',
    light: '#373737',
    main: '#111111',
  },
  text: {
    disabled: 'rgba(0, 0, 0, 0.38);',
    hint: 'rgba(0, 0, 0, 0.38);',
    primary: 'rgba(0, 0, 0, 0.87);',
    secondary: 'rgba(0, 0, 0, 0.54);',
  },
};

export default colors;
