import { atom } from 'recoil';

import { ILanguage } from './../types';

const selectedLang = atom<ILanguage>({
  default: 'pl',
  key: 'selectedLang',
});

export default selectedLang;
