import { combineReducers } from 'redux';

import deviceReducer from './device';
import sizesReducer from './sizes';

const reducer = combineReducers({
  device: deviceReducer,
  sizes: sizesReducer,
});

export default reducer;
