import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';
import history from './history';

// App reducers
import appReducer from '@Model/app/reducer';
import bookingReducer from '@Model/booking/reducer';
import errorsReducer from '@Model/errors/reducer';
import extendedPaymentReducer from '@Model/extendedPayment/reducer';
import happeningReducer from '@Model/happening/reducer';
import happeningsReducer from '@Model/happenings/reducer';
import iframeReducer from '@Model/iframe/reducer';
import priceReducer from '@Model/price/reducer';
import productsReducer from '@Model/products/reducer';
import reservationReducer from '@Model/reservation/reducer';
import stateReducer from '@Model/state/reducer';

const rootReducer = combineReducers({
  // Router
  router: connectRouter(history),

  // App reducers
  app: appReducer,
  booking: bookingReducer,
  errors: errorsReducer,
  extendedPayment: extendedPaymentReducer,
  happening: happeningReducer,
  happenings: happeningsReducer,
  iframe: iframeReducer,
  price: priceReducer,
  products: productsReducer,
  reservation: reservationReducer,
  state: stateReducer,
});

export default rootReducer;
