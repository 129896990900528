import config from '@Config';
import catchHttpError from '@Misc/helpers/api/catchHttpError';
import getData from '@Misc/helpers/api/getData';
import { IHappenings } from '@Model/happenings/types';
import axios, { CancelTokenSource } from 'axios';
import { IHappeningResponse, IHappeningsResponse } from './types';

class HappeningsApi {
  private static getHappeningsUrl(partnerId: string | null): string {
    if (partnerId) {
      return `${config.api.baseUrl}happenings?partnerIds[]=${partnerId}`;
    }
    return `${config.api.baseUrl}happenings`;
  }

  private static getHappeningUrl(slug: string): string {
    return `${config.api.baseUrl}happenings/${slug}`;
  }

  private cancelTokenHappening?: CancelTokenSource;

  private cancelTokenHappenings?: CancelTokenSource;

  public getHappenings<T extends IHappeningsResponse>(
    partnerId: string | null,
  ): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      this.cancelTokenHappenings = axios.CancelToken.source();

      axios
        .get(HappeningsApi.getHappeningsUrl(partnerId), {
          cancelToken: this.cancelTokenHappenings.token,
        })
        .then(getData)
        .then((response: T) => {
          resolve(response);
        })
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public normalizeHappenings(response: IHappeningsResponse): IHappenings {
    return {
      items: response.items,
      totalCount: response.totalCount,
    };
  }

  public cancelHappenings() {
    if (this.cancelTokenHappenings) {
      this.cancelTokenHappenings.cancel();
      this.cancelTokenHappenings = undefined;
    }
  }

  public getHappening<T extends IHappeningResponse>(slug: string): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      this.cancelTokenHappening = axios.CancelToken.source();

      axios
        .get(HappeningsApi.getHappeningUrl(slug), {
          cancelToken: this.cancelTokenHappening.token,
        })
        .then(getData)
        .then((response: T) => {
          resolve(response);
        })
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public cancelSingleHappening() {
    if (this.cancelTokenHappening) {
      this.cancelTokenHappening.cancel();
      this.cancelTokenHappening = undefined;
    }
  }
}

export default new HappeningsApi();
