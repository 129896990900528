import axios, { CancelTokenSource } from 'axios';

import config from '@Config';
import catchHttpError from '@Misc/helpers/api/catchHttpError';
import getData from '@Misc/helpers/api/getData';
import {
  ITransactionDetails,
  ITransactionPaymentSuccess,
} from '@Model/extendedPayment/types';
import { IUpdatedTransactionDetails } from './types';

class ExtendedPayment {
  private static getExtendedPaymentUrl(uuid: string): string {
    return `${config.api.baseEmpikTicketUrl}transaction-details/${uuid}`;
  }
  private static getPayUrl(uuid: string): string {
    return `${config.api.baseEmpikTicketUrl}transaction-payment/${uuid}`;
  }
  private static getUpdatedInfoAboutTransactionUrl(uuid: string): string {
    return `${config.api.baseUrl}transactions/${uuid}`;
  }

  private cancelTokenExtendedPayment?: CancelTokenSource;

  public getExtendedPayment<T extends ITransactionDetails>(
    uuid: string,
  ): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      this.cancelTokenExtendedPayment = axios.CancelToken.source();

      axios
        .get(ExtendedPayment.getExtendedPaymentUrl(uuid), {
          cancelToken: this.cancelTokenExtendedPayment.token,
        })
        .then(getData)
        .then((response: T) => {
          resolve(response);
        })
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public getUpdatedExtendedPayment(
    uuid: string,
  ): Promise<IUpdatedTransactionDetails> {
    return new Promise<IUpdatedTransactionDetails>((resolve, reject) => {
      this.cancelTokenExtendedPayment = axios.CancelToken.source();

      axios
        .get(ExtendedPayment.getUpdatedInfoAboutTransactionUrl(uuid), {
          cancelToken: this.cancelTokenExtendedPayment.token,
        })
        .then(getData)
        .then((response: IUpdatedTransactionDetails) => {
          resolve(response);
        })
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public pay<T extends ITransactionPaymentSuccess>(uuid: string): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      this.cancelTokenExtendedPayment = axios.CancelToken.source();

      axios
        .get(ExtendedPayment.getPayUrl(uuid), {
          cancelToken: this.cancelTokenExtendedPayment.token,
        })
        .then(getData)
        .then((response: T) => {
          resolve(response);
        })
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public cancelExtendedPayment() {
    if (this.cancelTokenExtendedPayment) {
      this.cancelTokenExtendedPayment.cancel();
      this.cancelTokenExtendedPayment = undefined;
    }
  }
}

export default new ExtendedPayment();
