import { connect } from 'react-redux';

import { getTheme } from '@Model/iframe/selectors';
import { _Store } from '@Store';
import App from './App.component';
import { IAppFromState } from './App.types';

const mapStateToProps = (state: _Store.IState): IAppFromState => ({
  selectedTheme: getTheme(state),
});

export default connect<IAppFromState, {}, {}, _Store.IState>(mapStateToProps)(
  App,
);
