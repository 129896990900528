import { selectorFamily } from 'recoil';

import FormIoApi from '@Services/$formio-api';

const getSaveUserData = selectorFamily({
  get: (userData: any) => async () => {
    try {
      return await FormIoApi.saveUserData(userData.url, userData.data);
    } catch {}
  },
  key: 'getSaveUserData',
});

export default getSaveUserData;
