import React, { useContext } from 'react';

import cn from 'classnames';

import Config from '@Config';
import { priceFormatter } from '@Misc/helpers';
import Products from './components/Products';
import { IUpSellProps } from './UpSell.types';

import { IZagrywkiBasket } from '@Model/basket/types';
import { clearContext } from '../Basket/ZagrywkiBasketProvider/ZagrywkiBasketProvider';

import styles from './UpSell.module.scss';

const ADD_TEXT = 'Dodaj';
const ADD_SMALL_TEXT = '+';
const REMOVE_TEXT = 'Usuń';
const REMOVE_SMALL_TEXT = '-';
const ENLARGE_TEXT = 'Wydłuż grę';
const CONJUNCTION_TEXT = ' o ';
const MINUTES_TEXT = 'minut';
const YOUR_PLAY_WILL_LAST_TILL_TEXT = 'Wasza zabawa będzie trwać do';
const ADD_NEXT_HOUR_TEXT = 'Wydłuż czas zabawy';

const UpSell = ({
  additionalDuration,
  checkUpSell,
  finishTime,
  isBigDesktop,
  isDesktop,
  isUpSellSelected,
  resetUpSell,
  upSellPrice,
  haveUpSell,
  name,
  showBasket,
  happening,
}: IUpSellProps) => {
  const BagState = useContext(clearContext);
  const showFullText = isBigDesktop || isDesktop;
  const { isHangar, isSaltos } = Config.theme;
  const canDisplayUpSellBox =
    BagState &&
    BagState.basketItems.length &&
    BagState.basketItems.some((item) => item.upSell);

  const getAddingValue = (
    price: number,
    isCalculatedPerPerson?: boolean,
  ): string =>
    isCalculatedPerPerson
      ? `+ ${priceFormatter(price)} PLN / os.`
      : `+ ${priceFormatter(price)} PLN`;

  const icon =
    isHangar || isSaltos
      ? '/static/another-hour-black.svg'
      : '/static/another-hour.svg';

  const addUpSellToBasketItem = (item: IZagrywkiBasket) => {
    if (item.upSell && BagState) {
      const updatedItem: IZagrywkiBasket = {
        ...item,
        upSell: {
          ...item.upSell,
          isUpSellSelected:
            item.upSell && item.upSell.isUpSellSelected ? false : true,
        },
      };
      BagState.updateItem(updatedItem);
    }
  };

  return (
    <div className={styles.upSell}>
      {haveUpSell && (
        <div className={styles.row}>
          <img className={styles.image} src={icon} />
          <div className={styles.info}>
            {showBasket ? (
              <span className={styles.infoTitle}>
                {ENLARGE_TEXT} {name} {CONJUNCTION_TEXT} {additionalDuration}{' '}
                {MINUTES_TEXT}
              </span>
            ) : (
              <span className={styles.infoTitle}>{ADD_NEXT_HOUR_TEXT}</span>
            )}
            <span className={styles.infoDescription}>
              {YOUR_PLAY_WILL_LAST_TILL_TEXT} {finishTime}
            </span>
          </div>
          <div className={styles.actions}>
            <div className={styles.addingPrice}>
              {getAddingValue(upSellPrice, happening?.calculatePricePerPerson)}
            </div>
            <div className={styles.buttons}>
              {!isUpSellSelected && (
                <button
                  onClick={checkUpSell}
                  className={styles.button}
                  type="button"
                >
                  {showFullText && ADD_TEXT}
                  {!showFullText && ADD_SMALL_TEXT}
                </button>
              )}
              {isUpSellSelected && (
                <button
                  onClick={resetUpSell}
                  className={cn(styles.button, styles.buttonSelected)}
                  type="button"
                >
                  {showFullText && REMOVE_TEXT}
                  {!showFullText && REMOVE_SMALL_TEXT}
                </button>
              )}
            </div>
          </div>
        </div>
      )}

      {!!(canDisplayUpSellBox && BagState) &&
        BagState.basketItems.map((item) => {
          if (item.upSell) {
            return (
              <div className={styles.row} key={item.id}>
                <img className={styles.image} src={icon} />
                <div className={styles.info}>
                  <span className={styles.infoTitle}>
                    {item.spaceTitle && item.spaceTitle.length
                      ? `${ENLARGE_TEXT} ${item.title} ${item.spaceTitle} ${CONJUNCTION_TEXT}
                    ${item.upSell.additionalDuration} ${MINUTES_TEXT}`
                      : `${ENLARGE_TEXT} ${item.title} ${CONJUNCTION_TEXT}
                    ${item.upSell.additionalDuration} ${MINUTES_TEXT}`}
                  </span>
                  <span className={styles.infoDescription}>
                    {YOUR_PLAY_WILL_LAST_TILL_TEXT} {item.upSell.finishTime}
                  </span>
                </div>
                <div className={styles.actions}>
                  <div className={styles.addingPrice}>
                    {getAddingValue(
                      item.upSell.upSellPrice,
                      item.calculatePricePerPerson,
                    )}
                  </div>
                  <div className={styles.buttons}>
                    {!item.upSell.isUpSellSelected && (
                      <button
                        // tslint:disable-next-line: jsx-no-lambda
                        onClick={() => addUpSellToBasketItem(item)}
                        className={styles.button}
                        type="button"
                      >
                        {showFullText && ADD_TEXT}
                        {!showFullText && ADD_SMALL_TEXT}
                      </button>
                    )}
                    {item.upSell.isUpSellSelected && (
                      <button
                        // tslint:disable-next-line: jsx-no-lambda
                        onClick={() => addUpSellToBasketItem(item)}
                        className={cn(styles.button, styles.buttonSelected)}
                        type="button"
                      >
                        {showFullText && REMOVE_TEXT}
                        {!showFullText && REMOVE_SMALL_TEXT}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            );
          }
          return null;
        })}

      <Products />
    </div>
  );
};

export default UpSell;
