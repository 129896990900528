const makeUrlForPaymentFromRouteString = (url: string) => {
  if (url) {
    const urlLowerCase = url.toLocaleLowerCase();

    if (urlLowerCase.includes('transactionId'.toLocaleLowerCase())) {
      url = url.replace('transactionId', 'prevTransaction');
    }

    if (urlLowerCase.includes('error')) {
      url = url.replace('error', 'prevError');
    }
  }

  if (url.includes('?')) {
    return url + '&transactionId={transactionId}';
  }
  return url + '?transactionId={transactionId}';
};

export default makeUrlForPaymentFromRouteString;
