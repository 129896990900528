import {
  registrationWithoutCode,
  registrationWithTicketCode,
} from '@Model/analytics/actions';
import { connect } from 'react-redux';
import { Dispatch, AnyAction } from 'redux';

import _Store from '@Store';

import HaveTicketCodeStep from './HaveTicketCodeStep.component';
import { IHaveTicketCodeStepFromDispatch } from './HaveTicketCodeStep.types';

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>,
): IHaveTicketCodeStepFromDispatch => ({
  registrationWithoutCode: () => dispatch(registrationWithoutCode()),
  registrationWithTicketCode: () => dispatch(registrationWithTicketCode()),
});

export default connect<{}, IHaveTicketCodeStepFromDispatch, {}, _Store.IState>(
  null,
  mapDispatchToProps,
)(HaveTicketCodeStep);
