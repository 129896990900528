import React, { useEffect } from 'react';

import Loading from '@Compo/layout/Loading';

import config from '@Config';
import _L from '@Misc/helpers/local';
import LOCALS from '@Vars/constants';
import HappeningBox from './components/HappeningBox';
import HappeningCheckBox from './components/HappeningCheckBox';
import HappeningCheckBoxV2 from './components/HappeningCheckBoxV2';
import { IHappeningsProps } from './Happenings.types';

import styles from './Happenings.module.scss';

const INTRO_RESERVATION_TEXT = _L(LOCALS.INTRO_RESERVATION_TEXT);

const Happenings = ({ mounted, isLoading, unmount }: IHappeningsProps) => {
  useEffect(() => {
    mounted();

    return () => {
      unmount();
    };
  }, []);

  const {
    theme: { isHangar, isSaltos, isZagrywki },
  } = config;

  return (
    <>
      {(!isHangar || !isSaltos) && (
        <h1 className={styles.title}>{INTRO_RESERVATION_TEXT}</h1>
      )}

      {!isLoading && isZagrywki && <HappeningBox />}

      {!isLoading && isHangar && <HappeningCheckBox />}

      {!isLoading && isSaltos && <HappeningCheckBoxV2 />}

      {isLoading && <Loading />}
    </>
  );
};

export default Happenings;
