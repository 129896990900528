import moment from 'moment';
import { createSelector } from 'reselect';
import getAvailabilities from './getAvailabilities';
import getValues from './getValues';

const getSelectedDayIncludeTimeSlot = createSelector(
  [getValues, getAvailabilities],
  (values, availabilities) => {
    const selectedDate = moment(values.day);
    if (
      values.day &&
      values.slot &&
      availabilities.otherDays &&
      availabilities.otherDays[values.slot]
    ) {
      return selectedDate
        .startOf('day')
        .add('days', 1)
        .toDate();
    }
    return selectedDate.startOf('day').toDate();
  },
);

export default getSelectedDayIncludeTimeSlot;
