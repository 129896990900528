import { createAsyncAction, createStandardAction } from 'typesafe-actions';
import {
  _GET_FAILURE,
  _GET_REQUEST,
  _GET_SUCCESS,
  _RESET_STATE,
  HAPPENINGS_MOUNTED,
} from './../constants/actions';
import { IGetHappeningsSuccessPayload } from './../types';

export const getHappenings = createAsyncAction(
  _GET_REQUEST,
  _GET_SUCCESS,
  _GET_FAILURE,
)<undefined, IGetHappeningsSuccessPayload, Error>();

export const resetState = createStandardAction(_RESET_STATE)();

export const happeningsMounted = createStandardAction(HAPPENINGS_MOUNTED)();
