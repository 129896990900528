export const _GET_COMPANY_DATA_REQUEST =
  'Zagrywki/reservation/_GET_COMPANY_DATA_REQUEST';
export const _GET_COMPANY_DATA_SUCCESS =
  'Zagrywki/reservation/_GET_COMPANY_DATA_SUCCESS';
export const _GET_COMPANY_DATA_FAILURE =
  'Zagrywki/reservation/_GET_COMPANY_DATA_FAILURE';

export const _GET_REGULATIONS_REQUEST =
  'Zagrywki/booking/availabilities/_GET_REGULATIONS_REQUEST';
export const _GET_REGULATIONS_SUCCESS =
  'Zagrywki/booking/availabilities/_GET_REGULATIONS_SUCCESS';
export const _GET_REGULATIONS_FAILURE =
  'Zagrywki/booking/availabilities/_GET_REGULATIONS_FAILURE';

export const RESERVATION_MOUNTED = 'Zagrywki/reservation/RESERVATION_MOUNTED';

export const SET_USER_DATA = 'Zagrywki/reservation/SET_USER_DATA';

export const SET_TERMS = 'Zagrywki/reservation/SET_TERMS';

export const SET_DISCOUNT = 'Zagrywki/reservation/SET_DISCOUNT';

export const RESET_STATE = 'Zagrywki/reservation/RESET_STATE';

export const SET_UPSELL = 'Zagrywki/reservation/SET_UPSELL';

export const SET_COMPANY_DATA = 'Zagrywki/reservation/SET_COMPANY_DATA';

export const GET_COMPANY_DATA = 'Zagrywki/reservation/GET_COMPANY_DATA';

export const SET_FORM_DATA = 'Zagrywki/reservation/SET_FORM_DATA';

export const CLEAR_DISCOUNT = 'Zagrywki/reservation/CLEAR_DISCOUNT';

export const RE_TRANSACTION = 'Zagrywki/reservation/RE_TRANSACTION';
