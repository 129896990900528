import { providers } from 'goingapp-shared';

import { IZagrywkiBasket } from '@Model/basket/types';
import { removeError } from '@Model/errors/actions';
import { get } from '@Model/errors/selectors';
import { reTransaction } from '@Model/reservation/actions';
import _Store from '@Store';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';
import ErrorComponent from './Error.component';
import { IErrorFromDispatch, IErrorFromState } from './Error.types';

const mapStateToProps = (state: _Store.IState): IErrorFromState => {
  return {
    error: get(state),
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>,
): IErrorFromDispatch => {
  return {
    reTransaction: (data: {
      basketError: providers.IGoingBagTypes.IBasketError;
      basketItems: IZagrywkiBasket[];
      onDone: () => void;
    }) => dispatch(reTransaction(data)),
    remove: () => {
      dispatch(removeError());
    },
  };
};

const Errors = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ErrorComponent);

export default Errors;
