import _Store from '@Store';
import { Selector } from 'reselect';
import { IReservationTransaction } from '../types';

const getTransactionDetails: Selector<
  _Store.IState,
  IReservationTransaction
> = (state) => state.reservation.transaction;

export default getTransactionDetails;
