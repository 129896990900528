import { createSelector } from 'reselect';

import get from './get';

const getAdditionalTerms = createSelector(
  [get],
  (reservation) => reservation.terms.additionalTerms,
);

export default getAdditionalTerms;
