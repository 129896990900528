import { getType } from 'typesafe-actions';
import { add, removeError } from '../actions';
import { IAction, IErrorReducer } from '../types';

const initialErrorState: IErrorReducer = null;

export function errorsReducer(state = initialErrorState, action: IAction) {
  switch (action.type) {
    case getType(add):
      return action.payload;

    case getType(removeError):
      return null;

    default:
      return state;
  }
}

export default errorsReducer;
