import _Store from '@Store';
import { createSelector } from 'reselect';
import { IPriceCheckReducer } from './../types';
import get from './get';

const getErrorMessage = createSelector<
  _Store.IState,
  IPriceCheckReducer,
  string
>(
  [get],
  (priceCheck) => (priceCheck && priceCheck.message) || '',
);

export default getErrorMessage;
