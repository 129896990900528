import React, { useContext, useEffect } from 'react';

import { Grid, ListItem, Typography } from '@material-ui/core';
import cn from 'classnames';
import format from 'date-fns/format';
import pl from 'date-fns/locale/pl';
import moment from 'moment';

import Config from '@Config';
import { Colors } from '@Constants/Colors';
import { TypographyVariant } from '@Constants/Variants/Typography';
import { priceFormatter } from '@Misc/helpers';
import capitalize from '@Misc/helpers/strings/capitalise';
import { IZagrywkiBasket } from '@Model/basket/types';
import { clearContext } from '../Basket/ZagrywkiBasketProvider/ZagrywkiBasketProvider';
import { IBasketItemsSortedByDate, ISummaryBoxProps } from './SummaryBox.types';

import styles from './SummaryBox.module.scss';

const ONE_PERSON_TEXT = 'osoba';
const TWO_TO_FOUR_PEOPLE_TEXT = 'osoby';
const MORE_THAN_FOUR_PEOPLE_TEXT = 'osób';
const YOUR_BOOKING_TEXT = 'Twoja rezerwacja';
const CARNET_BOOKING_TEXT = 'Podsumowanie zakupu';
const LOCAL_CURRENCY = 'PLN';
const FEE_TEXT = 'Opłata serwisowa';
const UPSELL_TEXT = '+ dodatkowy slot';
const COUNT_PRODUCT_TEXT = 'Ilość';
const COUNT_PRODUCT_UNIT_TEXT = 'szt.';

const SummaryBox = ({
  happeningTitle: title,
  happeningSpace,
  isOverbookingAllowed,
  price,
  selectedDay,
  selectedPeopleCount,
  selectedTimeRange,
  carnet,
  resetState,
  cannotRemoveCurrentItem,
  showBasket,
  happeningNumberOfPeopleText,
  addToGlobalBasket,
  calculatedPrice,
  calculateUpSellProps,
  isUpSellEnabled,
  numberOfPeople,
  calculatedPricePerPerson,
  selectedProduct,
}: ISummaryBoxProps) => {
  const state = useContext(clearContext);

  if (!state) {
    return null;
  }

  const getSelectedDayString = (day: string | null) => {
    if (day) {
      const date = moment(day, 'DD.MM.YYYY').toDate();
      return capitalize(format(date, 'EEEE dd.MM', { locale: pl }));
    }
  };

  const calculatePrice = (): number => {
    // TODO: get price from request when be ready
    if (state) {
      const getUpSellBasketPrice = (): number => {
        if (state.basketItems && state.basketItems.length) {
          return state.basketItems.reduce((accumulator, currentValue) => {
            if (
              currentValue.upSell &&
              currentValue.upSell.isUpSellSelected &&
              currentValue.upSell.upSellPrice
            ) {
              return accumulator + currentValue.upSell.upSellPrice;
            }
            return accumulator;
          }, 0);
        }
        return 0;
      };

      const getFeeBasketPrice = (): number => {
        if (state.basketItems && state.basketItems.length) {
          return state.basketItems.reduce((accumulator, currentValue) => {
            if (currentValue.fee) {
              return accumulator + currentValue.fee;
            }
            return accumulator;
          }, 0);
        }
        return 0;
      };

      if (calculatedPrice) {
        return (
          state.getSummedPrice(state.basketItems) +
          getUpSellBasketPrice() +
          calculatedPrice.total +
          getFeeBasketPrice()
        );
      }

      return (
        state.getSummedPrice(state.basketItems) +
        getUpSellBasketPrice() +
        price +
        getFeeBasketPrice()
      );
    }

    if (calculatedPrice) {
      return calculatedPrice.total;
    }
    return price;
  };

  if (isOverbookingAllowed) {
    if (selectedPeopleCount === 1) {
      title += ` (${selectedPeopleCount} ${ONE_PERSON_TEXT})`;
    } else if (selectedPeopleCount > 1 && selectedPeopleCount < 5) {
      title += ` (${selectedPeopleCount} ${TWO_TO_FOUR_PEOPLE_TEXT})`;
    } else {
      title += ` (${selectedPeopleCount} ${MORE_THAN_FOUR_PEOPLE_TEXT})`;
    }
  }

  const renderItems = () => {
    const currentReservation: IZagrywkiBasket = {
      calculatePricePerPerson: !!calculatedPricePerPerson,
      cannotRemove: !!cannotRemoveCurrentItem,
      configurationId: null,
      dateTime: selectedDay.toString(),
      duration: 0,
      fee: calculatedPrice ? calculatedPrice.fee : undefined,
      happeningId: -1,
      happeningSlug: '',
      id: -1,
      isCarnet: carnet,
      numberOfPeople,
      numberOfPeopleText: happeningNumberOfPeopleText,
      price,
      priceType: '',
      selectedTimeRange,
      spaceId: -1,
      spaceSlug: '',
      spaceTitle: happeningSpace,
      title,
      upSell: { isUpSellSelected: isUpSellEnabled, ...calculateUpSellProps },
    };

    const items: IZagrywkiBasket[] = [
      ...(state ? state.basketItems : []),
      currentReservation,
    ];

    const getSortedItems = (): IBasketItemsSortedByDate[] => {
      const sortedItemsByDate: IBasketItemsSortedByDate[] = [];
      items.forEach((item) => {
        const date = moment(
          item.dateTime ? item.dateTime.split('+')[0] : new Date(),
        ).format('DD.MM.YYYY');
        const objectKey = sortedItemsByDate.findIndex(
          (sortedItem) => sortedItem.date === date,
        );

        if (item.title && item.price) {
          if (objectKey !== -1) {
            sortedItemsByDate[objectKey].items.push(item);
          } else {
            const newObject: IBasketItemsSortedByDate = {
              date,
              items: [item],
            };
            sortedItemsByDate.push(newObject);
          }
        }
      });

      return sortedItemsByDate.sort((a, b) => {
        return new Date(b.date).getTime() - new Date(a.date).getTime();
      });
    };

    const handleRemove = (id: number) => {
      if (id === -1) {
        resetState();
      } else {
        if (state) {
          state.removeFromBag(id);
          addToGlobalBasket(
            state.basketItems.filter((product) => product.id !== id),
          );
        }
      }
    };
    const sortedItems = getSortedItems();

    const canDisplaysRemoveButton: boolean = !!(
      state &&
      state.basketItems.length > 0 &&
      items &&
      items.filter((item) => !!item.title).length > 0 &&
      showBasket
    );

    const totalProductsPrice = selectedProduct.reduce(
      (pV, cV) => pV + cV.price * (cV.count || 0),
      0,
    );

    return (
      <div
        className={cn(styles.basketWrapper, isSaltos && styles.withoutBorder)}
      >
        {sortedItems.map(({ date, items: currentItems }) => (
          <section key={date} className={styles.dayWrapper}>
            <section className={styles.topSection}>
              <span className={styles.day}>{getSelectedDayString(date)}</span>
            </section>
            {currentItems.map(
              ({
                cannotRemove,
                calculatePricePerPerson: basketPricePerPerson,
                id,
                dateTime,
                title: basketTitle,
                spaceTitle,
                price: basketPrice,
                selectedTimeRange: basketSelectedTimeRange,
                isCarnet,
                numberOfPeople: basketNumberOfPeople,
                numberOfPeopleText,
                upSell,
                fee,
              }) => {
                const totalUpsellPrice =
                  upSell && upSell.isUpSellSelected
                    ? basketPrice -
                      (basketPricePerPerson
                        ? upSell.upSellPrice * basketNumberOfPeople
                        : upSell.upSellPrice || 0) -
                      totalProductsPrice
                    : basketPrice - totalProductsPrice;

                return (
                  <div key={id} className={styles.reservation}>
                    <Grid container={true}>
                      <Grid item={true} xs={12}>
                        <ListItem disableGutters={true}>
                          <Grid container={true}>
                            <Grid item={true} container={true} xs={12}>
                              <Grid item={true} xs={true}>
                                <Typography variant={TypographyVariant.body1}>
                                  {basketTitle} {numberOfPeopleText}
                                </Typography>
                              </Grid>
                              <Grid item={true}>
                                <Typography
                                  variant={TypographyVariant.body1}
                                >{`${priceFormatter(
                                  totalUpsellPrice,
                                )} ${LOCAL_CURRENCY}`}</Typography>
                              </Grid>
                              <Grid
                                item={true}
                                className={styles.removeContainer}
                              >
                                {!!(
                                  canDisplaysRemoveButton && !cannotRemove
                                ) ? (
                                  <span className={styles.removeButton}>
                                    <img
                                      className={styles.removeImage}
                                      src={'/static/removeButton.svg'}
                                      // tslint:disable-next-line: jsx-no-lambda
                                      onClick={() => handleRemove(id)}
                                    />
                                  </span>
                                ) : (
                                  !!canDisplaysRemoveButton && (
                                    <span className={styles.removeButton} />
                                  )
                                )}
                              </Grid>
                            </Grid>
                            <Grid item={true} xs={12}>
                              <Typography
                                variant={TypographyVariant.body2}
                                color={Colors.textSecondary}
                              >
                                {basketSelectedTimeRange}
                              </Typography>
                            </Grid>
                            {spaceTitle && spaceTitle.length && (
                              <Grid item={true} xs={12}>
                                <Typography
                                  variant={TypographyVariant.body2}
                                  color={Colors.textSecondary}
                                >
                                  {spaceTitle}
                                </Typography>
                              </Grid>
                            )}
                            {upSell && upSell.isUpSellSelected && (
                              <Grid item={true} container={true} xs={12}>
                                <Grid item={true} xs={true}>
                                  <Typography
                                    variant={TypographyVariant.body2}
                                    color={Colors.textSecondary}
                                  >
                                    {UPSELL_TEXT}
                                  </Typography>
                                </Grid>
                                <Grid item={true}>
                                  <Typography
                                    variant={TypographyVariant.body2}
                                    color={Colors.textSecondary}
                                  >
                                    {basketPricePerPerson
                                      ? upSell.upSellPrice *
                                        basketNumberOfPeople
                                      : upSell.upSellPrice}{' '}
                                    {LOCAL_CURRENCY}
                                  </Typography>
                                </Grid>
                              </Grid>
                            )}
                          </Grid>
                        </ListItem>
                      </Grid>
                      {!!fee && (
                        <Grid item={true} xs={12}>
                          <ListItem disableGutters={true}>
                            <Grid container={true}>
                              <Grid item={true} xs={6}>
                                <Typography variant={TypographyVariant.body1}>
                                  {FEE_TEXT}
                                </Typography>
                              </Grid>
                              <Grid
                                item={true}
                                container={true}
                                xs={6}
                                justify="flex-end"
                              >
                                <Typography
                                  variant={TypographyVariant.body1}
                                >{`${priceFormatter(
                                  fee,
                                )} ${LOCAL_CURRENCY}`}</Typography>
                              </Grid>
                            </Grid>
                          </ListItem>
                        </Grid>
                      )}
                    </Grid>
                  </div>
                );
              },
            )}
          </section>
        ))}
      </div>
    );
  };

  const renderProducts = () => {
    return selectedProduct.map((product) => (
      <div key={product.id} className={styles.reservation}>
        <Grid container={true}>
          <Grid container={true}>
            <Grid item={true} xs={true}>
              <Typography>{product.name}</Typography>
            </Grid>
            <Grid item={true}>
              <Typography>{`${priceFormatter(
                product.price * (product?.count || 0),
              )} ${LOCAL_CURRENCY}`}</Typography>
            </Grid>
          </Grid>
          <Grid container={true}>
            <Grid item={true} xs={true}>
              <Typography
                variant={TypographyVariant.body2}
                color={Colors.textSecondary}
              >
                {COUNT_PRODUCT_TEXT}
              </Typography>
            </Grid>
            <Grid item={true}>
              <Typography
                variant={TypographyVariant.body2}
                color={Colors.textSecondary}
              >
                {product.count} {COUNT_PRODUCT_UNIT_TEXT}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </div>
    ));
  };

  const TITLE = carnet ? CARNET_BOOKING_TEXT : YOUR_BOOKING_TEXT;

  const { isSaltos } = Config.theme;

  return (
    <div className={cn(styles.summaryBox, isSaltos && styles.withoutBorder)}>
      <section className={cn(!carnet && styles.topSection)}>
        <h3 className={styles.title}>{TITLE}</h3>
      </section>

      {renderItems()}

      {renderProducts()}

      <section className={cn(styles.section, isSaltos && styles.withoutBorder)}>
        <div className={styles.summaryRow}>
          <span>Do zapłaty</span>
          <span className={styles.summaryPrice}>
            {`${priceFormatter(calculatePrice())} ${LOCAL_CURRENCY}`}
          </span>
        </div>
      </section>
    </div>
  );
};

export default SummaryBox;
