import { connect } from 'react-redux';
import { Dispatch, AnyAction } from 'redux';

import _Store from '@Store';

import ThanksStep from './ThanksStep.component';

import { IThanksStepFromDispatch } from './ThanksStep.types';
import { registrationSuccess } from '@/models/analytics/actions';

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>,
): IThanksStepFromDispatch => ({
  registrationSuccess: (email) => dispatch(registrationSuccess(email)),
});

export default connect<{}, IThanksStepFromDispatch, {}, _Store.IState>(
  null,
  mapDispatchToProps,
)(ThanksStep);
