import { getType } from 'typesafe-actions';

import { getTerms } from '../actions';
import { IAction, IReservationTermsReducer } from './../types';

const initialState: IReservationTermsReducer = {
  additionalTerms: [],
};

const termsReducer = (
  state: IReservationTermsReducer = initialState,
  action: IAction,
): IReservationTermsReducer => {
  switch (action.type) {
    case getType(getTerms.success):
      return {
        ...state,
        additionalTerms: action.payload,
      };

    default:
      return state;
  }
};

export default termsReducer;
