import { ThemeOptions } from '@material-ui/core';

import colors from './colors/saltos';

const saltos = (): ThemeOptions => ({
  MuiHeaderContent: {
    root: {
      borderColor: colors.text.primary,
      borderStyle: 'solid',
      borderWidth: 3,
    },
  },
  overrides: {
    MuiButton: {
      contained: {
        border: '3px solid',
        borderColor: colors.primary.border,
      },
      containedPrimary: {
        background: colors.primary.main,
        color: colors.text.primary,

        '&:disabled': {
          background: colors.actions.disabledBackground,
        },
        '&:hover': {
          backgroundColor: colors.secondary.main,
          color: colors.primary.contrastText,
        },
      },
      containedSecondary: {
        background: colors.secondary.main,

        '&:disabled': {
          background: colors.actions.disabledBackground,
        },
      },
      outlinedPrimary: {
        borderColor: colors.primary.border,
        borderWidth: 3,
        color: colors.text.primary,

        '&:disabled': {
          borderColor: colors.actions.disabled,
        },
        '&:hover': {
          backgroundColor: colors.primary.main,
          borderColor: colors.primary.border,
          borderWidth: 3,
        },
      },
      outlinedSecondary: {
        borderColor: colors.secondary.border,
        borderWidth: 3,

        '&:disabled': {
          borderColor: colors.actions.disabled,
          borderWidth: 3,
        },
        '&:hover': {
          backgroundColor: colors.primary.main,
          borderWidth: 3,
        },
      },
      outlinedSizeLarge: {
        padding: '8px 22px',
      },
      root: {
        textTransform: 'none',
      },
      sizeLarge: {
        fontSize: 15,
        fontWeight: 700,
        letterSpacing: 0.46,
        lineHeight: '26px',
      },
      sizeSmall: {
        fontSize: 13,
        fontWeight: 700,
        letterSpacing: 0.46,
        lineHeight: '22px',
      },
      textPrimary: {
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
        backgroundImage: colors.primary.main,
        display: 'inline-block',
      },
    },
    MuiInputLabel: {
      outlined: {
        backgroundColor: colors.other.white,
        paddingLeft: 4,
        paddingRight: 4,
      },
    },
    MuiOutlinedInput: {
      notchedOutline: {
        borderWidth: '3px !important',
      },
      root: {
        '&$focused $notchedOutline': {
          borderImageSlice: 1,
          borderImageSource: colors.primary.main,
        },
      },
    },
    MuiSelect: {
      iconOutlined: {
        height: 24,
        width: 24,
      },
    },
    MuiSvgIcon: {
      fontSizeLarge: {
        fontSize: '3.5rem',
      },
      root: {
        fontSize: '2.4rem',
      },
    },
  },
  palette: {
    action: {
      disabled: colors.actions.disabledText,
      disabledBackground: colors.actions.disabledBackground,
    },
    background: {
      default: 'transparent',
    },
    other: {
      focused: colors.primary.main,
      stroke: colors.other.stroke,
    },
    primary: {
      contrastText: colors.primary.contrastText,
      dark: colors.primary.dark,
      light: colors.primary.light,
      main: colors.primary.main,
    },
    secondary: {
      contrastText: colors.secondary.contrastText,
      dark: colors.secondary.dark,
      light: colors.secondary.light,
      main: colors.secondary.main,
    },
    text: {
      disabled: colors.text.disabled,
      hint: colors.text.hint,
      primary: colors.text.primary,
      secondary: colors.text.secondary,
    },
  },
  shape: {
    borderRadius: 0,
  },
  spacing: [0, 4, 8, 16, 24, 32, 48, 96, 120, 160],
  typography: {
    body1: {
      fontSize: 16,
      fontWeight: 400,
      letterSpacing: 0.15,
      lineHeight: '24px',
    },
    body2: {
      fontSize: 14,
      fontWeight: 400,
      letterSpacing: 0.15,
      lineHeight: '20px',
    },
    button: {
      fontSize: 14,
      fontWeight: 700,
      letterSpacing: 0.4,
      lineHeight: '26px',
    },
    caption: {
      fontSize: 12,
      fontWeight: 700,
      letterSpacing: 0.4,
      lineHeight: '20px',
    },
    fontFamily: [
      'Point',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    h1: {
      fontSize: 96,
      fontWeight: 400,
      letterSpacing: -1.5,
      lineHeight: '112px',
    },
    h2: {
      fontSize: 60,
      fontWeight: 700,
      letterSpacing: -0.5,
      lineHeight: '72px',
    },
    h3: {
      fontSize: 48,
      fontWeight: 400,
      lineHeight: '56px',
    },
    h4: {
      fontSize: 34,
      fontWeight: 700,
      letterSpacing: 0.25,
      lineHeight: '42px',
    },
    h5: {
      fontSize: 24,
      fontWeight: 400,
      lineHeight: '32px',
    },
    h6: {
      fontSize: 20,
      fontWeight: 700,
      letterSpacing: 0.15,
      lineHeight: '32px',
    },
    overline: {
      fontSize: 12,
      fontWeight: 400,
      letterSpacing: 1,
      lineHeight: '32px',
    },
    subtitle1: {
      fontSize: 16,
      fontWeight: 400,
      letterSpacing: 0.15,
      lineHeight: '28px',
    },
    subtitle2: {
      fontSize: 14,
      fontWeight: 700,
      letterSpacing: 0.1,
      lineHeight: '22px',
    },
  },
});

export default saltos;
