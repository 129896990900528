import React from 'react';

import cn from 'classnames';

import { ISliderProps } from './Slider.types';

import styles from './Slider.module.scss';

const Slider = ({
  maxValue,
  minValue,
  onChange,
  showFullText,
  value,
  round,
}: ISliderProps) => {
  const y = -1;

  const up = () => {
    if (value < maxValue) {
      onChange(value + 1);
    }
  };

  const down = () => {
    if (value > minValue) {
      onChange(value - 1);
    }
  };

  return (
    <>
      <span className={styles.additionalButton} onClick={down}>
        -
      </span>
      <div
        className={cn(
          showFullText && styles.wrapper,
          !showFullText && styles.smallWrapper,
          round && styles.round,
        )}
      >
        <div className={styles.value}>{value}</div>
      </div>
      <span className={styles.additionalButton} onClick={up}>
        +
      </span>
    </>
  );
};

export default Slider;
