import config from '@Config';
import { get } from '@Model/happening/selectors';
import { createSelector } from 'reselect';

import getAvailabilities from './getAvailabilities';
import getValues from './getValues';

const getConfigurationForSelectedSpace = createSelector(
  [getValues, getAvailabilities, get],
  (values, availabilities, happening) => {
    if (
      !(
        values.day &&
        values.space &&
        values.slot &&
        availabilities &&
        happening
      )
    ) {
      return null;
    }

    const { currentDay, otherDays } = availabilities;

    let availability = currentDay[values.slot];

    if (otherDays && otherDays[values.slot]) {
      availability = otherDays[values.slot];
    }

    if (!availability) {
      return null;
    }

    const space = availability.find(
      (_space) => _space.spaceId === values.space,
    );

    if (space) {
      const { rulePriceId: configurationId } = space;
      const selectedSpace = happening.spaces.find(
        (_space) => _space.id === values.space,
      );

      if (selectedSpace && selectedSpace.rulePrice) {
        const configuration = selectedSpace.rulePrice.find(
          (_config) => _config.id === configurationId,
        );

        if (configuration) {
          return configuration;
        }
      }
    }

    return null;
  },
);

export default getConfigurationForSelectedSpace;
