import { filter as filter$, map as map$, tap as tap$ } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { setUserData } from '@Model/reservation/actions';
import { sendTransaction } from '@Model/transaction/actions';
import _Store from '@Store';
import { resetLoading, setLoading } from './../actions';
import { MODULE_BOOKING } from './../constants/constants';

export const setLoaderPositionWhenBookingSendRequested: _Store.IEpic = (
  action$,
  state$,
  { iframeProvider },
) => {
  return action$.pipe(
    filter$(isActionOf(setUserData)),
    tap$(() => {
      iframeProvider.runSetLoaderPositionTopMethod();
    }),
    map$(() => {
      iframeProvider.runScrollTopMethod();
      return setLoading(MODULE_BOOKING);
    }),
  );
};

export const setLoaderWhenBookingSendFinished: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$((action: _Store.IAction) =>
      isActionOf([sendTransaction.failure], action),
    ),
    map$(() => resetLoading(MODULE_BOOKING)),
  );
};
