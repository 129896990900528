import { LOCATION_CHANGE } from 'connected-react-router';
import { EMPTY as EMPTY$, from as from$, of as of$ } from 'rxjs';
import {
  catchError as catchError$,
  filter as filter$,
  mergeMap as mergeMap$,
  takeUntil as takeUntil$,
  tap as tap$,
  withLatestFrom as withLatestFrom$,
} from 'rxjs/operators';
import { isActionOf, isOfType } from 'typesafe-actions';

import { add } from '@Model/errors/actions/index';
import { get } from '@Model/iframe/selectors';
import _Store from '@Store';
import { ITransactionDetails, ITransactionPaymentSuccess } from '../types';
import {
  catchGetExtendedPayment,
  getExtendedPayment,
  getUpdatedExtendedPayment,
  pay,
  payment,
} from './../actions';

const SOMETHING_WENT_WRONG_TEXT =
  'Coś poszło nie tak, proszę spróbuj jeszcze raz.';

export const whenExtendedPaymentMounted: _Store.IEpic = (action$, state$) => {
  return action$.pipe(
    filter$(isActionOf(catchGetExtendedPayment)),
    mergeMap$(() => {
      return of$(
        getExtendedPayment.request(),
        getUpdatedExtendedPayment.request(),
      );
    }),
  );
};

export const getExtendedPaymentWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { extendedPaymentApi },
) => {
  return action$.pipe(
    filter$(isActionOf(getExtendedPayment.request)),
    withLatestFrom$(state$),
    mergeMap$(([_, state]) => {
      const { extendedPayment } = get(state);

      if (!extendedPayment) {
        return EMPTY$;
      }

      return from$(extendedPaymentApi.getExtendedPayment(extendedPayment)).pipe(
        mergeMap$((data: ITransactionDetails) => {
          return [getExtendedPayment.success(data)];
        }),
        takeUntil$(
          action$.pipe(
            filter$(isOfType(LOCATION_CHANGE)),
            tap$(() => extendedPaymentApi.cancelExtendedPayment()),
          ),
        ),
        catchError$((error: Error) => of$(getExtendedPayment.failure(error))),
      );
    }),
  );
};

export const getUpdatedExtendedPaymentWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { extendedPaymentApi },
) => {
  return action$.pipe(
    filter$(isActionOf(getUpdatedExtendedPayment.request)),
    withLatestFrom$(state$),
    mergeMap$(([_, state]) => {
      const { extendedPayment } = get(state);

      if (!extendedPayment) {
        return EMPTY$;
      }

      return from$(
        extendedPaymentApi.getUpdatedExtendedPayment(extendedPayment),
      ).pipe(
        mergeMap$((data) => {
          return [getUpdatedExtendedPayment.success(data)];
        }),
        takeUntil$(
          action$.pipe(
            filter$(isOfType(LOCATION_CHANGE)),
            tap$(() => extendedPaymentApi.cancelExtendedPayment()),
          ),
        ),
        catchError$((error: Error) =>
          of$(getUpdatedExtendedPayment.failure(error)),
        ),
      );
    }),
  );
};

export const catchPayWhenRequest: _Store.IEpic = (action$, state$) => {
  return action$.pipe(
    filter$(isActionOf(pay)),
    mergeMap$(() => {
      return of$(payment.request());
    }),
  );
};

export const payWhenRequest: _Store.IEpic = (
  action$,
  state$,
  { extendedPaymentApi },
) => {
  return action$.pipe(
    filter$(isActionOf(pay)),
    withLatestFrom$(state$),
    mergeMap$(([_, state]) => {
      const { extendedPayment } = get(state);

      if (!extendedPayment) {
        return EMPTY$;
      }

      return from$(extendedPaymentApi.pay(extendedPayment)).pipe(
        mergeMap$((data: ITransactionPaymentSuccess) => {
          return [payment.success(data)];
        }),
        takeUntil$(
          action$.pipe(
            filter$(isOfType(LOCATION_CHANGE)),
            tap$(() => extendedPaymentApi.cancelExtendedPayment()),
          ),
        ),
        catchError$((error: Error) => of$(payment.failure(error))),
      );
    }),
  );
};

export const redirectToPaymentWhenAction: _Store.IEpic = (
  action$,
  state$,
  { iframeProvider },
) => {
  return action$.pipe(
    filter$(isActionOf(payment.success)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const { url } = action.payload;
      iframeProvider.runRedirectParentMethod(url);

      return EMPTY$;
    }),
  );
};

export const showErrorMessageWhenBookingFailed: _Store.IEpic = (
  action$,
  state$,
) => {
  return action$.pipe(
    filter$(isActionOf(payment.failure)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      return of$(add({ text: SOMETHING_WENT_WRONG_TEXT }));
    }),
  );
};
