export interface IHeaderContentMUIProps {
  variant: HeaderContentMUIVariant;
  withHeader?: boolean;
  withHeadParagraph?: boolean;
  withBottomAppendix?: boolean;
  onBottomAppendixClick?: () => void;
  text?: string[];
  title?: string;
  className?: string;
}

export enum HeaderContentMUIVariant {
  DESCRIPTION = 'description',
  GENERIC = 'generic',
}
