const payment = {
  paymentFail: '/rezultat-platnosci/:transactionId/platnosc-nieudana',
  paymentSuccess: '/rezultat-platnosci/:transactionId/platnosc-udana',
};

const routes = {
  ...payment,
  carnets: '/karnety',
  extendedPayment: '/platnosc',
  externalForm: '/formularz',
  happening: '/wydarzenie/:slug',
  index: '/',
  newTermsHangar: '/nowe-zgody',
  reservation: '/rezerwacja/:slug',
  start: '/start',
  summary: '/podsumowanie/:id',
  termsHangar: '/zgody-hangar',
  // tickets: '/zarzadzaj-biletem',
};

export default routes;
