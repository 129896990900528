import { providers } from 'goingapp-shared';

import { IBookFormValues } from '@Compo/BookForm/BookForm.types';
import { IZagrywkiBasket } from '@Model/basket/types';

export const BagContext = providers.GoingBasketProvider<
  IZagrywkiBasket,
  IBookFormValues
>();

export const BagContextConsumer = BagContext.Consumer;
export const BagStore = BagContext.Store;
export const clearContext = BagContext.clearContext;
