import { EMPTY as EMPTY$, from as from$ } from 'rxjs';
import {
  catchError as catchError$,
  filter as filter$,
  map as map$,
  mergeMap as mergeMap$,
  withLatestFrom as withLatestFrom$,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import _Store from '@Store';
import { catchSaveUser, editUserData } from '../actions';

export const catchSaveUserWhenRequest: _Store.IEpic = (action$, state$) => {
  return action$.pipe(
    filter$(isActionOf(catchSaveUser)),
    map$((action) => {
      return editUserData.request(action.payload);
    }),
  );
};

export const saveUserDataWhenRequest: _Store.IEpic = (
  action$,
  state$,
  { formIoApi },
) => {
  return action$.pipe(
    filter$(isActionOf(editUserData.request)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const formUrl = action.payload.url;

      return from$(formIoApi.saveUserData(formUrl, action.payload.data)).pipe(
        mergeMap$(() => {
          return EMPTY$;
        }),
        catchError$((error: Error) => {
          return EMPTY$;
        }),
      );
    }),
  );
};
