export const REGISTRATION_WITHOUT_CODE = 'analytics/REGISTRATION_WITHOUT_CODE';

export const REGISTRATION_WITH_TICKET_CODE =
  'analytics/REGISTRATION_WITH_TICKET_CODE';

export const NEW_USER_REGISTRATION = 'analytics/NEW_USER_REGISTRATION';

export const ADD_VISITOR_DATA = 'analytics/ADD_VISITOR_DATA';

export const REGISTRATION_VISITOR_CONFIRMED =
  'analytics/REGISTRATION_VISITOR_CONFIRMED';

export const REGISTRATION_SUCCESS = 'analytics/REGISTRATION_SUCCESS';
