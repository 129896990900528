import { selector } from 'recoil';

import { mapEN, mapPL, selectedLang } from '@Recoil/lang/atoms';
import { ITranslation } from '@Recoil/lang/types';

const translation = selector<ITranslation>({
  get: ({ get }) => {
    const language = get(selectedLang);
    const englishMap = get(mapEN);
    const polishMap = get(mapPL);

    switch (language) {
      case 'pl':
        return polishMap;
      case 'en':
        return englishMap;
      default:
        return polishMap;
    }
  },
  key: 'lang',
});

export default translation;
