import { BIG_DESKTOP } from '@Model/app/constants/constants';
import { createSelector } from 'reselect';
import getDevice from './getDevice';

const isBigDesktop = createSelector(
  [getDevice],
  (device) => device === BIG_DESKTOP,
);

export default isBigDesktop;
