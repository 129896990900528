import { IAnalytics } from './analytics.types';
import { facebookAnalytics } from './facebook/facebookAnalytics';
import googleAnalytics from './google';

export const analytics = ({ facebookPixelKey, googleGtmKey }: IAnalytics) => {
  if (facebookPixelKey) {
    facebookAnalytics(facebookPixelKey);
  }
  if (googleGtmKey) {
    googleAnalytics(googleGtmKey);
  }
};
