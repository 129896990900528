import { createAsyncAction, createStandardAction } from 'typesafe-actions';

import {
  _GET_FAILURE,
  _GET_REQUEST,
  _GET_SUCCESS,
  _GET_UPDATED_TRANSACTION_DETAILS_FAILURE,
  _GET_UPDATED_TRANSACTION_DETAILS_REQUEST,
  _GET_UPDATED_TRANSACTION_DETAILS_SUCCESS,
  _PAYMENT_FAILURE,
  _PAYMENT_REQUEST,
  _PAYMENT_SUCCESS,
  _RESET_STATE,
  CATCH_GET_EXTENDED_PAYMENT,
  PAY,
} from './../constants/actions';
import {
  IExtendedUpdatedSuccess,
  ITransactionDetails,
  ITransactionPaymentSuccess,
} from './../types';

export const getExtendedPayment = createAsyncAction(
  _GET_REQUEST,
  _GET_SUCCESS,
  _GET_FAILURE,
)<undefined, ITransactionDetails, Error>();

export const getUpdatedExtendedPayment = createAsyncAction(
  _GET_UPDATED_TRANSACTION_DETAILS_REQUEST,
  _GET_UPDATED_TRANSACTION_DETAILS_SUCCESS,
  _GET_UPDATED_TRANSACTION_DETAILS_FAILURE,
)<undefined, IExtendedUpdatedSuccess, Error>();

export const payment = createAsyncAction(
  _PAYMENT_REQUEST,
  _PAYMENT_SUCCESS,
  _PAYMENT_FAILURE,
)<undefined, ITransactionPaymentSuccess, Error>();

export const catchGetExtendedPayment = createStandardAction(
  CATCH_GET_EXTENDED_PAYMENT,
)();

export const pay = createStandardAction(PAY)();
