import React, { useEffect } from 'react';

import { format } from 'date-fns';
import { pl } from 'date-fns/locale';
import moment from 'moment';

import cn from 'classnames';
import { IItemsViewProps } from './ItemsView.types';

import styles from './ItemsView.module.scss';

const PLEASE_COME_EARLIER_TEXT = 'Prosimy o przyjście 20 minut wcześniej!';
const RESERVATION_DATE_TEXT = 'Data i godzina rezerwacji:';
const RESERVATION_NUMBER = 'Numer rezerwacji:';

const ItemsView = ({ transactionItems }: IItemsViewProps) => {
  if (transactionItems && !transactionItems.length) {
    return null;
  }

  return (
    <>
      {transactionItems.map((transactionItem) => {
        const timezoneDateTime = moment
          .utc(transactionItem.startDate)
          .format('YYYY-MM-DD HH:mm:ss');

        const formattedDateTime = `${format(
          moment(timezoneDateTime).toDate(),
          'EEEE dd.MM.yy',
          {
            locale: pl,
          },
        )} r., ${format(moment(timezoneDateTime).toDate(), 'HH:mm')}`;

        return (
          <section key={transactionItem.entryToken} className={styles.info}>
            <div className={styles.infoRow}>
              <span className={styles.infoName}>{RESERVATION_NUMBER} </span>
              <span className={styles.infoValue}>
                {transactionItem.bookingIdentifier}
              </span>
            </div>

            <div className={styles.infoRow}>
              <span className={styles.infoName}>{RESERVATION_DATE_TEXT} </span>
              <span className={cn(styles.infoValue, styles.date)}>
                {formattedDateTime}
              </span>
            </div>

            <div className={styles.additionalInfo}>
              <img className={styles.timerIcon} src="/static/timer.svg" />
              {PLEASE_COME_EARLIER_TEXT}
            </div>
          </section>
        );
      })}
    </>
  );
};

export default ItemsView;
