import React from 'react';
import { Link } from 'react-router-dom';

import routes from '@/routes/routes';

import styles from './Error404.module.scss';

const Error404 = () => {
  return (
    <div className={styles.wrapper}>
      <Link to={routes.index}>Brak strony</Link>
    </div>
  );
};

export default Error404;
