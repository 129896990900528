import React, { FC } from 'react';

import cn from 'classnames';
import { ICheckboxProps } from './CheckboxRound.types';

import styles from './CheckboxRound.module.scss';

const CheckboxRound: FC<ICheckboxProps> = ({
  checked,
  children,
  onChange,
  closeIcon,
}) => {
  return (
    <label className={styles.label} onClick={onChange}>
      <div
        className={cn(
          styles.checkbox,
          checked && styles.checked,
          closeIcon && styles.closeIcon,
        )}
      />
      {children}
    </label>
  );
};

export default CheckboxRound;
