import { captureIframeEvent, mounted } from '@Model/iframe/actions';
import { IEvent } from '@Model/iframe/types';
import _Store from '@Store';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';
import Start from './Start.component';
import { IStartFromDispatch } from './Start.types';

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>,
): IStartFromDispatch => ({
  captureIframeEvent: (event: IEvent) => dispatch(captureIframeEvent(event)),
  mounted: () => dispatch(mounted()),
});

export default connect<{}, IStartFromDispatch, {}, _Store.IState>(
  null,
  mapDispatchToProps,
)(Start);
