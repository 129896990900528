import { atom } from 'recoil';

import { IFormIoUser } from '../types';

const ownerState = atom<IFormIoUser>({
  default: {},
  key: 'ownerState',
});

export default ownerState;
