import { get } from '@Model/happening/selectors';
import { createSelector } from 'reselect';
import getValues from './getValues';

import { selectors, services } from 'goingapp-shared';
import { IHappeningResponse } from 'goingapp-shared/dist/services/api.types';

const SECONDS_IN_MINUTE = 60;

const getActivityDuration = createSelector(
  [get, getValues],
  (happening, values) => {
    if (happening && values) {
      const normalizedHappening = services.HappeningsApi.normalizeHappening(
        happening as IHappeningResponse,
        '',
      );

      return selectors.calculateDuration(normalizedHappening, values);
    }
    return null;
  },
);

export default getActivityDuration;
