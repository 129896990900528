import { LOCATION_CHANGE } from 'connected-react-router';
import format from 'date-fns/format';
import { EMPTY, from as from$, of as of$ } from 'rxjs';
import {
  catchError as catchError$,
  filter as filter$,
  map as map$,
  mergeMap as mergeMap$,
  takeUntil as takeUntil$,
  tap as tap$,
  withLatestFrom as withLatestFrom$,
} from 'rxjs/operators';
import { isActionOf, isOfType } from 'typesafe-actions';

import config from '@Config';
import { setPrice, setSlot, setSpace } from '@Model/booking/actions';
import {
  calculateAvailableSpaces,
  getConfigurationForSelectedSpace,
  getSelectedDayIncludeTimeSlot,
  getSelectedPrices,
  getValues,
} from '@Model/booking/selector';
import getSelectedPrice from '@Model/booking/selector/getSelectedPrice';
import { add } from '@Model/errors/actions';
import { scrollTo } from '@Model/happening/actions';
import { get as getHappening } from '@Model/happening/selectors';
import { updateList } from '@Model/products/actions';
import { getProducts } from '@Model/products/selectors/index';
import {
  clearDiscount,
  setDiscount,
  setUpSell,
} from '@Model/reservation/actions';
import { isUpSellEnabled } from '@Model/reservation/selectors';
import getDiscountCode from '@Model/reservation/selectors/getDiscountCode';
import {
  IPriceCheckBody,
  IPriceCheckResponse,
  ISelectedProduct,
} from '@Services/$price-api/types';
import _Store from '@Store';
import { checkPrice } from './../actions';

const SOMETHING_WENT_WRONG_TEXT =
  'Coś poszło nie tak, proszę spróbuj jeszcze raz.';

const PLACE_ID = 'place';

export const requestForDiscountCheckOnCalculateDiscount: _Store.IEpic = (
  action$,
  state$,
) => {
  return action$.pipe(
    filter$(isActionOf([setDiscount])),
    withLatestFrom$(state$),
    map$(() => checkPrice.request('discount')),
  );
};

export const requestForDiscountCheckOnCalculateUpsell: _Store.IEpic = (
  action$,
  state$,
) => {
  return action$.pipe(
    filter$(isActionOf([setUpSell])),
    withLatestFrom$(state$),
    map$(() => checkPrice.request('upsell')),
  );
};

export const checkPriceWhenSetPriceType: _Store.IEpic = (action$, state$) => {
  return action$.pipe(
    filter$(isActionOf(setPrice)),
    withLatestFrom$(state$),
    map$(() => checkPrice.request('discount')),
  );
};

export const checkDiscountWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { priceAPi },
) => {
  return action$.pipe(
    filter$(isActionOf(checkPrice.request)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const values = getValues(state);
      const configuration = getConfigurationForSelectedSpace(state);
      const date = getSelectedDayIncludeTimeSlot(state);
      const products = getProducts(state);
      const selectedPrice = getSelectedPrice(state);
      const happening = getHappening(state);
      const selectedPrices = getSelectedPrices(state);

      const pricesPromises: Array<Promise<IPriceCheckResponse>> = [];

      if (
        !(
          values &&
          values.day &&
          values.slot &&
          values.space &&
          configuration &&
          date
        )
      ) {
        return EMPTY;
      }

      const selectedProducts = (): ISelectedProduct[] => {
        if (products && products.items && products.items.length) {
          const { items } = products;

          return items
            .filter((item) => item.selected)
            .map((item) => ({
              id: Number(item.id),
              quantity: item.count || 1,
            }));
        }
        return [];
      };

      const getNumberOfPeople = (): number => {
        if (happening) {
          if (happening.calculatePricePerPerson) {
            return values.numberOfPlayers;
          }

          const selectedSpace = happening.spaces.find(
            (_space) => _space.id === values.space,
          );

          if (selectedSpace) {
            return selectedSpace.maxNumberOfPeople;
          }
        }

        return 0;
      };

      const body: IPriceCheckBody = {
        agent: 'zagrywki-web',
        dateTime: `${format(date, 'yyyy-MM-dd')}T${values.slot}+00:00`,
        numberOfPeople: getNumberOfPeople(),
        price: configuration.prices[0].value,
        products: selectedProducts(),
        salesChannelId: 12,
        spaceId: values.space,
      };

      const discountCode = getDiscountCode(state);
      const upSellSelected = isUpSellEnabled(state);

      if (discountCode) {
        body.discount = {
          code: discountCode,
        };
      }

      if (upSellSelected) {
        body.upsell = {
          configurationId: configuration.id,
        };
      }

      const getPriceType = (): string => {
        if (selectedPrice) {
          return selectedPrice;
        }

        const defaultPriceKey = configuration.prices.findIndex(
          (_price) => _price.type === 'default',
        );

        if (defaultPriceKey !== -1) {
          return configuration.prices[defaultPriceKey].type;
        }

        return configuration.prices[0].type;
      };

      body.rulePriceId = configuration.id;
      body.priceType = getPriceType();

      if (upSellSelected) {
        body.upsell = true as any; // TODO: nomalize
      }

      if (selectedPrices.length) {
        selectedPrices.forEach((price, index) => {
          pricesPromises.push(
            priceAPi.checkPrice({
              ...body,
              numberOfPeople: price.numberOfPeople,
              priceType: price.priceType,
              products: index === 0 ? body.products : [],
              upsell: index === 0 ? body.upsell : undefined,
            }),
          );
        });

        return from$(Promise.all(pricesPromises)).pipe(
          map$((data) => {
            const value = data.reduce(
              (total, obj) => (obj.value || 0) + total,
              0,
            );
            const totalValue = data.reduce(
              (total, obj) => (obj.total || 0) + total,
              0,
            );
            return checkPrice.success({
              fee: data[0].fee,
              message: data[0].message,
              total: totalValue,
              value,
            });
          }),
          takeUntil$(
            action$.pipe(
              filter$(isOfType(LOCATION_CHANGE)),
              tap$(() => priceAPi.cancelCheckPrice()),
            ),
          ),
          catchError$((error: string) => {
            switch (action.payload) {
              case 'discount':
                return of$(checkPrice.failure(error), clearDiscount());
              case 'upsell':
                if (upSellSelected) {
                  return of$(checkPrice.failure(error), setUpSell(false));
                }
              case 'product':
                const { items } = getProducts(state);
                const newProductsList = items.map((item) => {
                  return {
                    ...item,
                    selected: false,
                  };
                });

                return of$(
                  checkPrice.failure(error),
                  updateList(newProductsList),
                );
              default:
                return of$(checkPrice.failure(error));
            }
          }),
        );
      }

      return from$(priceAPi.checkPrice(body)).pipe(
        map$(checkPrice.success),
        takeUntil$(
          action$.pipe(
            filter$(isOfType(LOCATION_CHANGE)),
            tap$(() => priceAPi.cancelCheckPrice()),
          ),
        ),
        catchError$((error: string) => {
          switch (action.payload) {
            case 'discount':
              return of$(checkPrice.failure(error), clearDiscount());
            case 'upsell':
              if (upSellSelected) {
                return of$(checkPrice.failure(error), setUpSell(false));
              }
            case 'product':
              const { items } = getProducts(state);
              const newProductsList = items.map((item) => {
                return {
                  ...item,
                  selected: false,
                };
              });

              return of$(
                checkPrice.failure(error),
                updateList(newProductsList),
              );
            default:
              return of$(checkPrice.failure(error));
          }
        }),
      );
    }),
    catchError$(() => {
      return of$(checkPrice.failure(SOMETHING_WENT_WRONG_TEXT));
    }),
  );
};

export const displayErrorMessageWhenCheckPriceFailure: _Store.IEpic = (
  action$,
  state$,
) => {
  return action$.pipe(
    filter$(isActionOf(checkPrice.failure)),
    withLatestFrom$(state$),
    mergeMap$(([action]) => {
      return of$(add({ text: action.payload }));
    }),
  );
};

export const catchCheckPriceWhenReservationStateHasBeChanged: _Store.IEpic = (
  action$,
  state$,
) => {
  return action$.pipe(
    filter$(isActionOf([setSlot, setSpace])),
    mergeMap$(() => {
      return of$(checkPrice.request(''), scrollTo(PLACE_ID));
    }),
  );
};

export const whenSetSlotSetSpaceAction: _Store.IEpic = (action$, state$) => {
  return action$.pipe(
    filter$(isActionOf([setSlot])),
    withLatestFrom$(state$),
    mergeMap$(([_, state]) => {
      const spaces = calculateAvailableSpaces(state);
      if (spaces && spaces.length === 1) {
        return of$(setSpace(spaces[0].id));
      }
      return of$(setSpace(-1));
    }),
  );
};
