import React from 'react';

import ExtendedPayment from '@Compo/ExtendedPayment/ExtendedPayment.container';
import Grid from '@Compo/layout/Grid';
import PageWrapper from '@Compo/layout/PageWrapper';

const ExtendedPaymentPage = () => (
  <Grid>
    <PageWrapper>
      <ExtendedPayment />
    </PageWrapper>
  </Grid>
);

export default ExtendedPaymentPage;
