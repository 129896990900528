import React from 'react';

import cn from 'classnames';

import getMetadataForLanguage from '@Misc/helpers/getMetadataForLanguage';
import {
  IHappeningsItem,
  IHappeningsItemMetadata,
} from '@Model/happenings/types';
import { IHappeningCheckBoxProps } from './HappeningCheckBoxV2.types';

import styles from './HappeningCheckBoxV2.module.scss';

const SELECT_SALTOS_TEXT = 'Wybór parku';
const PARK_TEXT = 'Park trampolin';
const SELECTED_PARK = 'wybrany park';
const SELECT_PARK = 'wybieram';

const HappeningCheckBoxV2 = ({
  happenings,
  happening: selectedHappening,
  redirectToHappening,
  handleHappening,
}: IHappeningCheckBoxProps) => {
  const getMetadata = (happening: IHappeningsItem) =>
    getMetadataForLanguage<IHappeningsItemMetadata>(happening.metadata);

  const catchHandleHappening = (slug: string) => {
    redirectToHappening(`/wydarzenie/${slug}`);
    handleHappening(slug);
  };

  return (
    <>
      <div className={styles.title}>{SELECT_SALTOS_TEXT}</div>
      <div className={styles.spaces}>
        {happenings.map((happening) => {
          const isSelected = !!(
            selectedHappening && selectedHappening.id === happening.id
          );

          return (
            <div
              className={styles.space}
              key={happening.id}
              // tslint:disable-next-line: jsx-no-lambda
              onClick={() => catchHandleHappening(getMetadata(happening).slug)}
            >
              <div className={styles.type}>{PARK_TEXT}</div>
              <div className={styles.title}>{getMetadata(happening).title}</div>
              <section className={styles.description}>
                {getMetadata(happening).description}
              </section>

              <div className={styles.checkBoxLabel}>
                <div
                  className={cn(styles.checkBox, isSelected && styles.selected)}
                />
                <div className={styles.checkBoxDescription}>
                  {isSelected ? SELECTED_PARK : SELECT_PARK}
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div className={styles.line} />
    </>
  );
};

export default HappeningCheckBoxV2;
