import React from 'react';

import config from '@Config';
import { IBackButtonProps } from './BackButton.types';

import styles from './BackButton.module.scss';

const GO_BACK_TEXT = 'Powrót do poprzedniego kroku';

const getBackIcon = (): string => {
  if (config.theme.isHangar) {
    return '/static/arrow-left-black.svg';
  } else if (config.theme.isSaltos) {
    return '/static/arrow-back-full.svg';
  }
  return '/static/arrow-left.svg';
};

const BackButton = ({ goBack, backToIndex, goToIndex }: IBackButtonProps) => (
  <button
    type="button"
    className={styles.backButton}
    onClick={backToIndex ? goToIndex : goBack}
  >
    <img src={getBackIcon()} alt={GO_BACK_TEXT} />
  </button>
);

export default BackButton;
