import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { handleHappening, redirectToHappening } from '@Model/happening/actions';
import { get as getHappening } from '@Model/happening/selectors';
import { getHappenings } from '@Model/happenings/selectors';
import _Store from '@Store';
import HappeningCheckBoxV2 from './HappeningCheckBoxV2.component';
import {
  IHappeningCheckBoxFromDispatch,
  IHappeningCheckBoxFromState,
} from './HappeningCheckBoxV2.types';

const mapStateToProps = (state: _Store.IState): IHappeningCheckBoxFromState => {
  return {
    happening: getHappening(state),
    happenings: getHappenings(state),
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>,
): IHappeningCheckBoxFromDispatch => ({
  handleHappening: (slug: string) => dispatch(handleHappening(slug)),
  redirectToHappening: (url: string) => dispatch(redirectToHappening(url)),
});

export default connect<
  IHappeningCheckBoxFromState,
  IHappeningCheckBoxFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps,
)(HappeningCheckBoxV2);
