import { connect } from 'react-redux';

import { getTransactionDetails } from '@Model/reservation/selectors';
import _Store from '@Store';
import ProductsView from './ProductsView.component';
import { IProductsViewFromState } from './ProductsView.types';

const mapStateToProps = (state: _Store.IState): IProductsViewFromState => {
  const transactionDetails = getTransactionDetails(state);
  return {
    transactionProducts: transactionDetails.transactionProducts,
  };
};

export default connect<IProductsViewFromState, {}, {}, _Store.IState>(
  mapStateToProps,
)(ProductsView);
