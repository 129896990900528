import { atom } from 'recoil';

import { IInputEmail } from '@Compo/HappeningRegister/RegisterNewPersonModal/EntryTerm/EmailModal/EmailModal.types';

const personEmail = atom<IInputEmail | null>({
  default: null,
  key: 'personEmail',
});

export default personEmail;
