import { atom } from 'recoil';

import { IError } from './../types';

const error = atom<IError>({
  default: { message: null },
  key: 'error',
});

export default error;
