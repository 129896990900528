import { providers } from 'goingapp-shared';
import { createAsyncAction, createStandardAction } from 'typesafe-actions';

import { IError } from '@Model/errors/types';
import {
  ITransactionEmpikResponse,
  ITransactionItem,
} from '@Services/$booking-api/types';
import {
  _CAPTURE_TRANSACTION_EMPIK_DETAILS_FAILURE,
  _CAPTURE_TRANSACTION_EMPIK_DETAILS_REQUEST,
  _CAPTURE_TRANSACTION_EMPIK_DETAILS_SUCCESS,
  _SEND_TRANSACTION_FAILURE,
  _SEND_TRANSACTION_REQUEST,
  _SEND_TRANSACTION_SUCCESS,
  REDIRECT_AFTER_SALE,
  RESET_LOADER,
  SET_BOOKING_TERMS,
  SET_BOOKING_USER_DATA,
} from './../constants/actions';
import { IBookingUserData } from './../types';

export const setBookingUserData = createStandardAction(SET_BOOKING_USER_DATA)<
  IBookingUserData
>();

export const setBookingTerms = createStandardAction(SET_BOOKING_TERMS)<
  boolean
>();

export const redirectAfterSale = createStandardAction(REDIRECT_AFTER_SALE)<
  string | undefined
>();

export const resetLoader = createStandardAction(RESET_LOADER)();

export const captureTransactionsEmpikDetailsRequest = createAsyncAction(
  _CAPTURE_TRANSACTION_EMPIK_DETAILS_REQUEST,
  _CAPTURE_TRANSACTION_EMPIK_DETAILS_SUCCESS,
  _CAPTURE_TRANSACTION_EMPIK_DETAILS_FAILURE,
)<string, ITransactionEmpikResponse, Error>();

export const sendTransaction = createAsyncAction(
  _SEND_TRANSACTION_REQUEST,
  _SEND_TRANSACTION_SUCCESS,
  _SEND_TRANSACTION_FAILURE,
)<undefined, string, providers.IGoingBagTypes.IBasketError | IError>();
