import _Store from '@Store';
import { goBack } from 'connected-react-router';
import { of as of$ } from 'rxjs';
import {
  filter as filter$,
  mergeMap as mergeMap$,
  withLatestFrom as withLatestFrom$,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { add, addError, removeError } from '../actions';
import { IError } from '../types';

export const addErrorWhenRequested: _Store.IEpic = (action$, state$) => {
  return action$.pipe(
    filter$(isActionOf(addError)),
    withLatestFrom$(state$),
    mergeMap$(([action]) => {
      const error: IError = {
        text: action.payload.text,
      };

      return of$(add(error));
    }),
  );
};

export const goBackWhenRemoveErrorRequest: _Store.IEpic = (action$, state$) => {
  return action$.pipe(
    filter$(isActionOf(removeError)),
    withLatestFrom$(state$),
    mergeMap$(() => {
      return of$(goBack());
    }),
  );
};
