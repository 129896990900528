import { createSelector } from 'reselect';

import getSpaces from './getSpaces';
import getValues from './getValues';

const getSelectedSpaceProducts = createSelector(
  [getValues, getSpaces],
  (selected, spaces) => {
    const selectedSpace =
      spaces.find((space) => selected.space === space.id) || spaces[0];

    if (selectedSpace) {
      return selectedSpace.products.map((product) => ({
        ...product,
        count: 0,
      }));
    }
    return [];
  },
);

export default getSelectedSpaceProducts;
