import { createStandardAction } from 'typesafe-actions';
import {
  ADD_VISITOR_DATA,
  NEW_USER_REGISTRATION,
  REGISTRATION_SUCCESS,
  REGISTRATION_VISITOR_CONFIRMED,
  REGISTRATION_WITHOUT_CODE,
  REGISTRATION_WITH_TICKET_CODE,
} from '../constants/constants';

export const registrationWithoutCode = createStandardAction(
  REGISTRATION_WITHOUT_CODE,
)();

export const registrationWithTicketCode = createStandardAction(
  REGISTRATION_WITH_TICKET_CODE,
)();

export const newUserRegistration = createStandardAction(NEW_USER_REGISTRATION)<
  string
>();

export const addVisitorData = createStandardAction(ADD_VISITOR_DATA)<string>();

export const registrationVisitorConfirmed = createStandardAction(
  REGISTRATION_VISITOR_CONFIRMED,
)<string>();

export const registrationSuccess = createStandardAction(REGISTRATION_SUCCESS)<
  string
>();
