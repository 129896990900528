import { createSelector } from 'reselect';
import get from './get';

const getSpaces = createSelector(
  [get],
  (happening) => {
    const spaces = happening ? happening.spaces : [];

    return spaces;
  },
);

export default getSpaces;
