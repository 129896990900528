import { redirectToTryAgain } from '@Model/iframe/actions';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { get as getIframeData } from '@Model/iframe/selectors';
import { summaryMounted } from '@Model/summaries/actions';
import _Store from '@Store';
import SummaryFail from './SummaryFail.component';
import {
  ISummaryFailFromDispatch,
  ISummaryFailFromState,
} from './SummaryFail.types';

const mapStateToProps = (state: _Store.IState): ISummaryFailFromState => ({
  roomUrl: getIframeData(state).roomUrl,
});

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>,
): ISummaryFailFromDispatch => ({
  mounted: () => dispatch(summaryMounted()),
  redirectToTryAgain: () => dispatch(redirectToTryAgain()),
});

export default connect<
  ISummaryFailFromState,
  ISummaryFailFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps,
)(SummaryFail);
