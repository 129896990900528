import { Grid, IconButton } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import cn from 'classnames';
import RcSlider from 'rc-slider';
import React from 'react';

import styles from './Slider.module.scss';
import useStyles from './Slider.styles';
import { ISliderProps } from './Slider.types';

const Slider = ({
  // TODO:
  capacityLeft,
  className,
  maxValue,
  minValue,
  step,
  value,
  priceType,
  onChange,
}: ISliderProps) => {
  const y = -1;

  const classes = useStyles();

  const up = () => {
    if (value < maxValue) {
      onChange(value + step, priceType);
    }
  };

  const down = () => {
    if (value > (minValue || 0)) {
      onChange(value - step, priceType);
    }
  };

  const handleOnChange = (x: number) => {
    if (x < maxValue) {
      onChange(x, priceType);
    }
  };

  return (
    <Grid className={classes.wrapper} container={true}>
      <Grid item={true}>
        <IconButton size="medium" color="primary" onClick={down}>
          <RemoveCircleOutlineIcon fontSize="large" />
        </IconButton>
      </Grid>
      <Grid item={true} xs={true} className={styles.wrapper}>
        <div className={styles.container}>
          <RcSlider
            className={cn(className, styles.slider)}
            onChange={handleOnChange}
            value={value}
            min={minValue || 0}
            max={maxValue}
            step={step}
          />
        </div>
        <div className={styles.value}>{value}</div>
      </Grid>
      <IconButton size="medium" color="primary" onClick={up}>
        <AddCircleOutlineIcon fontSize="large" />
      </IconButton>
    </Grid>
  );
};

export default Slider;
