import { connect } from 'react-redux';

import get from '@Model/iframe/selectors/get';
import _Store from '@Store';
import PageWrapper from './PageWrapper.component';
import {  IPageWrapperFromState } from './PageWrapper.types';

const mapStateToProps = (state: _Store.IState): IPageWrapperFromState => {
  const {template} = get(state);

  return {
    template,
  };
};


export default connect<
IPageWrapperFromState,
  {},
  {},
  _Store.IState
>(
  mapStateToProps,

)(PageWrapper);
