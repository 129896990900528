import { getType } from 'typesafe-actions';
import { checkPrice, resetPrice } from './../actions';
import { IAction, IPriceCheckReducer } from './../types';

const initialState: IPriceCheckReducer = null;

const priceReducer = (
  state: IPriceCheckReducer = initialState,
  action: IAction,
): IPriceCheckReducer => {
  switch (action.type) {
    case getType(checkPrice.success):
      return action.payload;

    case getType(resetPrice):
      return initialState;

    default:
      return state;
  }
};

export default priceReducer;
