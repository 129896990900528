import React, { useEffect, useState } from 'react';

import {
  Button,
  Checkbox,
  Grid,
  ListItem,
  Typography,
} from '@material-ui/core';
import { useRecoilState, useRecoilValue } from 'recoil';

import { HeaderContentMUI } from '@/srcShared/ui/molecules/HeaderContentMUI';
import { HeaderContentMUIVariant } from '@/srcShared/ui/molecules/HeaderContentMUI/models/HeaderContentMUI';
import config from '@Config';
import { formioData, ownerState, peopleState } from '@Recoil/formio/atoms';
import { getUserSubmission } from '@Recoil/formio/selectors';
import { translation } from '@Recoil/lang/selectors';
import Analytics from '@Services/$analytics-provider';
import useStyles from './AddPeopleToTicketStep.styles';
import { IAddPeopleToTicketStepProps } from './AddPeopleToTicketStep.types';

const AddPeopleToTicketStep = ({
  onBack,
  setStepByName,
  removeStepFromPrevSlugs,
}: IAddPeopleToTicketStepProps) => {
  const styles = useStyles();
  const [people, setPeople] = useRecoilState(peopleState);
  const peopleCount = useRecoilValue(formioData).peopleCount;
  const owner = useRecoilValue(ownerState);
  const userSubmissions = useRecoilValue(
    getUserSubmission({
      userEmail: owner.email,
      userName: owner.firstName,
    }),
  );
  const { entryListL } = useRecoilValue(translation);

  const filteredUserSubmission =
    userSubmissions &&
    userSubmissions.filter(
      (submission) => submission.submissionId !== owner.submissionId,
    );

  const [lastPeopleList, setLastPeopleList] = useState(filteredUserSubmission);
  const [checkboxDisable, setCheckboxDisable] = useState(false);

  useEffect(() => {
    Analytics.logEvent('page_view', {
      page_title: entryListL.addPeopleToTicketStep.title,
    });
  }, []);

  useEffect(() => {
    const selectedPeopleCount = people.length;

    if (selectedPeopleCount === Number(peopleCount)) {
      setCheckboxDisable(true);
    } else {
      setCheckboxDisable(false);
    }

    if (
      (lastPeopleList && lastPeopleList.length === 0) ||
      lastPeopleList === undefined
    ) {
      setStepByName('addNewPeople');
      removeStepFromPrevSlugs('addPeople');
    }
  }, [people, lastPeopleList]);

  useEffect(() => {
    if (lastPeopleList) {
      const newList = lastPeopleList.map((lastPerson) => {
        if (
          people.some(
            (person) =>
              person.submissionId === lastPerson.submissionId &&
              person.submissionId !== owner.submissionId,
          )
        ) {
          return { ...lastPerson, selected: true };
        }

        return lastPerson;
      });

      setLastPeopleList(newList);
    }
  }, []);

  const onPersonSelect = (submissionId: string) => {
    if (lastPeopleList) {
      const newList = lastPeopleList.map((person, index: number) => {
        if (person.submissionId === submissionId) {
          return { ...person, selected: !person.selected };
        } else {
          return { ...person };
        }
      });
      if (newList) {
        setLastPeopleList(newList);
        const peopleList = newList
          .filter((person) => person.selected)
          .map(({ selected, ...person }) => person);
        setPeople(() => {
          if (owner) {
            return [
              {
                email: owner.email,
                firstName: owner.firstName,
                lastName: owner.lastName,
                submissionId: owner.submissionId,
              },
              ...peopleList,
            ];
          }

          return peopleList;
        });
      }
    }
  };

  const handleNext = () => {
    setStepByName('addNewPeople');
  };

  const spacing = config.spacings;

  return (
    <HeaderContentMUI
      variant={HeaderContentMUIVariant.GENERIC}
      withHeader={true}
      withHeadParagraph={false}
      title={entryListL.addPeopleToTicketStep.title}
      withBottomAppendix={true}
      onBottomAppendixClick={onBack}
    >
      <Grid container={true} spacing={spacing.regular} direction="column">
        <Grid item={true} xs={12}>
          <Typography variant="body1">
            {entryListL.addPeopleToTicketStep.description}
          </Typography>
        </Grid>
        <Grid item={true} xs={12}>
          {lastPeopleList && lastPeopleList.length ? (
            lastPeopleList.map((person, index: number) => (
              <ListItem key={index}>
                <div className={styles.checkbox}>
                  <Checkbox
                    checked={person.selected}
                    // tslint:disable-next-line: jsx-no-lambda
                    onChange={() => {
                      if (person.submissionId) {
                        onPersonSelect(person.submissionId);
                      }
                    }}
                    name="checkedB"
                    color="primary"
                    disabled={!person.selected && checkboxDisable}
                  />
                </div>
                <div>
                  <Typography variant="body1">{`${person.firstName} ${person.lastName}`}</Typography>
                  <Typography variant="body2" color="textSecondary">
                    {person.email}
                  </Typography>
                </div>
              </ListItem>
            ))
          ) : (
            <Typography variant="body1">
              {entryListL.addPeopleToTicketStep.noData}
            </Typography>
          )}
        </Grid>
        <Grid item={true}>
          <Grid container={true}>
            <Grid item={true} xs={12}>
              <Button
                className={styles.button}
                size="large"
                variant="contained"
                color="primary"
                onClick={handleNext}
              >
                {entryListL.next}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </HeaderContentMUI>
  );
};

export default AddPeopleToTicketStep;
