import React, { useContext, useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import cn from 'classnames';

import routes from '@/routes/routes';
import { clearContext } from '@Compo/Basket/ZagrywkiBasketProvider/ZagrywkiBasketProvider';
import Checkbox from '@Compo/forms/Checkbox';
import FormField from '@Compo/forms/FormField';
import BackButton from '@Compo/layout/BackButton';
import Loading from '@Compo/layout/Loading';
import SummaryBox from '@Compo/SummaryBox';
import UpSell from '@Compo/UpSell';
import Config from '@Config';
import _L from '@Misc/helpers/local';
import LOCALS from '@Vars/constants';
import ExtraFields from '../ExtraFields';
import Discount from './../Discount';
import Terms from './../Terms';
import { IFormLayoutProps } from './FormLayout.types';

import styles from './FormLayout.module.scss';

const DO_YOU_HAVE_DISCOUNT_TEXT = 'Posiadasz kod zniżkowy?';
const PLAY_EVEN_BETTER_TEXT = 'Bawcie się jeszcze lepiej';
const PHONE_NUMBER_PLACEHOLDER_TEXT = '+48';
const FIRST_NAME_TEXT = 'Imię';
const LAST_NAME_TEXT = 'Nazwisko';
const EMAIL_TEXT = 'Adres e-mail';
const PHONE_TEXT = 'Numer telefonu';
const I_WANT_INVOICE_TEXT = 'Chcę otrzymać fakturę';
const I_HAVE_PASS = 'Mam karnet';
const I_PREPAID_CARD = 'Mam kartę podarunkową';

const I_PAY_TEXT = 'Płacę';
const CONTINUE_BUYING_TEXT = 'Kontynuuj zakupy';
const NIP_NUMBER_TEXT = 'NIP';
const PASS_CODE_TEXT = 'Nr karnetu';
const PREPAID_CODE_TEXT = 'Kod karty podarunkowej';
const COMPANY_NAME_TEXT = 'Nazwa firmy';
const STREET_TEXT = 'Ulica';
const HOUSE_NUMBER_TEXT = 'Numer';
const ZIP_CODE_TEXT = 'Kod pocztowy';
const CITY_TEXT = 'Miasto';

const DATE_FOR_FACTURE_TEXT = 'Dane do faktury';

const FILL_DATA_TEXT = 'Uzupełnij dane posiadacza karnetu';
const CARNET_REMEMBER_TEXT =
  'Pamiętaj, że karnet jest imienny - wypełnij dane osoby, która będzie z niego korzystać';

const SAME_ITEM_IN_BASKET_ERROR_TEXT =
  'Aktywność znajduje się już w twoim koszyku!';

const useStyles = makeStyles(() => ({
  alert: {
    alignItems: 'center',
    fontSize: 14,
  },
}));

const FormLayout = (props: IFormLayoutProps) => {
  const BagState = useContext(clearContext);
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  const {
    areTermsAccepted,
    errors,
    handleSubmit,
    haveUpSell,
    isBigDesktop,
    isDesktop,
    touched,
    values,
    products,
    setFieldValue,
    getCompanyData,
    setFormData,
    isInvoiceLoading,
    isCarnet,
    currentBasketData,
    setValues,
    isUpSellSelected,
    isCurrentReservation,
    showBasket,
    addToGlobalBasket,
    addBasketRedirect,
    redirectParentTo,
    history,
  } = props;

  useEffect(() => {
    if (BagState) {
      BagState.readLocalStorage();

      if (BagState.userData) {
        setValues(BagState.userData);
      }
    }
  }, []);

  const isBookingAvailable =
    areTermsAccepted && Object.keys(errors).length === 0;
  const [timeOut, setTimeOut] = useState<NodeJS.Timeout | null>(null);
  const { isHangar, isSaltos } = Config.theme;

  const isError = (fieldName: string): boolean =>
    // @ts-ignore
    touched[fieldName] && errors[fieldName] !== undefined;

  const isBiggerDesktop = isDesktop || isBigDesktop;

  const PROVIDE_BOOKING_DATA_TEXT = isCarnet
    ? FILL_DATA_TEXT
    : _L(LOCALS.RESERVATION_TEXT);

  const canDisplayUpSellBox =
    (haveUpSell ||
      (products && products.length) ||
      (BagState &&
        BagState.basketItems.length &&
        BagState.basketItems.some((item) => item.upSell))) &&
    !isSaltos;

  const renderExtra = () => (
    <>
      {!isCarnet && isCurrentReservation && !isSaltos && (
        <section className={styles.section}>
          <h4 className={styles.sectionTitle}>{DO_YOU_HAVE_DISCOUNT_TEXT}</h4>
          <Discount values={values} touched={touched} />
        </section>
      )}

      {!!canDisplayUpSellBox && (
        <section className={styles.section}>
          <h4 className={styles.sectionTitle}>{PLAY_EVEN_BETTER_TEXT}</h4>
          <UpSell />
        </section>
      )}
    </>
  );

  const renderFormField = (fieldName: string, text: string) => (
    <label className={styles.label} htmlFor={fieldName}>
      {text}
      <FormField
        id={fieldName}
        name={fieldName}
        type="text"
        isError={isError(fieldName)}
      />
    </label>
  );

  const setNipValue = (e: React.FormEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;

    setFieldValue('nip', value);

    if (timeOut) {
      clearTimeout(timeOut);
    }

    setTimeOut(
      setTimeout(() => {
        getCompanyData(value);
        setFormData({ ...values, nip: value });
      }, 1000),
    );
  };

  const renderNipField = (fieldName: string, text: string) => (
    <label className={styles.label} htmlFor={fieldName}>
      {text}
      <FormField
        id={fieldName}
        name={fieldName}
        type="text"
        isError={isError(fieldName)}
        onChange={setNipValue}
      />
    </label>
  );

  const handleContinueBuying = () => {
    if (BagState && currentBasketData) {
      if (
        BagState.basketItems.some((item) => item.id === currentBasketData.id)
      ) {
        setOpen(true);
      } else {
        BagState.addToBag({
          ...currentBasketData,
          discountCode: values.discountCode,
          isUpSellSelected,
        });
        BagState.handleUserData(values);

        if (BagState.basketItems) {
          addToGlobalBasket([...BagState.basketItems, currentBasketData]);
        }
      }
    }
    if (!addBasketRedirect) {
      history.push(routes.index);
    }
  };

  const continueWithRedirection = () => {
    handleContinueBuying();
    if (addBasketRedirect) {
      redirectParentTo(addBasketRedirect);
    }
  };

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const { facture, pass, prePardCard } = values;
  const showBasketButton = showBasket && !isCarnet;

  return (
    <>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert className={classes.alert} onClose={handleClose} severity="error">
          {SAME_ITEM_IN_BASKET_ERROR_TEXT}
        </Alert>
      </Snackbar>

      <section className={styles.header}>
        {(isCarnet || isCurrentReservation) && (
          <BackButton backToIndex={isSaltos || isHangar} />
        )}

        <h1 className={styles.title}>{PROVIDE_BOOKING_DATA_TEXT}</h1>
      </section>
      {isCarnet && (
        <section className={styles.secondHeader}>
          <h2>{CARNET_REMEMBER_TEXT}</h2>
        </section>
      )}

      <form className={styles.form} onSubmit={handleSubmit}>
        <section className={styles.twoColumns}>
          <div
            className={cn(styles.fixedBox, isSaltos && styles.withoutBorder)}
          >
            {!isBiggerDesktop && renderExtra()}

            <SummaryBox />
          </div>

          <fieldset className={styles.box}>
            <div className={styles.formRow}>
              <label className={styles.label} htmlFor="firstName">
                {FIRST_NAME_TEXT}
                <FormField
                  id="firstName"
                  name="firstName"
                  type="text"
                  isError={isError('firstName')}
                />
              </label>
              <label className={styles.label} htmlFor="lastName">
                {LAST_NAME_TEXT}
                <FormField
                  id="lastName"
                  name="lastName"
                  type="text"
                  isError={isError('lastName')}
                />
              </label>
            </div>

            <div className={styles.formRow}>
              <label className={styles.label} htmlFor="email">
                {EMAIL_TEXT}
                <FormField
                  id="email"
                  name="email"
                  type="email"
                  isError={isError('email')}
                />
              </label>
              <label className={styles.label} htmlFor="phoneNumber">
                {PHONE_TEXT}
                <FormField
                  id="phoneNumber"
                  name="phoneNumber"
                  placeholder={PHONE_NUMBER_PLACEHOLDER_TEXT}
                  type="text"
                  isError={isError('phoneNumber')}
                  value={values.phoneNumber || ''}
                />
              </label>
            </div>

            {isSaltos && <ExtraFields {...props} />}

            {!isSaltos && (
              <div className={styles.factureFormRow}>
                <div className={styles.formRow}>
                  {isHangar && !isCarnet && (
                    <label className={styles.label} htmlFor="pass">
                      <Checkbox
                        checked={!!pass}
                        // tslint:disable-next-line: jsx-no-lambda
                        onChange={() => setFieldValue('pass', !pass)}
                      >
                        <span>{I_HAVE_PASS}</span>
                      </Checkbox>
                    </label>
                  )}

                  {Config.app.showPrePaidCard && (
                    <label className={styles.label} htmlFor="prePardCard">
                      <Checkbox
                        checked={!!prePardCard}
                        // tslint:disable-next-line: jsx-no-lambda
                        onChange={() =>
                          setFieldValue('prePardCard', !prePardCard)
                        }
                      >
                        <span>{I_PREPAID_CARD}</span>
                      </Checkbox>
                    </label>
                  )}

                  <label className={styles.label} htmlFor="facture">
                    <Checkbox
                      checked={!!facture}
                      // tslint:disable-next-line: jsx-no-lambda
                      onChange={() => setFieldValue('facture', !facture)}
                    >
                      <span>{I_WANT_INVOICE_TEXT}</span>
                    </Checkbox>
                  </label>
                </div>
              </div>
            )}

            {pass && !isSaltos && (
              <div className={styles.formRow}>
                {renderFormField('passCode', PASS_CODE_TEXT)}
              </div>
            )}

            {prePardCard && !isSaltos && (
              <div className={styles.formRow}>
                {renderFormField('prePardCardCode', PREPAID_CODE_TEXT)}
              </div>
            )}

            {facture && !isSaltos && (
              <>
                <div className={styles.formRow}>
                  <h3 className={styles.factureTitle}>
                    {DATE_FOR_FACTURE_TEXT}
                  </h3>
                </div>
                {isInvoiceLoading && <Loading />}
                <div className={styles.formRow}>
                  {renderNipField('nip', NIP_NUMBER_TEXT)}
                  {renderFormField('companyName', COMPANY_NAME_TEXT)}
                </div>

                <>
                  <div className={styles.formRow}>
                    {renderFormField('street', STREET_TEXT)}
                    {renderFormField('houseNumber', HOUSE_NUMBER_TEXT)}
                  </div>
                  <div className={styles.formRow}>
                    {renderFormField('zipCode', ZIP_CODE_TEXT)}
                    {renderFormField('city', CITY_TEXT)}
                  </div>
                </>
              </>
            )}
          </fieldset>
        </section>

        {isBiggerDesktop && renderExtra()}

        {!isBiggerDesktop && (
          <section className={styles.singleRow}>
            <div className={styles.formRow}>
              <Terms />
            </div>
          </section>
        )}

        <section className={cn(styles.singleRow, styles.rowButtons)}>
          {isBiggerDesktop && <Terms />}

          <div className={styles.buttonList}>
            <button
              className={cn(
                styles.payButton,
                !isBookingAvailable && styles.disabled,
              )}
              type="submit"
            >
              {I_PAY_TEXT}
            </button>

            {!!showBasketButton && !addBasketRedirect && (
              <a className={styles.link}>
                <button
                  className={cn(styles.backButton)}
                  onClick={handleContinueBuying}
                  type="button"
                >
                  {CONTINUE_BUYING_TEXT}
                </button>
              </a>
            )}
            {addBasketRedirect && (
              <a className={styles.link}>
                <button
                  className={cn(styles.backButton)}
                  onClick={continueWithRedirection}
                  type="button"
                >
                  {CONTINUE_BUYING_TEXT}
                </button>
              </a>
            )}
          </div>
        </section>
      </form>
    </>
  );
};

export default FormLayout;
