import { isActionOf } from 'typesafe-actions';

import {
  captureTransactionsEmpikDetailsRequest,
  resetLoader,
} from '@Model/transaction/actions';
import _Store from '@Store';
import { filter as filter$, map as map$ } from 'rxjs/operators';
import { resetLoading, setLoading } from '../actions';
import { MODULE_TRANSACTION_DETAILS } from '../constants/constants';

export const whenGetTransactionDetailsSetLoading: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf(captureTransactionsEmpikDetailsRequest.request)), //
    map$(() => setLoading(MODULE_TRANSACTION_DETAILS)),
  );
};

export const whenGetTransationDetailsFinishedResetLoading: _Store.IEpic = (
  action$,
) => {
  return action$.pipe(
    filter$((action: _Store.IAction) =>
      isActionOf(
        [resetLoader, captureTransactionsEmpikDetailsRequest.failure],
        action,
      ),
    ),
    map$(() => resetLoading(MODULE_TRANSACTION_DETAILS)),
  );
};
