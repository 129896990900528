import React, { useEffect } from 'react';

import { Button, Grid, TextField } from '@material-ui/core';
import { Formik } from 'formik';
import { useRecoilState, useRecoilValue } from 'recoil';
import { number, object } from 'yup';

import { HeaderContentMUI } from '@/srcShared/ui/molecules/HeaderContentMUI';
import { HeaderContentMUIVariant } from '@/srcShared/ui/molecules/HeaderContentMUI/models/HeaderContentMUI';
import config from '@Config';
import { formioData } from '@Recoil/formio/atoms';
import { translation } from '@Recoil/lang/selectors';
import Analytics from '@Services/$analytics-provider';
import {
  INumberOfPeopleStepProps,
  INumberOfPeopleValues,
} from './NumberOfPeopleStep.types';

import useStyles from './NumberOfPeopleStep.styles';

const NumberOfPeopleStep = ({
  onBack,
  setStepByName,
}: INumberOfPeopleStepProps) => {
  const styles = useStyles();
  const { entryListL } = useRecoilValue(translation);

  const [formioDataState, setFormioData] = useRecoilState(formioData);

  const configuration = {
    peopleCount: number()
      .min(1, entryListL.numberOfPeopleStep.error)
      .typeError(entryListL.numberOfPeopleStep.numberError)
      .required(entryListL.numberOfPeopleStep.numberError),
  };

  const NumberOfPeopleSchema = object().shape(configuration);

  const handleNext = (values: INumberOfPeopleValues) => {
    setStepByName('addNewPeople');
    setFormioData((oldState) => ({
      ...oldState,
      peopleCount: values.peopleCount,
    }));
  };

  const spacing = config.spacings;

  useEffect(() => {
    Analytics.logEvent('page_view', {
      page_title: entryListL.numberOfPeopleStep.inputText,
    });
  }, []);

  return (
    <HeaderContentMUI
      variant={HeaderContentMUIVariant.GENERIC}
      withHeader={true}
      withHeadParagraph={false}
      title={entryListL.numberOfPeopleStep.inputText}
      withBottomAppendix={true}
      onBottomAppendixClick={onBack}
    >
      <Formik
        initialValues={{ peopleCount: formioDataState.peopleCount }}
        onSubmit={handleNext}
        validationSchema={NumberOfPeopleSchema}
      >
        {({
          handleSubmit,
          values,
          errors,
          handleChange,
          handleBlur,
          touched,
        }) => (
          <form onSubmit={handleSubmit}>
            <Grid container={true} spacing={spacing.regular} direction="column">
              <Grid item={true} xs={12}>
                <TextField
                  variant="outlined"
                  name="peopleCount"
                  label={errors.peopleCount}
                  placeholder={entryListL.numberOfPeopleStep.inputText}
                  helperText={entryListL.numberOfPeopleStep.inputHelperText}
                  fullWidth={true}
                  onChange={handleChange}
                  error={!!errors.peopleCount && touched.peopleCount}
                  onBlur={handleBlur}
                  value={values.peopleCount}
                />
              </Grid>
              <Grid item={true}>
                <Grid container={true}>
                  <Grid item={true} xs={12}>
                    <Button
                      className={styles.button}
                      size="large"
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={!!errors.peopleCount}
                    >
                      {entryListL.next}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </HeaderContentMUI>
  );
};

export default NumberOfPeopleStep;
