import { createMatchSelector, LOCATION_CHANGE } from 'connected-react-router';
import format from 'date-fns/format';
import normalizeUrl from 'normalize-url';
import { EMPTY, from as from$, of as of$ } from 'rxjs';
import {
  catchError as catchError$,
  filter as filter$,
  map as map$,
  mergeMap as mergeMap$,
  takeUntil as takeUntil$,
  tap as tap$,
  withLatestFrom as withLatestFrom$,
} from 'rxjs/operators';
import { isActionOf, isOfType } from 'typesafe-actions';

import routes from '@/routes/routes';
import config from '@Config';
import oblateParentUrl from '@Misc/helpers/api/makeParentUrl';
import makeUrlForPaymentFromRouteString from '@Misc/helpers/api/makeUrlForPaymentFromRouteString';
import { getPriceReduction } from '@Misc/helpers/getPriceReduction';
import { getErrorMessageFromString } from '@Misc/helpers/strings/errorMessageFromString';
import {
  getConfigurationForSelectedSpace,
  getSelectedDayIncludeTimeSlot,
  getSelectedPrices,
  getValues,
} from '@Model/booking/selector';
import getSelectedPrice from '@Model/booking/selector/getSelectedPrice';
import { add } from '@Model/errors/actions';
import { get as getHappening } from '@Model/happening/selectors';
import { redirectParentTo, runFbqAction } from '@Model/iframe/actions';
import { get as getIframe } from '@Model/iframe/selectors';
import { getProducts } from '@Model/products/selectors/index';
import {
  catchCompanyData,
  getCompanyData,
  reTransaction,
  setCompanyData,
  setUserData,
} from '@Model/reservation/actions';
import {
  getAdditionalTerms,
  isUpSellEnabled,
} from '@Model/reservation/selectors';
import getUserData from '@Model/reservation/selectors/getUserData';
import { summaryMounted } from '@Model/summaries/actions';
import {
  IBookingPayUUrlTransactionResponse,
  IBookingTransactionBody,
  ICompanyInfoResponse,
  IReservation,
  ITransactionEmpikResponse,
} from '@Services/$booking-api/types';
import { ISelectedProduct } from '@Services/$price-api/types';
import _Store from '@Store';
import { ITerm } from './../../reservation/types/index';
import {
  captureTransactionsEmpikDetailsRequest,
  redirectAfterSale,
  resetLoader,
  sendTransaction,
} from './../actions';
import { IBookingUserData, IMatch } from './../types';

const HAPPENING_DOESNT_EXIST_TEXT = 'Błędne wydarzenie';
const SOMETHING_WENT_WRONG_TEXT =
  'Coś poszło nie tak, proszę spróbuj jeszcze raz.';

export const captureTransactionDetailsWhenSummaryMounted: _Store.IEpic = (
  action$,
  state$,
) => {
  return action$.pipe(
    filter$(isActionOf([summaryMounted])),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const matchSelector = createMatchSelector(routes.summary);
      const match: IMatch | null = matchSelector(state);

      if (match && match.params && match.params.id) {
        const { id } = match.params;

        return of$(captureTransactionsEmpikDetailsRequest.request(id));
      }

      return EMPTY;
    }),
  );
};

export const runFbqActionWhenSummaryMounted: _Store.IEpic = (
  action$,
  state$,
) => {
  return action$.pipe(
    filter$(isActionOf(captureTransactionsEmpikDetailsRequest.success)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const matchSelector = createMatchSelector(routes.summary);
      const match: IMatch | null = matchSelector(state);
      const { transactionItems } = action.payload;

      const getPriceData = () => {
        let price = 0;

        action.payload.transactionItems.forEach((item) => {
          price = price + item.price;
        });

        action.payload.transactionProducts.forEach((item) => {
          price = price + item.price;
        });

        if (price) {
          return {
            extraData: {
              currency: 'PLN',
              value: price,
            },
          };
        }
        return {};
      };

      if (match && match.params && match.params.id) {
        return of$(
          redirectAfterSale(transactionItems[0].uuid),
          runFbqAction({
            action: 'track',
            payload: 'Purchase',
            ...getPriceData(),
          }),
        );
      }

      return of$(redirectAfterSale(transactionItems[0].uuid));
    }),
  );
};

export const runRedirectAfterSaleWhenRequest: _Store.IEpic = (
  action$,
  state$,
) => {
  return action$.pipe(
    filter$(isActionOf(redirectAfterSale)),
    mergeMap$((action) => {
      const transactionUuid = action.payload;
      if (config.app.redirectAfterSaleUrl && transactionUuid) {
        return of$(
          redirectParentTo(
            `${config.app.redirectAfterSaleUrl}?transactionHash=${transactionUuid}`,
          ),
        );
      }

      return of$(resetLoader());
    }),
  );
};

export const captureTransactionEmpikDetailsWhenRequest: _Store.IEpic = (
  action$,
  state$,
  { bookingApi },
) => {
  return action$.pipe(
    filter$(isActionOf(captureTransactionsEmpikDetailsRequest.request)),
    withLatestFrom$(state$),
    mergeMap$(([action]) => {
      return from$(bookingApi.getTransactionEmpikDetails(action.payload)).pipe(
        mergeMap$((response: ITransactionEmpikResponse) => {
          return of$(captureTransactionsEmpikDetailsRequest.success(response));
        }),
        catchError$(() => {
          return of$(
            captureTransactionsEmpikDetailsRequest.failure(
              new Error(HAPPENING_DOESNT_EXIST_TEXT),
            ),
          );
        }),
      );
    }),
    catchError$(() => {
      return EMPTY;
    }),
  );
};

export const postTransactionWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { bookingApi },
) => {
  return action$.pipe(
    filter$(isActionOf([setUserData, reTransaction])),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      let { parentUrl } = getIframe(state);
      const day = getSelectedDayIncludeTimeSlot(state);
      parentUrl = oblateParentUrl(parentUrl);

      const { onDone, idempotencyKey } = action.payload;

      const happening = getHappening(state);
      const userData = getUserData(state) as IBookingUserData;
      const configuration = getConfigurationForSelectedSpace(state);
      const upSellSelected = isUpSellEnabled(state);
      const values = getValues(state);
      const { items: products, selectedCarnet } = getProducts(state);
      const additionalTerms = getAdditionalTerms(state);
      const selectedPrice = getSelectedPrice(state);
      const selectedPrices = getSelectedPrices(state);

      const prepareFactureData = () => {
        if (
          userData.facture &&
          userData.companyName &&
          userData.street &&
          userData.houseNumber &&
          userData.zipCode &&
          userData.city
        ) {
          return {
            invoice: {
              address: `${userData.street} ${userData.houseNumber}`,
              city: userData.city,
              email: userData.email,
              name: userData.companyName,
              nip: userData.nip,
              post: userData.zipCode,
            },
          };
        } else {
          return {};
        }
      };

      const selectedProducts = (): ISelectedProduct[] => {
        if (selectedCarnet) {
          return [
            {
              id: selectedCarnet.id,
              quantity: 1,
            },
          ];
        } else if (products && products.length) {
          return products
            .filter((item) => item.selected)
            .map((item) => ({
              id: Number(item.id),
              quantity: item.count || 1,
              storageHouseId: item.assignedStorageHouseId || undefined,
            }));
        }
        return [];
      };

      const selectedSpace =
        happening &&
        happening.spaces.find((_space) => _space.id === values.space);

      const paymentFailLink = makeUrlForPaymentFromRouteString(
        normalizeUrl(parentUrl),
      );

      const paymentSuccessLink = makeUrlForPaymentFromRouteString(
        normalizeUrl(parentUrl),
      );

      const dateTime =
        values && values.day
          ? `${format(day || values.day, 'yyyy-MM-dd')}T${values.slot}+00:00`
          : null;

      const getNumberOfPeople = (): number => {
        if (happening && selectedSpace) {
          if (happening.calculatePricePerPerson && values.numberOfPlayers) {
            return values.numberOfPlayers;
          }

          return selectedSpace.maxNumberOfPeople;
        }

        return 0;
      };

      const getAcceptedTerms = (): number[] => {
        return additionalTerms
          .filter((term: ITerm) => term.selected)
          .map((term) => Number(term.id));
      };

      const getPriceType = (): string => {
        if (selectedPrice) {
          return selectedPrice;
        } else if (
          configuration &&
          configuration.prices &&
          configuration.prices.length
        ) {
          const defaultPriceKey = configuration.prices.findIndex(
            (_price) => _price.type === 'default',
          );

          if (defaultPriceKey !== -1) {
            return configuration.prices[defaultPriceKey].type;
          }

          return configuration.prices[0].type;
        }

        return '';
      };

      const priceType = getPriceType();

      const getReservations = (): IReservation[] => {
        const { basketError } = action.payload;

        const getCurrentReservation = (): IReservation[] => {
          const _getPassData = () => {
            if (userData.pass && userData.passCode) {
              return {
                passCode: userData.passCode,
              };
            }
            return {};
          };

          const getPriceValue = (): number => {
            if (
              configuration &&
              configuration.prices &&
              configuration.prices.length
            ) {
              const defaultPriceKey = configuration.prices.findIndex(
                (_price) => _price.type === 'default',
              );

              if (defaultPriceKey !== -1) {
                return configuration.prices[defaultPriceKey].value;
              }

              return configuration.prices[0].value;
            }
            return 0;
          };

          const isCurrentGotError =
            basketError &&
            selectedSpace &&
            happening &&
            basketError.spaceId === selectedSpace.id.toString() &&
            basketError.happeningId === happening.id.toString() &&
            basketError.dateTime === dateTime;

          if (
            happening &&
            selectedSpace &&
            !isCurrentGotError &&
            selectedPrices.length === 0
          ) {
            const currentReservation: IReservation = {
              numberOfPeople: getNumberOfPeople(),
              products: selectedProducts(),
              ..._getPassData(),
              dateTime,
              duration: selectedSpace.timeSlot,
              happeningId: happening.id,
              paymentOperator: 3,
              priceReduction: getPriceReduction(
                userData.discountCode,
                upSellSelected,
                getPriceValue(),
                dateTime || '',
                getNumberOfPeople(),
                selectedProducts(),
                selectedSpace.id,
                configuration && configuration.id ? configuration.id : null,
                priceType,
              ),
              priceType,
              spaceId: selectedSpace.id,
              title:
                selectedSpace &&
                selectedSpace.metadata &&
                selectedSpace.metadata[0]
                  ? selectedSpace.metadata[0].title
                  : '',
            };
            return [currentReservation];
          }
          return [];
        };

        const getReservationsWithPriceTypes = (): IReservation[] => {
          const _getPassData = () => {
            if (userData.pass && userData.passCode) {
              return {
                passCode: userData.passCode,
              };
            }
            return {};
          };
          if (selectedPrices.length && selectedSpace && happening) {
            const getPriceValueByType = (type: string): number => {
              if (
                configuration &&
                configuration.prices &&
                configuration.prices.length
              ) {
                const defaultPriceKey = configuration.prices.findIndex(
                  (_price) => _price.type === type,
                );

                if (defaultPriceKey !== -1) {
                  return configuration.prices[defaultPriceKey].value;
                }

                return configuration.prices[0].value;
              }
              return 0;
            };

            const reservationsWithPriceType = selectedPrices.map(
              (price, index) => ({
                numberOfPeople: price.numberOfPeople,
                products: index === 0 ? selectedProducts() : [],
                ..._getPassData(),
                dateTime,
                duration: selectedSpace.timeSlot,
                happeningId: happening.id,
                paymentOperator: 3,
                priceReduction: getPriceReduction(
                  userData.discountCode,
                  index === 0 ? upSellSelected : false,
                  getPriceValueByType(price.priceType),
                  dateTime || '',
                  price.numberOfPeople,
                  index === 0 ? selectedProducts() : [],
                  selectedSpace.id,
                  configuration && configuration.id ? configuration.id : null,
                  price.priceType,
                ),
                priceType: price.priceType,
                spaceId: selectedSpace.id,
                title:
                  selectedSpace &&
                  selectedSpace.metadata &&
                  selectedSpace.metadata[0]
                    ? selectedSpace.metadata[0].title
                    : '',
              }),
            );

            return reservationsWithPriceType;
          }

          return [];
        };

        const getReservationsFromBasket = (): IReservation[] => {
          const { basketItems } = action.payload;

          if (basketItems && basketItems.length) {
            return basketItems.map((item) => {
              return {
                dateTime: item.dateTime,
                duration: item.duration,
                happeningId: item.happeningId,
                numberOfPeople: item.numberOfPeople,
                priceReduction: getPriceReduction(
                  item.discountCode || '',
                  item.upSell ? item.upSell.isUpSellSelected || false : false,
                  item.price,
                  item.dateTime || '',
                  item.numberOfPeople,
                  [],
                  item.spaceId,
                  item.configurationId,
                  priceType && priceType.length ? priceType : item.priceType,
                ),
                priceType:
                  priceType && priceType.length ? priceType : item.priceType,
                products: [],
                spaceId: item.spaceId,
                title: item.title,
              };
            });
          }
          return [];
        };

        return [
          ...getCurrentReservation(),
          ...getReservationsFromBasket(),
          ...getReservationsWithPriceTypes(),
        ];
      };

      const getPassData = () => {
        if (userData.prePardCard && userData.prePardCardCode.length) {
          return {
            prepaidCard: userData.prePardCardCode,
          };
        }
        return {};
      };

      const body: IBookingTransactionBody = {
        agent: 'zagrywki-web',
        idempotencyKey: idempotencyKey || '',
        linkFail: paymentFailLink,
        linkOk: paymentSuccessLink,
        paymentOperator: 3,
        products: selectedCarnet ? selectedProducts() : [],
        reservations: getReservations(),
        salesChannelId: 12,
        user: {
          acceptedTerms: getAcceptedTerms(),
          email: userData.email,
          firstName: userData.firstName,
          lastName: userData.lastName,
          phone: userData.phoneNumber || '',
          terms: true,
        },
        ...prepareFactureData(),
        ...getPassData(),
      };

      return from$(bookingApi.postTransaction(body)).pipe(
        map$((response: IBookingPayUUrlTransactionResponse) => {
          const {
            payment: { formUrl },
            errors,
          } = response;

          if (errors && errors.length && errors[0] && errors[0].message) {
            const { message } = errors[0];
            const path = errors[0].path || '';

            // Always got single error
            const formattedError = getErrorMessageFromString(
              errors[0].message,
              errors[0].path || '',
              getReservations(),
            );

            if (formattedError) {
              return sendTransaction.failure(formattedError);
            }
          }

          if (formUrl) {
            onDone();

            return sendTransaction.success(formUrl);
          }

          return sendTransaction.failure({ text: SOMETHING_WENT_WRONG_TEXT });
        }),
        takeUntil$(
          action$.pipe(
            filter$(isOfType(LOCATION_CHANGE)),
            tap$(() => bookingApi.cancelPostTransaction()),
          ),
        ),
        catchError$((error: string) =>
          of$(sendTransaction.failure({ text: error })),
        ),
      );
    }),
  );
};

export const showErrorMessageWhenBookingFailed: _Store.IEpic = (
  action$,
  state$,
) => {
  return action$.pipe(
    filter$(isActionOf(sendTransaction.failure)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      return of$(add(action.payload));
    }),
  );
};

export const redirectToPayIWhenTransactionPosted: _Store.IEpic = (
  action$,
  state$,
  { iframeProvider },
) => {
  return action$.pipe(
    filter$(isActionOf(sendTransaction.success)),
    mergeMap$((action) => {
      iframeProvider.runRedirectParentMethod(action.payload);
      iframeProvider.runFbqMethod({
        action: 'track',
        payload: 'InitiateCheckout',
      });
      iframeProvider.runFbqMethod({
        action: 'track',
        payload: 'AddPaymentInfo',
      });

      return EMPTY;
    }),
  );
};

export const getCompanyDataWhenRequest: _Store.IEpic = (
  action$,
  state$,
  { bookingApi },
) => {
  return action$.pipe(
    filter$(isActionOf(getCompanyData.request)),
    withLatestFrom$(state$),
    mergeMap$(([action]) => {
      if (typeof action.payload === 'string') {
        return from$(bookingApi.getCompanyData(action.payload)).pipe(
          mergeMap$((response: ICompanyInfoResponse) => {
            const {
              apartmentNumber,
              city,
              propertyNumber,
              name: companyName,
              postCode: zipCode,
              street,
              taxNumber: nip,
            } = response;
            const getPropertyNumber = (): string => {
              if (apartmentNumber) {
                return `${propertyNumber}/${apartmentNumber}`;
              }
              return propertyNumber;
            };

            const data = {
              city,
              companyName,
              facture: true,
              houseNumber: '',
              nip,
              propertyNumber: getPropertyNumber(),
              street,
              zipCode,
            };

            return of$(setCompanyData(data), getCompanyData.success());
          }),
          catchError$(() => {
            return of$(getCompanyData.failure());
          }),
        );
      }
      return EMPTY;
    }),
    catchError$(() => {
      return EMPTY;
    }),
  );
};

export const catchCompanyDataWhenRequest: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf(catchCompanyData)),
    mergeMap$((action) => {
      return of$(getCompanyData.request(action.payload));
    }),
  );
};
