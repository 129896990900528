import React from 'react';

import { IProductsViewProps } from './ProductsView.types';

const ItemsView = ({ transactionProducts }: IProductsViewProps) => {
  return (
    <>
      <section>{/* //TODO: FINISH WHEN API WILL READY */}</section>
    </>
  );
};

export default ItemsView;
