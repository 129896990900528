import React, { FC } from 'react';

import { Button, Grid, Typography } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import cn from 'classnames';

import { HeaderJP } from './../../particles/HeaderJP';
import {
  HeaderContentMUIVariant,
  IHeaderContentMUIProps,
} from './models/HeaderContentMUI';
import { useStyles } from './styles/HeaderContentMUI.styles';

export const HeaderContentMUI: FC<IHeaderContentMUIProps> = ({
  children,
  title,
  withHeader,
  withBottomAppendix,
  onBottomAppendixClick,
  text,
  variant,
  className,
}) => {
  const isDescriptionVariant = variant === HeaderContentMUIVariant.DESCRIPTION;
  const isGenericVariant = variant === HeaderContentMUIVariant.GENERIC;

  const styles = useStyles();

  return (
    <Grid>
      {withHeader && <HeaderJP subheader={true}>{title}</HeaderJP>}
      {isDescriptionVariant && (
        <Grid className={cn(styles.container, styles.overrides, className)}>
          {!!(text && text.length) &&
            text.map((element, index) => {
              const isHeadParagraph = index === 0;

              return (
                <Typography
                  key={index}
                  variant={isHeadParagraph ? 'h5' : 'body2'}
                >
                  {element}
                </Typography>
              );
            })}
        </Grid>
      )}
      {isGenericVariant && (
        <Grid className={cn(styles.container, styles.overrides)}>
          {children}
        </Grid>
      )}
      {withBottomAppendix && (
        <Button
          variant="text"
          size="small"
          color="secondary"
          onClick={onBottomAppendixClick}
          className={styles.bottomAppendix}
          startIcon={<ArrowBackIcon />}
        >
          Wróć
        </Button>
      )}
    </Grid>
  );
};
