// Private
export const _GET_REQUEST = 'Zagrywki/products/_GET_REQUEST';
export const _GET_SUCCESS = 'Zagrywki/products/_GET_SUCCESS';
export const _GET_FAILURE = 'Zagrywki/products/_GET_FAILURE';

export const _GET_CARNETS_REQUEST = 'Zagrywki/products/_GET_CARNETS_REQUEST';
export const _GET_CARNETS_SUCCESS = 'Zagrywki/products/_GET_CARNETS_SUCCESS';
export const _GET_CARNETS_FAILURE = 'Zagrywki/products/_GET_CARNETS_FAILURE';

export const SELECT_PRODUCT = 'Zagrywki/products/SELECT_PRODUCT';
export const UN_SELECT_PRODUCT = 'Zagrywki/products/UN_SELECT_PRODUCT';
export const UPDATE_LIST = 'Zagrywki/products/UPDATE_LIST';
export const SET_PRODUCT_COUNT = 'Zagrywki/products/SET_PRODUCT_COUNT';
export const CARNETS_MOUNTED = 'Zagrywki/products/CARNETS_MOUNTED';
export const SELECT_CARNET = 'Zagrywki/products/SELECT_CARNET';
