import { asyncRedirect, setDevice, setSizes } from '@Model/app/actions';
import { IActionDevice } from '@Model/app/types';
import _Store from '@Store';
import { push } from 'connected-react-router';
import { of as of$ } from 'rxjs';
import {
  filter as filter$,
  map as map$,
  mergeMap as mergeMap$,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

export const whenSizesChangedSetDevice: _Store.IEpic = (
  action$,
  _,
  { deviceDetector },
) => {
  return action$.pipe(
    filter$(isActionOf(setSizes)),
    map$((action) => {
      return setDevice(deviceDetector.getDevice(
        action.payload,
      ) as IActionDevice);
    }),
  );
};

export const asyncRedirectWhenAction: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf(asyncRedirect)),
    mergeMap$((action) => {
      const path = action.payload;

      return of$(push('/index'), push(path));
    }),
  );
};
