import React from 'react';

import styles from './PersonalData.module.scss';
import { IPersonalDataProps } from './PersonalData.types';

const YOUR_DATA_TEXT = 'Twoje dane';
const NAME_TEXT = 'Imię:';
const SUR_NAME_TEXT = 'Nazwisko:';
const EMAIL_TEXT = 'Adres e-mail:';
const PHONE_NUMBER_TEXT = 'Numer telefonu:';
const INVOICE_TEXT = 'Dane do faktury';
const NIP_TEXT = 'NIP:';
const COMPANY_NAME_TEXT = 'Nazwa firmy:';
const STREET_TEXT = 'Ulica:';
const NUMBER_TEXT = 'Numer:';
const ZIP_CODE_TEXT = 'Kod pocztowy:';
const CITY_TEXT = 'Miasto:';

const PersonalData = ({
  extendedPayment,
  updatedTransaction,
}: IPersonalDataProps) => {
  if (!extendedPayment || !updatedTransaction) {
    return null;
  }

  const { userEmail, userFirstName, userLastName } = extendedPayment.user;
  const { invoice } = extendedPayment;
  const { items } = updatedTransaction;
  const { firstName, lastName } = items[0];
  return (
    <>
      <section className={styles.personalData}>
        <div className={styles.title}>{YOUR_DATA_TEXT}</div>

        <div className={styles.row}>
          <div className={styles.key}>{NAME_TEXT}</div>
          <div className={styles.value}>{firstName || userFirstName}</div>
        </div>
        <div className={styles.row}>
          <div className={styles.key}>{SUR_NAME_TEXT}</div>
          <div className={styles.value}>{lastName || userLastName}</div>
        </div>
        <div className={styles.row}>
          <div className={styles.key}>{EMAIL_TEXT}</div>
          <div className={styles.value}>{userEmail}</div>
        </div>

        {invoice && (
          <>
            <div className={styles.invoiceTitle}>{INVOICE_TEXT}</div>

            <div className={styles.row}>
              <div className={styles.key}>{NIP_TEXT}</div>
              <div className={styles.value}>{invoice.nip}</div>
            </div>
            <div className={styles.row}>
              <div className={styles.key}>{COMPANY_NAME_TEXT}</div>
              <div className={styles.value}>{invoice.name}</div>
            </div>
            <div className={styles.row}>
              <div className={styles.key}>{STREET_TEXT}</div>
              <div className={styles.value}>{invoice.address}</div>
            </div>
            <div className={styles.row}>
              <div className={styles.key}>{ZIP_CODE_TEXT}</div>
              <div className={styles.value}>{invoice.zip}</div>
            </div>
            <div className={styles.row}>
              <div className={styles.key}>{CITY_TEXT}</div>
              <div className={styles.value}>{invoice.city}</div>
            </div>
          </>
        )}
      </section>
    </>
  );
};

export default PersonalData;
