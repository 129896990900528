import { createAsyncAction, createStandardAction } from 'typesafe-actions';
import {
  _CHECK_PRICE_FAILURE,
  _CHECK_PRICE_REQUEST,
  _CHECK_PRICE_SUCCESS,
  RESET_PRICE,
} from './../constants/actions';
import { ICheckPricePayload, IPriceCheckSuccessPayload } from './../types';

export const checkPrice = createAsyncAction(
  _CHECK_PRICE_REQUEST,
  _CHECK_PRICE_SUCCESS,
  _CHECK_PRICE_FAILURE,
)<ICheckPricePayload, IPriceCheckSuccessPayload, string>();

export const resetPrice = createStandardAction(RESET_PRICE)();
