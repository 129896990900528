import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bottomAppendix: {
      marginTop: theme.spacing(2),
    },
    container: {
      backgroundColor: theme.palette.background.paper,
      borderRadius: theme.shape.borderRadius,
      padding: theme.spacing(4),
    },
    overrides: theme.MuiHeaderContent ? { ...theme.MuiHeaderContent.root } : {},
  }),
);
